import {Component, OnInit} from '@angular/core';
import {CoreComponent} from '../../core/core.component';
import {ActivatedRoute} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UsersService} from '../../users/users.service';
import {User} from '../users/user/user';
import {HttpErrorResponse} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {GoogleAnalyticsService} from 'ngx-google-analytics';

@Component({
  selector: 'app-users-manager',
  templateUrl: './users-manager.component.html',
  styleUrls: ['./users-manager.component.scss']
})
export class UsersManagerComponent extends CoreComponent<User> {

  active = 0;
  roles: Array<string> = new Array<string>();

  constructor(protected servicesService: UsersService, public route: ActivatedRoute, private modalService: NgbModal,
              private matSnackBar: MatSnackBar,protected gaService: GoogleAnalyticsService) {
    super(servicesService, route);
  }

  public ngOnInit(): void {
    this.servicesService.getRoles().subscribe((roles: Array<string>) => {
      this.roles = roles;
    }, (error: HttpErrorResponse) => {
      this.blockUI.stop();
      this.gaService.exception(error.error?.message, true);
      this.matSnackBar.open(error.error.message, 'Okay', {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    });
  }
}
