import {Injectable} from '@angular/core';
import {ServicesService} from '../users/services.service';
import {HttpClient} from '@angular/common/http';
import {ConfigServices} from '../core/config';
import {CookieService} from 'ngx-cookie-service';
import {Application} from '../applications/application/application';
import {Appeal} from '../apps/appeal/appeal';
import {RequestToReprocess} from '../admin/appeal/request-to-reprocess';

@Injectable({
  providedIn: 'root'
})
export class AppealsService extends ServicesService {

  constructor(public http: HttpClient, public configService: ConfigServices, public cookieService: CookieService) {
    super('appeals', http, configService);
  }

  public getAppeal(application: Application) {
    const url = this.configService.getApi() + this.type + '/by-application/' + application.id;
    return this.http.get(url, {withCredentials: true});

  }

  public withdraw(appeal: Appeal) {
    const url = this.configService.getApi() + this.type + '/withdraw/' + appeal.id;
    return this.http.post(url, appeal.withdrawReason,{withCredentials: true});

  }

  public reOpenWithdrawnAppeal(appeal: Appeal) {
    const url = this.configService.getApi() + this.type + '/reopen-withdrawn-appeal/' + appeal.id;
    return this.http.post(url, appeal.appealReOpenReason,{withCredentials: true});

  }

  public requestAppealReprocess(appeal: Appeal,requestToReprocess: RequestToReprocess) {
    const url = this.configService.getApi() + this.type + '/reprocess-appeal/' + appeal.id;
    console.log(requestToReprocess);
    return this.http.post(url, requestToReprocess,{withCredentials: true});

  }

  public getAppealDetails(id: string) {
    const url = this.configService.getApi() + this.type + '/' + id + '/details';
    return this.http.get(url, {withCredentials: true});

  }

}
