import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoanBursaryDecisionComponent } from '../loan-bursary-decision/loan-bursary-decision.component';
import { ApplicationsService } from 'src/app/applications/applications.service';
import { Application } from 'src/app/applications/application/application';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ContinuingStudentsComponent } from '../continuing-students/continuing-students.component';
import { ReturningStudent } from 'src/app/apps/returningStudent';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-tvet-univesity',
  templateUrl: './tvet-univesity.component.html',
  styleUrls: ['./tvet-univesity.component.scss']
})
export class TvetUnivesityComponent implements OnInit {

  application:Application;
  @ViewChild('appliedForm', {static: false})
  public appliedForm: NgForm;
  returningStudent:ReturningStudent;
  @BlockUI() blockUI: NgBlockUI;
  constructor(public modalService:NgbModal, public applicationsService:ApplicationsService) { }

  ngOnInit(): void {
    // this.applicationsService.application.disabled = this.applicationsService.application.user.disabled;
    // this.applicationsService.application.married = this.applicationsService.application.user.married;
    // this.applicationsService.application.address = this.applicationsService.application.user.address;
    
  }

  clear() {
    this.applicationsService.application.applied = false;
    this.applicationsService.application.provisionalOffer = false;
    }

  appliedUniTvet(){
    this.applicationsService.application.applied = true;
  }

  noApplication(){
    this.applicationsService.application.applied = false;
    this.applicationsService.application.provisionalOffer = false;
  }

  onSubmit() {
    if(this.applicationsService.application.provisionalOffer===undefined){
      this.applicationsService.application.provisionalOffer = false;
    }
    
    if(this.appliedForm.valid){
      this.modalService.dismissAll();
      this.blockUI.start();
      this.applicationsService.isContinuingStudent().subscribe((continuingStudent:ReturningStudent)=>{
        this.returningStudent = continuingStudent;
        const idNumber = this.returningStudent.idNumber!=null? this.returningStudent.idNumber:0;
        if (idNumber!=null) {
          this.blockUI.stop();
          const modalRef = this.modalService.open(ContinuingStudentsComponent, { fullscreen: true, modalDialogClass: 'animate-bottom' });
          modalRef.componentInstance.name = 'World';
          modalRef.closed.subscribe(() => {
          });
        }
      }, (error: HttpErrorResponse) => {
        this.blockUI.stop();
          const modalRef = this.modalService.open(LoanBursaryDecisionComponent, { fullscreen: true, modalDialogClass: 'animate-bottom' });
          modalRef.componentInstance.name = 'World';
          modalRef.closed.subscribe(() => {
          });
      });
    }
  }
}
