import {Component, Input, OnInit} from '@angular/core';
import {CoreComponent} from '../core/core.component';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ContactCenterService} from '../admin/contact-center/contact-center.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {Results} from '../core/results';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {DisabilityChangeRequestsService} from './disability-change-requests.service';
import {DisabilityChangeRequest} from './disability-change-request/disability-change-request';
import {DisabilityChangeRequestCheckComponent} from './disability-change-request-check/disability-change-request-check.component';
import {ConfigServices} from '../core/config';
import {DomSanitizer} from '@angular/platform-browser';
import {FilenameUtilsService} from '../utils/filename-utils.service';

declare var bootbox: any;

@Component({
  selector: 'app-disability-change-requests',
  templateUrl: './disability-change-requests.component.html',
  styleUrls: ['./disability-change-requests.component.scss']
})
export class DisabilityChangeRequestsComponent extends CoreComponent<DisabilityChangeRequest> {

  active = 1;
  @Input() status: string;

  constructor(public modalService: NgbModal, public servicesService: DisabilityChangeRequestsService, public contactCenterService: ContactCenterService,
              public route: ActivatedRoute, private matSnackBar: MatSnackBar, protected gaService: GoogleAnalyticsService,
              public configServices: ConfigServices, public sanitizer: DomSanitizer,
              public disabilityChangeRequestsService: DisabilityChangeRequestsService,
              public http: HttpClient, private filenameUtilsService: FilenameUtilsService,
              public activeModal: NgbActiveModal, private router: Router) {
    super(servicesService, route);
  }

  ngOnInit(): void {
    this.search();
  }

  search() {

    console.log('search');
    this.blockUI.start();
    this.servicesService.search(this.currentPage, this.perPage, 'ASC', 'application.firstName', this.contactCenterService.idNumber).subscribe((results: Results<DisabilityChangeRequest>) => {
      this.results = results;
      this.servicesService.selectedStudent.next(this.results.content[0]);
      this.blockUI.stop();
    }, (error: HttpErrorResponse) => {
      this.blockUI.stop();
      this.gaService.exception(error.error?.message, true);
      this.matSnackBar.open(error.error.message, 'Okay', {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    });

  }

  confirm(item: DisabilityChangeRequest) {
    const that = this;
    bootbox.confirm({
      title: 'Are you sure you want to approve the disability change?',
      message: '<strong>' + item.application?.user?.firstName + '</strong>',
      callback: function(result) {
        if (result === null) {
        } else if (result === true) {
          that.blockUI.start();
          item.status = 'Approved';
          that.disabilityChangeRequestsService.save(item).subscribe((data) => {
            that.blockUI.stop();
          }, (error: HttpErrorResponse) => {
            that.blockUI.stop();
            that.gaService.exception(error.error?.message, true);
            if (error.status === 401) {
              that.activeModal.close();
              that.router.navigate(['/']);
              that.matSnackBar.open('Your session has expired', 'Okay', {
                duration: 5000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              });
            } else {
              that.blockUI.stop();
              that.matSnackBar.open(error.error.message, 'Okay', {
                duration: 5000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              });
            }
          });
        }
      }
    });
  }

  reject(item: DisabilityChangeRequest) {
    const that = this;
    bootbox.confirm({
      title: 'Are you sure you want to reject the disability change?',
      message: '<strong>' + item.application?.user?.firstName + '</strong>',
      callback: function(result) {
        if (result === null) {
        } else if (result === true) {
          that.blockUI.start();
          item.status = 'Rejected';
          that.disabilityChangeRequestsService.save(item).subscribe((data) => {
            that.blockUI.stop();
          }, (error: HttpErrorResponse) => {
            that.blockUI.stop();
            that.gaService.exception(error.error?.message, true);
            if (error.status === 401) {
              that.activeModal.close();
              that.router.navigate(['/']);
              that.matSnackBar.open('Your session has expired', 'Okay', {
                duration: 5000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              });
            } else {
              that.blockUI.stop();
              that.matSnackBar.open(error.error.message, 'Okay', {
                duration: 5000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              });
            }
          });
        }
      }
    });
  }

  open(disabilityChangeRequest: DisabilityChangeRequest) {
    const modalRef = this.modalService.open(DisabilityChangeRequestCheckComponent, {fullscreen: true, modalDialogClass: 'animate-bottom'});
    modalRef.componentInstance.name = 'World';
    modalRef.componentInstance.disabilityChangeRequest = disabilityChangeRequest;
    modalRef.componentInstance.application = disabilityChangeRequest.application;
    modalRef.closed.subscribe(() => {
      this.search();
    });
  }
}
