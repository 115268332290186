<div class="row justify-content-center">
  <div class="col-md-5" *ngIf="!success">
    <h4 class="mb-1"><strong>Appeals</strong></h4>

    <form (ngSubmit)="onSubmit()" #ngFormAppeal="ngForm" class="px-5 needs-validation" 
          [ngClass]="validated===true?'was-validated':''" >
      <ng-container *ngFor="let rejectReason of application.rejectReasons; index as i;trackBy: trackByFn">
      <p class="mb-1 text-primary"><strong>Rejection reason</strong></p>
      <p class="mb-4" >
        {{rejectReason?.description}}
      </p>
      <p class="mb-1 text-primary"><strong>Appeal reason</strong></p>
      <div class="form-group mt-2 mb-3">
        <select class="form-control" required [showReason]="false" [(ngModel)]="appeals[i].appealReason" (onSelect)="retrieveDocumentRequired(appeals[i])" name="appealReason.{{i}}" [rejectReason]="rejectReason" placeholder="Appeal reason" appAppealReason>
        </select>
        <div class="invalid-feedback">
          Appeal Reason is required
        </div>
      </div>
      <div *ngIf="spouse(appeals[i]) === true">
        <p>Does your spouse have a SA National ID?</p>

        <div class="row">
          <div class="col-md-9">
            <div class="radio d-block mb-3">
              <input class="form-check-input" type="radio" name="saSpouse.{{i}}" id="spouseIDNo.{{i}}" [value]="false"
                     [(ngModel)]="appeals[i].saSpouse">
              <label class="cr" for="spouseIDNo.{{i}}">No</label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="radio d-block mb-3">
              <input class="form-check-input" type="radio" name="saSpouse.{{i}}" id="spouseIDYes.{{i}}" [value]="true"
                     [(ngModel)]="appeals[i].saSpouse">
              <label class="cr" for="spouseIDYes.{{i}}">Yes</label>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="spouse(appeals[i]) == true">
        <p class="text-primary"><strong>Spouse's information</strong></p>
        <div class="input-group mb-3">
          <input type="text" class="form-control" autocomplete="off"
                 [placeholder]="appeals[i].saSpouse == true ? 'ID number' : 'Passport number'" name="spouse.idNumber.{{i}}"
                 [(ngModel)]="appeals[i].spouse.idNumber" required>
          <div class="invalid-feedback">
            Spouse's {{appeals[i].saSpouse == true ? 'ID number' : 'Passport number'}} is required
          </div>
        </div>
        <div class="input-group mb-3">
          <input type="text" autocomplete="off" class="form-control" placeholder="First name" name="spouse.firstName.{{i}}" [(ngModel)]="appeals[i].spouse.firstName" required>
          <div class="invalid-feedback">
            Spouse's First name is required
          </div>
        </div>
        <div class="input-group mb-3">
          <input type="text" autocomplete="off" class="form-control" placeholder="Last name" name="spouse.lastName.{{i}}" [(ngModel)]="appeals[i].spouse.lastName"
                 required>
          <div class="invalid-feedback">
            Spouse's Surname is required
          </div>
        </div>
        <div class="input-group mb-4">
          <input type="text" pattern="[0-9]+" autocomplete="off" class="form-control" placeholder="Annual gross income" name="spouse.income.{{i}}"
                 [(ngModel)]="appeals[i].spouse.income" required>
          <div class="invalid-feedback">
            Spouse's annual gross income is required
          </div>
        </div>
      </div>
      <div *ngIf="isFinanciallyDepdentOnParentOrParentsDeceased(appeals[i])">
        <p>Are you able to provide the details of your Father?</p>
        <div class="row">
          <div class="col-md-9">
            <div class="radio d-block mb-3">
              <input class="form-check-input" type="radio" name="fatherDetails.{{i}}" id="fatherDetailsNo.{{i}}" [value]="false"
                    [(ngModel)]="appeals[i].fatherDetails" (click)="clearFather(appeals[i])" required>
              <label class="cr" for="fatherDetailsNo.{{i}}">No</label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="radio d-block mb-3">
              <input class="form-check-input" type="radio" name="fatherDetails.{{i}}" id="fatherDetailsYes.{{i}}" [value]="true"
                    [(ngModel)]="appeals[i].fatherDetails" required>
              <label class="cr" for="fatherDetailsYes.{{i}}">Yes</label>
            </div>
          </div>
          <div class="invalid-feedback">
            Select yes or no for Fathers details
          </div>
        </div>

      </div>
      <div *ngIf="isFinanciallyDepdentOnParentOrParentsDeceased(appeals[i]) && appeals[i].fatherDetails===true">
        <p>Does your father have a SA National ID?</p>

        <div class="row">
          <div class="col-md-9">
            <div class="radio d-block mb-3">
              <input class="form-check-input" type="radio" name="saFather.{{i}}" id="fatherIDNo.{{i}}" [value]="false"
                     [(ngModel)]="appeals[i].saFather">
              <label class="cr" for="fatherIDNo.{{i}}">No</label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="radio d-block mb-3">
              <input class="form-check-input" type="radio" name="saFather.{{i}}" id="fatherIDYes.{{i}}" [value]="true"
                     [(ngModel)]="appeals[i].saFather">
              <label class="cr" for="fatherIDYes.{{i}}">Yes</label>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isFinanciallyDepdentOnParentOrParentsDeceased(appeals[i]) && appeals[i].fatherDetails===true">
        <p class="text-primary"><strong>Father's information</strong></p>
        <div class="input-group mb-3">
          <input type="text" autocomplete="off" class="form-control"
                 placeholder="{{appeals[i].saFather==true?'Id Number ':'Passport'}}" name="father.idNumber.{{i}}"
                 [(ngModel)]="appeals[i].father.idNumber" required>
          <div class="invalid-feedback">
            Father's {{appeals[i].saFather==true?'Id Number ':'Passport'}} is required
          </div>
        </div>
        <div class="input-group mb-3">
          <input type="text" autocomplete="off" class="form-control" placeholder="First name" name="father.firstName.{{i}}" [(ngModel)]="appeals[i].father.firstName"
                 required>
          <div class="invalid-feedback">
            Father's First name is required
          </div>
        </div>
        <div class="input-group mb-3">
          <input type="text" autocomplete="off" class="form-control" placeholder="Last name" name="father.lastName.{{i}}" [(ngModel)]="appeals[i].father.lastName"
                 required>

          <div class="invalid-feedback">
            Father's last name is required
          </div>
        </div>
        <div class="input-group mb-4">
          <input type="text" pattern="[0-9]+" autocomplete="off" class="form-control" placeholder="Annual gross income" name="father.income.{{i}}"
                 [(ngModel)]="appeals[i].father.income" required>
          <div class="invalid-feedback">
            Father's annual gross income
          </div>
        </div>
      </div>

      <div *ngIf="isFinanciallyDepdentOnParentOrParentsDeceased(appeals[i])">
        <p>Are you able to provide the details of your Mother?</p>

        <div class="row">
          <div class="col-md-9">
            <div class="radio d-block mb-3">
              <input class="form-check-input" type="radio" name="motherDetails.{{i}}" id="motherDetailsNo.{{i}}" [value]="false"
                     [(ngModel)]="appeals[i].motherDetails" (click)="clearMother(appeals[i])" required>
              <label class="cr" for="motherDetailsNo.{{i}}">No</label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="radio d-block mb-3">
              <input class="form-check-input" type="radio" name="motherDetails.{{i}}" id="motherDetailsYes.{{i}}" [value]="true"
                     [(ngModel)]="appeals[i].motherDetails" required>
              <label class="cr" for="motherDetailsYes.{{i}}">Yes</label>
            </div>
          </div>
        </div>


      </div>
      <div *ngIf="isFinanciallyDepdentOnParentOrParentsDeceased(appeals[i]) && appeals[i].motherDetails==true">
        <p>Does your mother have a SA National ID?</p>

        <div class="row">
          <div class="col-md-9">
            <div class="radio d-block mb-3">
              <input class="form-check-input" type="radio" name="saMother.{{i}}" id="motherIDNo.{{i}}" [value]="false"
                     [(ngModel)]="appeals[i].saMother" required>
              <label class="cr" for="motherIDNo.{{i}}">No</label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="radio d-block mb-3">
              <input class="form-check-input" type="radio" name="saMother.{{i}}" id="motherIDYes.{{i}}" [value]="true"
                     [(ngModel)]="appeals[i].saMother" required>
              <label class="cr" for="motherIDYes.{{i}}">Yes</label>
            </div>
          </div>
          <div class="invalid-feedback">
            Select yes or no for Mothers details
          </div>
        </div>
        

      </div>
      <div *ngIf="isFinanciallyDepdentOnParentOrParentsDeceased(appeals[i]) && appeals[i].motherDetails==true">
        <p class="text-primary"><strong>Mother's information</strong></p>
        <div class="input-group mb-3">
          <input type="text" autocomplete="off" class="form-control"
                 placeholder="{{appeals[i].saMother==true?'Id Number ':'Passport'}}" name="mother.idNumber.{{i}}"
                 [(ngModel)]="appeals[i].mother.idNumber" required>
          <div class="invalid-feedback">
            Mother's {{appeals[i].saMother==true?'Id Number ':'Passport'}}is required
          </div>
        </div>
        <div class="input-group mb-3">
          <input type="text" autocomplete="off" class="form-control" placeholder="First name" name="mother.firstName.{{i}}" [(ngModel)]="appeals[i].mother.firstName"
                 required>
          <div class="invalid-feedback">
            Mother's First name is required
          </div>
        </div>
        <div class="input-group mb-3">
          <input type="text" autocomplete="off" class="form-control" placeholder="Last name" name="mother.lastName.{{i}}" [(ngModel)]="appeals[i].mother.lastName"
                 required>
          <div class="invalid-feedback">
            Mother's last name is required
          </div>
        </div>
        <div class="input-group mb-4">
          <input type="text" pattern="[0-9]+" autocomplete="off" class="form-control" placeholder="Annual gross income" name="mother.cellphone.{{i}}"
                 [(ngModel)]="appeals[i].mother.income" required>
          <div class="invalid-feedback">
            Mother's annual gross income is required
          </div>
        </div>
      </div>

      <div *ngIf="isFinanciallyDepdentOnParentOrParentsDeceased(appeals[i])">
        <p>Are you able to provide the details of your Guardian?</p>

        <div class="row">
          <div class="col-md-9">
            <div class="radio d-block mb-3">
              <input class="form-check-input" type="radio" name="guardianDetails.{{i}}" id="guardianDetailsNo.{{i}}" [value]="false"
                     [(ngModel)]="appeals[i].guardianDetails" required>
              <label class="cr" for="guardianDetailsNo.{{i}}" (click)="clearGuardian(appeals[i])">No</label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="radio d-block mb-3">
              <input class="form-check-input" type="radio" name="guardianDetails.{{i}}" id="guardianDetailsYes.{{i}}" [value]="true"
                     [(ngModel)]="appeals[i].guardianDetails" required>
              <label class="cr" for="guardianDetailsYes.{{i}}">Yes</label>
            </div>
          </div>          
          <div class="invalid-feedback">
            Select yes or no for Guardians details
          </div>
        </div>
      </div>

      <div
        *ngIf="isFinanciallyDepdentOnParentOrParentsDeceased(appeals[i]) && appeals[i].guardianDetails==true">
        <p>Does your guardian have a SA National ID?</p>

        <div class="row">
          <div class="col-md-9">
            <div class="radio d-block mb-3">
              <input class="form-check-input" type="radio" name="guardianID.{{i}}" id="guardianIDNo.{{i}}" [value]="false"
                     [(ngModel)]="appeals[i].saGuardian">
              <label class="cr" for="guardianIDNo.{{i}}">No</label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="radio d-block mb-3">
              <input class="form-check-input" type="radio" name="guardianID.{{i}}" id="guardianIDYes.{{i}}" [value]="true"
                     [(ngModel)]="appeals[i].saGuardian">
              <label class="cr" for="guardianIDYes.{{i}}">Yes</label>
            </div>
          </div>
        </div>


      </div>



      <div *ngIf="isFinanciallyDepdentOnParentOrParentsDeceased(appeals[i]) && appeals[i].guardianDetails==true">
        <p class="text-primary"><strong>Guardian's information</strong></p>
        <div class="input-group mb-3">
          <input type="text" autocomplete="off" class="form-control"
                 placeholder="ID number" name="guardian.idNumber.{{i}}" [(ngModel)]="appeals[i].guardian.idNumber"
                 [placeholder]="appeals[i].saGuardian == true ? 'ID number' : 'Passport number'" required>
          <div class="invalid-feedback">
            Guardian's {{appeals[i].saGuardian==true?'Id Number ':'Passport'}} is required
          </div>
        </div>
        <div class="input-group mb-3">
          <input type="text" autocomplete="off" class="form-control" placeholder="First name" name="guardian.firstName.{{i}}" [(ngModel)]="appeals[i].guardian.firstName"
                 required>
          <div class="invalid-feedback">
            Guardian's first name is required
          </div>
        </div>
        <div class="input-group mb-3">
          <input type="text" autocomplete="off" class="form-control" placeholder="Last name" name="guardian.lastName.{{i}}" [(ngModel)]="appeals[i].guardian.lastName"
                 required>
          <div class="invalid-feedback">
            Guardian's last name is required
          </div>
        </div>
        <div class="input-group mb-4">
          <input type="text" pattern="[0-9]+" autocomplete="off" class="form-control" placeholder="Annual gross income" name="guardian.cellphone.{{i}}"
                 [(ngModel)]="appeals[i].guardian.income" required>
          <div class="invalid-feedback">
            Guardian's annual gross income is required
          </div>
        </div>
      </div>

      <div *ngIf="this.appeals[i].spouse && this.appeals[i].spouse.requiredDocuments">
        <div class="row justify-content-center" *ngFor="let requiredDocument of this.appeals[i].spouse.requiredDocuments; let a = index;trackBy: trackByFn">
          <div class="col-md-12 text-start">
            <div class="mb-5 mt-2">
              <div class="mb-3">
                <label for="formFileSm" class="form-label">{{requiredDocument.caption}}</label>
                <input required [(ngModel)]="this.appeals[i].spouse.requiredDocuments[a]" appFileUpload [file]="this.appeals[i].spouse.requiredDocuments[a]" name="spouseRequiredDocument{{i}}{{a}}" accept="image/*,.pdf" class="form-control form-control-sm" type="file">
                <div class="invalid-feedback">
                  {{this.appeals[i].spouse.requiredDocuments[a].name}} is required
                </div>
              </div>
              <small class="ps-3" *ngIf="!this.appeals[i].spouse.requiredDocuments[a]">PDF or Jpeg, max 20mb</small>
              <small class="ps-3" *ngIf="this.appeals[i].spouse.requiredDocuments[a]">{{this.appeals[i].spouse.requiredDocuments[a].name}}</small>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="row justify-content-center" *ngFor="let appealSupportingDocument of appealSupportingDocuments.get(i); let e = index;trackBy: trackByFn">
            <div class="mb-5" *ngIf="isParentOrSpouseSelectedForDocument(appealSupportingDocument.description, i)">
              <label for="formFileSm{{i}}" class="form-label">{{appealSupportingDocument.description}}</label>
              <input  [(ngModel)]="appeals[i].supportingDocuments[e]" appFileUpload name="supportingDocument{{i}}{{e}}"
                      accept="image/*,.pdf" class="form-control form-control-sm" id="formFileSm{{i}}" type="file">
              <div class="invalid-feedback">
                {{appealSupportingDocument.description}} is required
              </div>
            </div>
          </div>
        </div>
      </div>
      
      </ng-container>
      <div class="row justify-content-center">
        <div class="col-md-12">
            <button class="btn btn-primary btn-lg mb-3" type="submit">Submit</button>
        </div>
      </div>
    </form>
  </div>
  <div class="col-md-8 text-center mt-5" *ngIf="success">
    <div class="m-5">
      <img src="../../../assets/images/nsfas_logo.png" class="w-25">
    </div>
    <h2 class="mt-5">Appeal submitted successfully!</h2>
    <p class="mb-4">You have successfully submitted your appeal for NSFAS funding</p>

    <div class="card mx-auto w-75">
      <div class="card-body-thin">
        <div class="row">
          <div class="col-md-1 text-center">
            <i class="fa fa-user fa-2x pt-2"></i>

          </div>
          <div class="col-md-7 text-start">
            <p class="notif-body mb-0">{{appeals[0].application.user.firstName}} {{appeals[0].application.user.lastName}}</p>
            <p class="mb-1 text-muted">
              {{appeals[0].application.user.idNumber}}
            </p>
            <p class="mb-1 text-muted">
              Ref:  {{appeals[0].application.reference}}
            </p>
          </div>
          <div class="col-md-4 text-end align-items-center align-middle">
            <button class="btn btn-outline-primary btn-block" type="button"
                    (click)="viewApplications()">View application
            </button>

          </div>
        </div>
      </div>
    </div>


    <div class="mx-5">

      <!-- <div class="d-grid gap-2"> -->

      <button class="btn btn-primary btn-block mb-5 w-30" type="button"
              (click)="closeModal()">Dashboard
      </button>
      <!-- </div> -->
    </div>


  </div>
</div>
