import {Injectable} from '@angular/core';
import {ServicesService} from '../../users/services.service';
import {Application} from '../../applications/application/application';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ConfigServices} from '../../core/config';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {User} from '../users/user/user';
import {RequestDocument} from '../case-management/request-document';
import {StudyTerm} from '../../admin-config/study-terms/study-term/study-term';
import {AppealComment} from './appeal-comment';
import { HigherEducationInstitution } from 'src/app/higher-education-institutions/higher-education-institution/higher-education-institution';
import { HigherEducationInstitutionType } from 'src/app/higher-education-institution-types/higher-education-institution-type/higher-education-institution-type';
import { AppealRejectReason } from 'src/app/admin-config/appeal-reject-reasons/appeal-reject-reason/appeal-reject-reason';
import {AssignByAppealType} from './assign-by-appeal-type';

declare var moment: any;

@Injectable({
  providedIn: 'root'
})
export class AppealsService extends ServicesService {


  application: Application = new Application();

  constructor(public http: HttpClient, public configService: ConfigServices, public cookieService: CookieService,
              private router: Router) {
    super('admin/appeals', http, configService);
  }

  public unassign(appealId: string) {
    const url = this.configService.getApi() + this.type;
    return this.http.post(url + '/unassign/' + appealId, null, {withCredentials: true});
  }

  public getAppealStatusCount(year: number,studyTerm: StudyTerm) {
    const url = this.configService.getApi() + this.type;
    let httpParams = new HttpParams();
    httpParams = httpParams.set('year', year);
    if(studyTerm != null) {
      httpParams = httpParams.set('studyTerm', studyTerm.id);
    }
    return this.http.get(url + '/appeal-status-count', {params: httpParams,withCredentials: true});
  }

  public getAppealReasonCount(year: number,studyTerm: StudyTerm) {
    const url = this.configService.getApi() + this.type;
    let httpParams = new HttpParams();
    httpParams = httpParams.set('year', year);
    if(studyTerm != null) {
      httpParams = httpParams.set('studyTerm', studyTerm.id);
    }
    return this.http.get(url + '/appeal-reason-count', {params: httpParams,withCredentials: true});
  }

  public getReport(start: Date| undefined,end: Date| undefined) {
    let httpParams = new HttpParams();
    if(start) {
      httpParams = httpParams.set('start',moment(start).format('DD MMMM YYYY') );
    }
    if(end) {
      httpParams = httpParams.set('end',moment(end).format('DD MMMM YYYY') );
    }
    const url = this.configService.getApi() + this.type + '/report';
    return this.http.get(url,{params: httpParams});

  }
  public getAppealApplicationRejectReasonCounts(year: number,studyTerm: StudyTerm) {
    const url = this.configService.getApi() + this.type;
    let httpParams = new HttpParams();
    httpParams = httpParams.set('year', year);
    if(studyTerm != null) {
      httpParams = httpParams.set('studyTerm', studyTerm.id);
    }
    return this.http.get(url + '/appeal-application-reject-reason-count', {params: httpParams,withCredentials: true});
  }

  public getAppealAssignedCount(year: number,studyTerm: StudyTerm) {
    const url = this.configService.getApi() + this.type;
    let httpParams = new HttpParams();
    httpParams = httpParams.set('year', year);
    if(studyTerm != null) {
      httpParams = httpParams.set('studyTerm', studyTerm.id);
    }
    return this.http.get(url + '/appeal-assigned-count', {params: httpParams,withCredentials: true});
  }
  public assign(appealId: string, userId: string) {
    const url = this.configService.getApi() + this.type;
    return this.http.post(url + '/assign/' + appealId + '/' + userId, null, {withCredentials: true});
  }


  public comment(appealComment: AppealComment, appealId: string) {
    const url = this.configService.getApi() + this.type;
    return this.http.put(url + '/comment/' + appealId, appealComment, {withCredentials: true});
  }

  public authorizeRevoke(appealId: string) {
    const url = this.configService.getApi() + this.type;
    return this.http.post(url + '/revoke/authorize/' + appealId, null, {withCredentials: true});
  }

  public withdrawAppeal(appealId: string) {
    const url = this.configService.getApi() + this.type;
    return this.http.post(url + '/revoke/' + appealId, null, {withCredentials: true});
  }

  public approveAppeal(appealId: string) {
    const url = this.configService.getApi() + this.type;
    return this.http.post(url + '/approve/' + appealId, null, {withCredentials: true});
  }

  public rejectAppeal(appealId: string) {
    const url = this.configService.getApi() + this.type;
    return this.http.post(url + '/reject/' + appealId, null, {withCredentials: true});
  }

  public rejectAppealWithReason(appealId: string,appealReasonId: string) {
    const url = this.configService.getApi() + this.type;
    return this.http.post(url + '/reject/' + appealId+'/'+appealReasonId, null, {withCredentials: true});
  }

  public getRequestedDocuments(appealId: string) {
    const url = this.configService.getApi() + this.type;
    return this.http.get(url + '/requested-documents/' + appealId, {withCredentials: true});
  }

  public getRequestedDocumentByAppeal(appealId: string) {
    const url = this.configService.getApi() + this.type;
    return this.http.get(url + '/get-requested-documents/' + appealId, {withCredentials: true});
  }

  public requestDocuments(requestDocument: RequestDocument) {
    const url = this.configService.getApi() + this.type + '/request-documents';
    return this.http.post(url, requestDocument, {withCredentials: true});
  }

  public getAppealDetails(appealId: string) {
    const url = this.configService.getApi() + this.type + '/' + appealId + '/details';
    return this.http.get(url, {withCredentials: true});

  }

  public searchByStatus(page: number, perPage: number, sortOrder: string, sortField: string, keyword: string, status: string, year: string, studyTerm: StudyTerm, date: Date) {

    if (keyword === undefined) {
      keyword = '';
    }
    let httpParams = new HttpParams();
    httpParams = httpParams.set('keyword', keyword);
    httpParams = httpParams.set('year', year);
    if (studyTerm) {
      httpParams = httpParams.set('cycle', studyTerm.id);
    }
    if (date) {
      httpParams = httpParams.set('date', moment(date).format('yyyy-MM-D'));
    }
    const url = this.configService.getApi() + this.type + '/status/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField + '/' + status;
    return this.http.get(url, {params: httpParams, withCredentials: true});

  }

  public getBundle(applicationId: string) {
    const url = this.configService.getApi() + this.type + '/bundle/by-application/' + applicationId;
    return this.http.get(url, { withCredentials: true});
  }

  public searchBundle(page: number, perPage: number, sortOrder: string, sortField: string, keyword: string, institutionName: string, status: string) {

    if (keyword === undefined) {
      keyword = '';
    }
    let httpParams = new HttpParams();
    if (status) {
      httpParams = httpParams.set('status', status);
    }
    if (keyword) {
      httpParams = httpParams.set('keyword', keyword);
    }
    if (institutionName) {
      httpParams = httpParams.set('institutionName',institutionName);
    }
    const url = this.configService.getApi() + this.type + '/bundle/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField;
    return this.http.get(url, {params: httpParams, withCredentials: true});

  }

  public searchByIdNumber(idNumber: string) {

    if (idNumber === undefined) {
      idNumber = '';
    }
    let httpParams = new HttpParams();
    if (idNumber) {
      httpParams = httpParams.set('idNumber', idNumber);
    }
    const url = this.configService.getApi() + this.type + '/id-number';
    return this.http.get(url, {params: httpParams, withCredentials: true});

  }

  public searchByReferenceNumber(referenceNumber: string) {

    if (referenceNumber === undefined) {
      referenceNumber = '';
    }
    let httpParams = new HttpParams();
    if (referenceNumber) {
      httpParams = httpParams.set('referenceNumber', referenceNumber);
    }
    const url = this.configService.getApi() + this.type + '/reference-number';
    return this.http.get(url, {params: httpParams, withCredentials: true});

  }

  public detailedReport(page: number, perPage: number, sortOrder: string, sortField: string, institutionType?: HigherEducationInstitutionType, institutionName?: HigherEducationInstitution, appealType?:AppealRejectReason, status?: string, applicationType?:string, studyTerm?: StudyTerm, date?: Date){



    let httpParams = new HttpParams();

    if (date) {
      httpParams = httpParams.set('date', moment(date).format('yyyy-MM-D'));
    }
    if (institutionType) {
      httpParams = httpParams.set('institutionType', institutionType.name);
    }
    if (institutionName) {
      httpParams = httpParams.set('institutionName', institutionName.name);
    }
    if (appealType) {
      httpParams = httpParams.set('appealType', appealType.name);
    }
    if (studyTerm) {
      httpParams = httpParams.set('cycleId', studyTerm.id);
    }
    if (status!= "undefined") {
      httpParams = httpParams.set('status', status);
    }
    if (applicationType) {
      httpParams = httpParams.set('applicationType', applicationType);
    }
    const url = this.configService.getApi() + this.type + '/detailed-report/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField ;
    return this.http.get(url, {params: httpParams, withCredentials: true});
  }
 // http://localhost:8080/api/admin/appeals/detailed-report/{page}/{perPage}/{sortOrder}/{sortField}

  public getCountOfAppealsWorkedOn(userId: string) {
    const url = this.configService.getApi() + this.type + '/evaluated/' + userId + '/count';
    return this.http.get(url, {withCredentials: true});

  }

  public getAppealsWorkedOn(userId: string) {
    const url = this.configService.getApi() + this.type + '/evaluated/' + userId + '/appeals';
    return this.http.get(url, {withCredentials: true});

  }

  public getAllocated(userId: string, status: string) {
    const url = this.configService.getApi() + this.type + '/allocated/' + userId + '/' + status;
    return this.http.get(url, {withCredentials: true});

  }

  public rejectRequestReProcess(appealId: string, rejectAppealReprocessReasonId: string) {
    const url = this.configService.getApi() + this.type + '/reject-request-to-process/' + appealId + '/' + rejectAppealReprocessReasonId;
    return this.http.post(url,null, {withCredentials: true});
  }

  public approveRequestReProcess(appealId: string) {
    const url = this.configService.getApi() + this.type + '/approve-request-to-process/' + appealId;
    return this.http.post(url,null, {withCredentials: true});
  }



  public getAllocatedAppeals(user: User) {
    const url = this.configService.getApi() + this.type + '/allocated/';
    return this.http.post(url, user, {withCredentials: true});

  }


  public allocate(user: User, count: number) {
    const url = this.configService.getApi() + this.type + '/allocate/' + count;
    return this.http.post(url, user, {withCredentials: true});

  }

  public allocateByAppealTypes(assignByAppealType: AssignByAppealType) {
    const url = this.configService.getApi() + this.type + '/allocate-by-appeal-types';
    return this.http.post(url, assignByAppealType, {withCredentials: true});
  }

  public getTypes() {
    const url = this.configService.getApi() + this.type + '/types';
    return this.http.get(url, {withCredentials: true});

  }

  public unallocate(user: User) {
    const url = this.configService.getApi() + this.type + '/unallocate';
    return this.http.post(url, user, {withCredentials: true});

  }

  public unallocateAll() {
    const url = this.configService.getApi() + this.type + '/unallocate-all';
    return this.http.get(url, {withCredentials: true});

  }

  public getUnassigned(page: number, perPage: number, sortOrder: string, sortField: string, keyword: string) {

    if (keyword === undefined) {
      keyword = '';
    }

    let httpParams = new HttpParams();
    httpParams = httpParams.set('keyword', keyword);
    const url = this.configService.getApi() + this.type + '/unassigned/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField;
    return this.http.get(url, {params: httpParams,withCredentials: true});

  }

  public download(institutionType?: HigherEducationInstitutionType, institutionName?: HigherEducationInstitution, appealType?:AppealRejectReason,studyTerm?:StudyTerm,applicationType?:string, status?: string, date?: Date) {



    if (status === undefined) {
      status = '';
    }

    let httpParams = new HttpParams();

    // httpParams = httpParams.set('institutionType', institutionType);
    // httpParams = httpParams.set('institutionName', institutionName);
    //httpParams = httpParams.set('appealType', appealType);
    httpParams = httpParams.set('status', status);


    if (date) {
      httpParams = httpParams.set('date', moment(date).format('yyyy-MM-D'));
    }
    if (institutionType) {
      httpParams = httpParams.set('institutionType', institutionType.name);
    }
    if (institutionName) {
      httpParams = httpParams.set('institutionName', institutionName.name);
    }
    if (appealType) {
      httpParams = httpParams.set('appealType', appealType.name);
    }
    if (studyTerm) {
      httpParams = httpParams.set('cycleId', studyTerm.id);
    }
    if (applicationType) {
      httpParams = httpParams.set('applicationType', applicationType);
    }
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Accept', 'text/csv');

    const url = this.configService.getApi() + this.type + '/download-detailed-report';
    return this.http.get(url, {headers: httpHeaders,params: httpParams, responseType: 'blob'});

  }

  public getAllocation(date: Date,page: number, perPage: number, sortOrder: string, sortField: string, user: User) {
    let httpParams = new HttpParams();
    if(user && user.id) {
      httpParams = httpParams.set('userId', user.id);
    }
    const url = this.configService.getApi() + this.type + '/allocation/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField +'/' + moment(date).format('yyyy-MM-DD');
    return this.http.get(url, {params: httpParams,withCredentials: true});
  }

  public getProductivity(date: Date,page: number, perPage: number, sortOrder: string, sortField: string, user: User) {
    let httpParams = new HttpParams();
    if(user && user.id) {
      httpParams = httpParams.set('userId', user.id);
    }
    const url = this.configService.getApi() + this.type + '/productivity/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField +'/' + moment(date).format('yyyy-MM-DD');

    return this.http.get(url, {params: httpParams,withCredentials: true});
  }

  public getHourlyProgress(date: Date,page: number, perPage: number, sortOrder: string, sortField: string, user: User) {
    let httpParams = new HttpParams();
    if(user && user.id) {
      httpParams = httpParams.set('userId', user.id);
    }
    const url = this.configService.getApi() + this.type + '/hourlyprogress/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField +'/' + moment(date).format('yyyy-MM-DD');
    return this.http.get(url, {params: httpParams,withCredentials: true});
  }

  public downloadWorkAllocation(date: Date,user:User,userRole) {
    let httpParams = new HttpParams();
    if(user) {
    httpParams = httpParams.set('userId', user.id);
   }
   if(userRole) {
    httpParams = httpParams.set('userRole', userRole);
   }

    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Accept', 'text/csv');
    const url = this.configService.getApi() + this.type + '/download-work-allocation/'+ moment(date).format('yyyy-MM-DD');
    return this.http.get(url, {params: httpParams, responseType: 'blob'});

  }

  public downloadHourlyProgress(date: Date,user:User,userRole) {
    let httpParams = new HttpParams();
    if(user) {
    httpParams = httpParams.set('userId', user.id);
   }
   if(userRole) {
    httpParams = httpParams.set('userRole', userRole);
   }
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Accept', 'text/csv');
    const url = this.configService.getApi() + this.type + '/download-hourly-progress/'+ moment(date).format('yyyy-MM-D');
    return this.http.get(url, {params: httpParams, responseType: 'blob'});

  }

  public downloadProductivity(date: Date,user:User,userRole) {
    let httpParams = new HttpParams();
    if(user) {
    httpParams = httpParams.set('userId', user.id);
   }
   if(userRole) {
    httpParams = httpParams.set('userRole', userRole);
   }
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Accept', 'text/csv');
    const url = this.configService.getApi() + this.type + '/download-productivity/'+ moment(date).format('yyyy-MM-D');
    return this.http.get(url, {params: httpParams, responseType: 'blob'});

  }

  public searchByAppealReason(page: number, perPage: number, sortOrder: string, sortField: string, keyword: string,reason: string) {

    const url = this.configService.getApi() + this.type;
    let httpParams = new HttpParams();
    if(keyword) {
      httpParams = httpParams.set('keyword', keyword);
    }
    if(reason) {
      httpParams = httpParams.set('reason', reason);
    }
    return this.http.get(url + '/appeal-reason/'+page+'/'+perPage+'/'+sortOrder+'/'+sortField, {params: httpParams,withCredentials: true});
  }

  //localhost:8080/api/admin/appeals/adownload-appeal-reason/{page}/{perPage}/{sortOrder}/{sortField}
  public downloadIHavePassed(page: number, perPage: number, sortOrder: string, sortField: string,reason: string){
    const url = this.configService.getApi() + this.type;
    let httpParams = new HttpParams();
    if(reason) {
      httpParams = httpParams.set('reason', reason);
    }
    return this.http.get(url + '/download-appeal-reason/'+page+'/'+perPage+'/'+sortOrder+'/'+sortField, {params: httpParams,withCredentials: true,responseType: 'blob'});
  }

}
