<div class="row justify-content-center">
  <div class="col-md-9 text-center">
    <form class="d-inline-block">
      <input type="text" class="form-control" name="keyword" [(ngModel)]="keyword" (ngModelChange)="search()"
             placeholder="Search">
    </form>
  </div>
  <div class="col-md-3">
    <button type="button" (click)="newItem()" class="btn btn-primary btn-block">Add</button>
  </div>

</div>
<div class="row justify-content-center">
  <div class="col-md-9 text-center">
    <div class="table-responsive">
    <table class="table table-borderless text-start mt-4 table-hover">
      <thead>
      <tr>
        <th>Name</th>
        <th></th>
      </tr>
      </thead>
      <tbody>


      <tr *ngFor="let template of results.content">

        <td (click)="open(template)">{{template.name}}</td>
        <td><i class="fa fa-close" (click)="remove(template)"></i></td>
      </tr>
      </tbody>
      <tfoot *ngIf="results.totalElements > 0">
      <td>
        <div class="dataTables_info" id="dynamic-table_info" role="status" aria-live="polite">
          Showing {{((currentPage - 1) * perPage) + 1}} to {{results.numberOfElements * currentPage}} of
          {{results.totalElements}} entries
        </div>
      </td>
      <td class="text-right">

        <ngb-pagination [collectionSize]="results.totalElements"
                        [pageSize]="perPage"
                        [(page)]="currentPage"
                        [maxSize]="10"
                        [rotate]="true"
                        (pageChange)="search()"></ngb-pagination>
      </td>
      </tfoot>
    </table>
    </div>
  </div>
</div>
