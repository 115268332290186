import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { Grade } from './grade';
import {CoreComponent} from '../../../core/core.component';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {GradesService} from '../grades.service';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpErrorResponse} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Meta, Title} from '@angular/platform-browser';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
declare var $: any;
@Component({
  selector: 'app-grade',
  templateUrl: './grade.component.html',
  styleUrls: ['./grade.component.scss']
})
export class GradeComponent extends CoreComponent<Grade> {

  @Input() item: Grade = new Grade();
  validated= false;
  @ViewChild('ngForm', { static: false }) public ngForm: NgForm;
  @BlockUI() blockUI: NgBlockUI;
  constructor(protected servicesService: GradesService, private modalService: NgbModal,public meta: Meta, public title: Title,
              protected gaService: GoogleAnalyticsService,
              public route: ActivatedRoute,public activeModal: NgbActiveModal,private router: Router, private matSnackBar: MatSnackBar) {
    super(servicesService,route);
    this.title.setTitle('Grade');
  }

  onSubmit() {

    if (!this.ngForm.valid) {
      this.validated = true;
      $('html, body').animate({
        scrollTop: 0
      }, 1000, function() {
      });
      return;
    } else {
      this.blockUI.start();
      this.servicesService.save(this.item).subscribe((item: Grade) => {
        this.blockUI.stop();
        this.activeModal.close();
        this.item = item;

      }, (error: HttpErrorResponse) => {
        this.blockUI.stop();
        this.gaService.exception(error.error?.message, true);
        if (error.status === 401) {
          this.activeModal.close();
          this.router.navigate(['/']);
          this.matSnackBar.open('Your session has expired', 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }else {
          this.blockUI.stop();
          this.matSnackBar.open(error.error.message, 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
      });
    }

  }
}
