<div class="col-md-8 text-center mt-5" >
    <div class="m-5">
      <img src="../../../assets/images/nsfas_logo.png" class="w-25">
    </div>
    <h2 class="mt-5">Application submitted successfully!</h2>
    <!-- <p class="mb-4">You have successfully submitted your application for NSFAS funding</p> -->

    <div class="card mx-auto w-75">
      <div class="card-body-thin">
        <div class="row">
          <div class="col-md-1 text-center">
            <i class="fa fa-user fa-2x pt-2"></i>

          </div>
          <div class="col-md-7 text-start">
            <p class="notif-body mb-0">{{applicationsService.application.user.firstName}} {{applicationsService.application.user.lastName}}</p>
            <p class="mb-1 text-muted">
              {{applicationsService.application.user.idNumber}}
            </p>
            <p class="mb-1 text-muted">
              Ref:{{applicationsService.application.reference}}
            </p>

          </div>
          <div class="col-md-4 text-end align-items-center align-middle">
            <button class="btn btn-outline-primary btn-block" type="button" (click)="viewApplication()">View application</button>

          </div>
        </div>
      </div>
    </div>

    <div class="mx-5">
      <!--            <div class="alert alert-light"><small>Please note your application is {{applicationsService?.application?.status}}</small></div>-->

      <!-- <div class="d-grid gap-2"> -->

      <button class="btn btn-primary btn-block mb-5 w-30" type="button"
              (click)="closeModal()">Dashboard</button>
      <!-- </div> -->
    </div>



  </div>