import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {NgForm} from '@angular/forms';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfigService} from '../../../config.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HttpErrorResponse} from '@angular/common/http';
import {AppealsService} from '../../../appeals/appeals.service';
import {Appeal} from '../../appeal/appeal';
import {AppealEvent} from '../appeal.event';
import {Application} from '../../../applications/application/application';
import {GoogleAnalyticsService} from 'ngx-google-analytics';

declare var $: any;

@Component({
  selector: 'app-withdraw-appeal',
  templateUrl: './withdraw-appeal.component.html',
  styleUrls: ['./withdraw-appeal.component.scss']
})
export class WithdrawAppealComponent implements OnInit {
  @Output() closeWithdraw = new EventEmitter();
  @Input() appeal: Appeal;
  @BlockUI() blockUI: NgBlockUI;
  @Input() kinType: string;
  @ViewChild('ngForm', {static: false})
  public ngForm: NgForm;
  public validated: boolean;

  constructor(public activeModal: NgbActiveModal, public appealsService: AppealsService,
              public configService: ConfigService, private modalService: NgbModal,
              protected gaService: GoogleAnalyticsService,
              private matSnackBar: MatSnackBar) {
  }

  ngOnInit() {

  }

  dashboard() {
    this.activeModal.close();
  }

  close() {
    this.closeWithdraw.emit();
  }

  save() {
    this.blockUI.start();
    this.appealsService.withdraw(this.appeal).subscribe((appeal: Appeal) => {
      this.appeal = appeal;
      this.appeal.status ='Revoked';
      this.blockUI.stop();
    }, (error: HttpErrorResponse) => {
      this.gaService.exception(error.error?.message, true);
      this.blockUI.stop();
      this.matSnackBar.open(error.error.message, 'Okay', {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    });
  }

  onSubmit() {

    if (!this.ngForm.valid) {
      this.validated = true;
      $('html, body').animate({
        scrollTop: 0
      }, 1000, function() {
      });
      return;
    } else {
      this.save();
    }

  }

}
