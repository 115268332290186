<div class="row  justify-content-center">

  <div class="col-md-6">
    <form class="d-inline-block">
      <div class="row">
        <div class="col-md-9">
          <input type="text" autocomplete="off" class="form-control" [(ngModel)]="contactCenterService.idNumber"
                 name="keyword" placeholder="Search">
        </div>
        <div class="col-md-3">
          <button type="button" class="btn btn-primary btn-circle btn-xl" (click)="search()">
            <i class="fa fa-arrow-right"></i>
          </button>
        </div>
      </div>
      <p class="text-muted">Search by id number.</p>
    </form>
  </div>

</div>



<div class="row justify-content-center">
  <div class=" col-md-12">
    <div class="table-responsive">
      <table class="table table-borderless text-start mt-4 table-hover">
        <thead>
        <tr>
          <th>ID Number</th>
          <th>First name</th>
          <th>Gender</th>
          <th>Surname</th>
          <th>Cellphone</th>
          <th>Email</th>
          <th>Current Disability Status</th>
          <th>Requested Disability Status</th>
          <th>Status</th>
        </tr>

        </thead>
        <tbody>
        <tr *ngFor="let disabilityChangeRequest of results?.content">
          <td  (click)="open(disabilityChangeRequest)" >{{disabilityChangeRequest?.application.user?.idNumber}}</td>
          <td  (click)="open(disabilityChangeRequest)">{{disabilityChangeRequest?.application.user?.firstName}}</td>
          <td  (click)="open(disabilityChangeRequest)">{{disabilityChangeRequest?.application.user?.gender}}</td>
          <td (click)="open(disabilityChangeRequest)">{{disabilityChangeRequest?.application.user?.lastName}}</td>
          <td  (click)="open(disabilityChangeRequest)">{{disabilityChangeRequest?.application.user?.cellphone}}</td>
          <td  (click)="open(disabilityChangeRequest)">{{disabilityChangeRequest?.application.user?.email}}</td>
          <td  (click)="open(disabilityChangeRequest)">{{disabilityChangeRequest?.application?.disabled==true ? 'Yes' : 'No'}}</td>
          <td>{{disabilityChangeRequest?.to ? 'Yes' : 'No'}}</td>
          <td>{{disabilityChangeRequest.status}}</td>
          <td>
            <button *ngIf="disabilityChangeRequest.status ==='Requested'" type="button" class="btn btn-sm btn-outline-dark" (click)="reject(disabilityChangeRequest)">Reject</button>
            <button *ngIf="disabilityChangeRequest.status ==='Requested'" type="button" class="btn btn-sm btn-primary" (click)="confirm(disabilityChangeRequest)">Approve</button>
          </td>
        </tr>
        </tbody>
        <tfoot *ngIf="results.totalElements > 0">
        <td>
          <div class="dataTables_info" id="dynamic-table_info" role="status" aria-live="polite">
            Showing {{((currentPage - 1) * perPage) + 1}} to {{results.numberOfElements * currentPage}} of
            {{results.totalElements}} entries
          </div>
        </td>
        <td class="text-right">
          <ngb-pagination [collectionSize]="results.totalElements"
                          [pageSize]="perPage"
                          [(page)]="currentPage"
                          [maxSize]="10"
                          [rotate]="true"
                          (pageChange)="search()"></ngb-pagination>
        </td>
        </tfoot>

      </table>
    </div>
  </div>



</div>

