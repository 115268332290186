<app-card>
  <div class="row justify-content-center">
    <div class="col-md-5">
      <h3 class="text-center mt-3 mb-0">{{item.role}}  Details</h3>
      <form (ngSubmit)="onSubmit()" #ngForm="ngForm" #userForm="ngForm" [ngClass]="validated?'was-validated':''" style="z-index: 1000;">
        <div class="input-group mb-3">
          <select class="form-control" appUserRole name="role"  [(ngModel)]="item.role" (selected)="ngOnInit()"  required></select>
          <div class="invalid-feedback">
            Role is required
          </div>
        </div>

        <div class="input-group mb-3" *ngIf="item.role ==='CaseWorker' || item.role ==='CaseQualityAssurance' || item.role ==='AppealWorker'|| item.role ==='AppealTeamLead' || item.role ==='ContactCenterWorker'">
          <select class="form-control" appUser [role]="role" [placeholder]="'Select Manager'" name="manager" [(ngModel)]="item.manager" required></select>
          <div class="invalid-feedback">
            Manager is required
          </div>
        </div>

        <div class="input-group mb-3 justify-content-center" *ngIf="item.role ==='AppealWorker' || item.role ==='AppealQualityAssurance' || item.role ==='AppealTeamLead' || item.role ==='AppealManager' || item.role === 'ContactCenterWorker' || item.role ==='ContactCenterStudentDetailUpdate'">
          <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" (click)="item.workerStatus='Present'" [ngClass]="item.workerStatus=== 'Present' ? 'btn-primary' : 'btn-outline-primary'" class="btn">Present</button>
            <button type="button" (click)="item.workerStatus='Absent'" [ngClass]="item.workerStatus=== 'Absent' ? 'btn-primary' : 'btn-outline-primary'" class="btn">Absent</button>
          </div>
        </div>

        <div class="input-group mb-3 justify-content-center" *ngIf="item.role ==='CaseWorker' || item.role ==='CaseQualityAssurance' || item.role ==='AppealWorker'|| item.role ==='AppealTeamLead' || item.role ==='ContactCenterWorker' || item.role ==='AppealWorker' || item.role ==='AppealQualityAssurance' || item.role ==='AppealTeamLead' || item.role ==='AppealManager'|| item.role ==='CaseTeamLead'">

        <div class="btn-group" role="group" aria-label="Basic example">
            <button type="button" (click)="item.appealWorkerStudentType='FTEN'" [ngClass]="item.appealWorkerStudentType=== 'FTEN' ? 'btn-primary' : 'btn-outline-primary'" class="btn">FTEN</button>
            <button type="button" (click)="item.appealWorkerStudentType='Continuing'" [ngClass]="item.appealWorkerStudentType=== 'Continuing' ? 'btn-primary' : 'btn-outline-primary'" class="btn">Continuing</button>
            <button type="button" (click)="item.appealWorkerStudentType='Returning'" [ngClass]="item.appealWorkerStudentType=== 'Returning' ? 'btn-primary' : 'btn-outline-primary'" class="btn">Returning</button>
            <button type="button" (click)="item.appealWorkerStudentType='Senior'" [ngClass]="item.appealWorkerStudentType=== 'Senior' ? 'btn-primary' : 'btn-outline-primary'" class="btn">Senior</button>
          </div>
        </div>

        <div class="input-group mb-3">
          <input type="text" autocomplete="off" class="form-control"  name="firstName" placeholder="First Name" [(ngModel)]="item.firstName">
          <div class="invalid-feedback">
            First Name is required
          </div>
        </div>

        <div class="input-group mb-3">
          <input type="text" autocomplete="off" class="form-control"  name="lastName" placeholder="Last Name" [(ngModel)]="item.lastName" required>
          <div class="invalid-feedback">
            Last Name is required
          </div>
        </div>

        <div class="input-group mb-3">
          <input type="text" autocomplete="off" class="form-control"  name="email" placeholder="Email" [(ngModel)]="item.email" required>
          <div class="invalid-feedback">
            Email is required
          </div>
        </div>

        <div class="input-group mb-3">
          <input type="text" autocomplete="off" class="form-control"  name="cellphone" placeholder="Cellphone"  [(ngModel)]="item.cellphone" required>
          <div class="invalid-feedback">
            Cellphone is required
          </div>
        </div>

        <div class="input-group mb-3">
          <input type="text" autocomplete="off" class="form-control"  name="idNumber" placeholder="Id Number" [(ngModel)]="item.idNumber">

        </div>

        <div class="input-group mb-3">
          <input type="password" class="form-control"  name="password" placeholder="Password" [(ngModel)]="item.password">
          <div class="invalid-feedback">
            Password is required
          </div>
        </div>

        <div class="d-grid gap-2">
          <button class="btn btn-primary btn-block mb-5" type="submit">Save</button>
        </div>
      </form>
    </div>
  </div>
</app-card>
