import { Injectable } from '@angular/core';
import {ServicesService} from '../users/services.service';
import {HttpClient} from '@angular/common/http';
import {ConfigServices} from '../core/config';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {Application} from '../applications/application/application';
import {RejectReason} from '../admin-config/reject-reasons/reject-reason/reject-reason';

@Injectable({
  providedIn: 'root'
})
export class AppealReasonsService extends ServicesService {

  constructor(public http: HttpClient, public configService: ConfigServices, public cookieService: CookieService, private router: Router) {
    super('appeal-reasons', http, configService);
  }

  public getAppealReasons(application: Application) {

    const url = this.configService.getApi() + this.type + '/by-application/'+application.id;
    return this.http.get(url,{  withCredentials: true });

  }

  public getAppealReasonsByRejectReason(rejectReason: RejectReason) {

    const url = this.configService.getApi() + this.type + '/by-reject-reason/'+rejectReason.id;
    return this.http.get(url,{  withCredentials: true });

  }
}
