
export class Kin {
    public financialDep: string;
    public employed: string;
    public relationship: string;
    public idNumber: string;
    public firstName: string;
    public lastName: string;
    public cellphone: string;
    public sassa: string;
    public disability: string;
    public married: boolean;
    public spouseID: boolean;
    public fatherID: boolean;
    public fatherDetails : boolean;
    public motherID: boolean;
    public motherDetails: boolean;
    public guardianID: boolean;
    public guardianDetails: boolean;
}
