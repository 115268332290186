import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {CoreComponent} from '../../../core/core.component';
import {Race} from './race';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {NgForm} from '@angular/forms';
import {RacesService} from '../races.service';
import {ActivatedRoute} from '@angular/router';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Meta, Title} from '@angular/platform-browser';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
declare var $: any;
@Component({
  selector: 'app-race',
  templateUrl: './race.component.html',
  styleUrls: ['./race.component.scss']
})
export class RaceComponent extends CoreComponent<Race> {

  @Input() item: Race = new Race();
  validated= false;
  @ViewChild('ngForm', { static: false }) public ngForm: NgForm;
  @BlockUI() blockUI: NgBlockUI;
  constructor(protected servicesService: RacesService, public route: ActivatedRoute,public activeModal: NgbActiveModal,public meta: Meta,
              protected gaService: GoogleAnalyticsService,
              public title: Title) {
    super(servicesService,route);
    this.title.setTitle('Race');
  }

  onSubmit() {

    if (!this.ngForm.valid) {
      this.validated = true;
      $('html, body').animate({
        scrollTop: 0
      }, 1000, function() {
      });
      return;
    } else {
      this.blockUI.start();
      this.servicesService.save(this.item).subscribe((item: Race) => {
        this.blockUI.stop();
        this.activeModal.close();
        this.item = item;

      },error => {
        this.gaService.exception(error.error?.message, true);
      });
    }

  }


}
