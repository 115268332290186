import { Injectable } from '@angular/core';
import {ServicesService} from "../users/services.service";
import {HttpClient, HttpParams} from '@angular/common/http';
import {ConfigServices} from "../core/config";
import {CookieService} from "ngx-cookie-service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class HigherEducationInstitutionTypesService extends ServicesService {

  constructor(public http: HttpClient, public configService: ConfigServices,public cookieService: CookieService, private router: Router) {
    super('higher-education-institution-types', http, configService);
  }

  public searchOpenedYear() {
    const url = this.configService.getApi() + this.type + '/opened/years';
    return this.http.get(url, {withCredentials: true});

  }
}
