import {Province} from '../admin-config/provinces/province/province';

export class Address {
  country: string;
  formattedAddress: string;
  latitude: number;
  line1: string;
  line2: string;
  longitude: number;
  position: number[];
  postalCode: string;
  province: string;
  suburb: string;
  town: string;
  provinceName: Province;
}
