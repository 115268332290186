import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ConfigServices } from 'src/app/core/config';

@Injectable({
  providedIn: 'root'
})
export class ContactCenterService {

  idNumber: string;
  constructor(public http: HttpClient, public configService: ConfigServices, public cookieService: CookieService, private router: Router) { }

  downloadAcademicDetails(idNumber:string) {

    let httpParams = new HttpParams();
    if(idNumber !== undefined) {
      httpParams = httpParams.set('idNumber', idNumber);
    }
    const url = this.configService.getApi() + `contact-center/download-student-academic-report`;
    return this.http.get(url, {params: httpParams,withCredentials: true,responseType: 'blob'});
  }

  downloadApplicationReport(idNumber:string){
    const url = this.configService.getApi() + `contact-center/download-student-application-report/${idNumber}`;
    return this.http.get(url, {withCredentials: true,responseType: 'blob'});
  }

  downloadNotesReport(idNumber:string){
    const url = this.configService.getApi() + `contact-center/download-student-notes-report/${idNumber}`;
    return this.http.get(url, {withCredentials: true,responseType: 'blob'});
  }

  downloadRegistrationReport(idNumber:string){
    const url = this.configService.getApi() + `contact-center/download-student-registration-report/${idNumber}`;
    return this.http.get(url, {withCredentials: true,responseType: 'blob'});
  }

  downloadFundedReport(idNumber:string){
    const url = this.configService.getApi() + `contact-center/download-student-funding-report/${idNumber}`;
    return this.http.get(url, {withCredentials: true,responseType: 'blob'});
  }

  downloadHistoryReport(idNumber:string){
    const url = this.configService.getApi() + `contact-center/view-history-report/${idNumber}`;
    return this.http.get(url, {withCredentials: true,responseType: 'blob'});
  }

  downloadViewDocumentsReport(idNumber:string){
    const url = this.configService.getApi() + `contact-center/view-document-report/${idNumber}`;
    return this.http.get(url, {withCredentials: true,responseType: 'blob'});
  }

}
