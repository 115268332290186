import {Component, Input, OnInit} from '@angular/core';
import {CoreComponent} from '../core/core.component';
import {Template} from '../admin/templates/template/template';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {Meta, Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Results} from '../core/results';
import {AppealReason} from './appeal-reason/appeal-reason';
import {AppealReasonsService} from './appeal-reasons.service';
import {AppealReasonComponent} from './appeal-reason/appeal-reason.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HttpErrorResponse} from '@angular/common/http';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
declare var bootbox: any;
@Component({
  selector: 'app-appeal-reasons',
  templateUrl: './appeal-reasons.component.html',
  styleUrls: ['./appeal-reasons.component.scss']
})
export class AppealReasonsComponent extends CoreComponent<AppealReason> {
  @BlockUI() blockUI: NgBlockUI;
  constructor(protected servicesService: AppealReasonsService, public route: ActivatedRoute,protected gaService: GoogleAnalyticsService,
              private modalService: NgbModal, private matSnackBar: MatSnackBar,public meta: Meta, public title: Title) {
    super(servicesService,route);
    this.title.setTitle('Appeal Reasons');
  }

  remove(item: AppealReason){
    const that = this;
    bootbox.confirm({
      title: 'Are you sure you want to delete?',
      message: '<strong>'+item.description+'</strong>',
      callback: function(result) {
        if (result === null) {
        } else if (result === true) {

          that.servicesService.delete(item.id).subscribe(()=>{
            that.search();
          },error => {
            that.gaService.exception(error.error?.message, true);
          });
        }
      }
    });
  }

  public search() {
    this.blockUI.start();
    this.servicesService.search(this.currentPage, this.perPage, this.order, this.orderBy, this.keyword)
      .subscribe((data: Results<AppealReason>) => {
        this.results = data;
        this.blockUI.stop();
      }, (error: HttpErrorResponse) => {
        this.blockUI.stop();
        this.gaService.exception(error.error?.message, true);
        this.matSnackBar.open(error.error.message, 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      });
  }

  newItem() {
    this.item = new AppealReason();
    this.open(this.item);
  }
  open(item: AppealReason) {
    const modalRef = this.modalService.open(AppealReasonComponent, {fullscreen:true, modalDialogClass:'animate-bottom'});
    modalRef.componentInstance.item= item;
    modalRef.componentInstance.name = 'World';
    modalRef.closed.subscribe(() => {
      this.search();
    });
  }


}
