import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {CoreComponent} from '../../../core/core.component';
import {NgForm} from '@angular/forms';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RejectionReason} from './rejection-reason';
import {RejectionReasonsService} from '../rejection-reasons.service';
import {HttpErrorResponse} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Meta, Title} from '@angular/platform-browser';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
declare var $: any;
@Component({
  selector: 'app-rejection-reason',
  templateUrl: './rejection-reason.component.html',
  styleUrls: ['./rejection-reason.component.scss']
})
export class RejectionReasonComponent extends CoreComponent<RejectionReason> {

  @Input() item: RejectionReason = new RejectionReason();
  validated= false;
  @ViewChild('ngForm', { static: false }) public ngForm: NgForm;
  @BlockUI() blockUI: NgBlockUI;
  constructor(public modalService: NgbModal,protected servicesService: RejectionReasonsService,
              public route: ActivatedRoute,public activeModal: NgbActiveModal,protected gaService: GoogleAnalyticsService,
              private matSnackBar: MatSnackBar,private router: Router, public meta: Meta, public title: Title) {
    super(servicesService,route);
    this.title.setTitle('Rejection Reason');
  }

  onSubmit() {

    if (!this.ngForm.valid) {
      this.validated = true;
      $('html, body').animate({
        scrollTop: 0
      }, 1000, function() {
      });
      return;
    } else {
      this.blockUI.start();
      this.servicesService.save(this.item).subscribe((item: RejectionReason) => {
        this.blockUI.stop();
        this.activeModal.close();
        this.item = item;
      }, (error: HttpErrorResponse) => {
        this.blockUI.stop();
        this.gaService.exception(error.error?.message, true);
        if (error.status === 401) {
          this.activeModal.close();
          this.router.navigate(['/']);
          this.matSnackBar.open('Your session has expired', 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }else {
          this.blockUI.stop();
          this.matSnackBar.open(error.error.message, 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
      });
    }

  }

}
