import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CoreComponent } from '../../core/core.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigServices } from '../../core/config';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgForm } from '@angular/forms';
import { User } from '../users/user/user';
import { UsersService } from '../../users/users.service';
import { CaseManagementService } from '../case-management/case-management.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ApplicationEvent } from '../../applications/application/application.event';
import { Case } from '../case-management/case';
import { DomSanitizer } from '@angular/platform-browser';
import { Attachment } from '../../core/models';
import { RequestDocument } from '../case-management/request-document';
import { RequiredDocumentsService } from '../required-documents/required-documents.service';
import { RequestedDocuments } from '../case-management/requested-documents';
import { Meta, Title } from '@angular/platform-browser';
import { Application, DhaRelative, Spouse } from '../../applications/application/application';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import * as _ from 'lodash';
import { FilenameUtilsService } from '../../utils/filename-utils.service';
import {
  DisabilityChangeRequestComponent
} from '../../disability-change-requests/disability-change-request/disability-change-request.component';

declare var $: any;

@Component({
  selector: 'app-exception-management',
  templateUrl: './exception-management.component.html',
  styleUrls: ['./exception-management.component.scss']
})

export class ExceptionManagementComponent extends CoreComponent<Case> {
  public validated: boolean;
  @BlockUI() blockUI: NgBlockUI;
  @ViewChild('ngForm', { static: false }) public ngForm: NgForm;
  active = 1;
  approve = false;
  reject = null;
  user: User = new User();
  requestedDocuments: RequestedDocuments = new RequestedDocuments();
  applicationEvents: Array<ApplicationEvent> = new Array<ApplicationEvent>();
  attachment: Attachment;

  father: DhaRelative;
  mother: DhaRelative;
  spouse: DhaRelative;
  isLoading:boolean = true;
  guardian: DhaRelative;

  constructor(public modalService: NgbModal, public usersService: UsersService, private requiredDocumentsService: RequiredDocumentsService,
              private router: Router, public servicesService: CaseManagementService, private matSnackBar: MatSnackBar,
              public activeModal: NgbActiveModal, protected gaService: GoogleAnalyticsService,
              public route: ActivatedRoute, public configServices: ConfigServices, public sanitizer: DomSanitizer, public meta: Meta,
              public title: Title,public http: HttpClient, private filenameUtilsService: FilenameUtilsService) {
    super(servicesService, route);
  }

  ngOnInit(): void {
    this.fetchCaseDetails();
    this.title.setTitle('Exception Management');
    if (this.item.application.disabilityAnnexure) {
      this.getURL(this.item.application.disabilityAnnexure);

    }
    if (this.item.application.vulnerableChildOrNonSassDeclaration) {
      this.getURL(this.item.application.vulnerableChildOrNonSassDeclaration);
    }
    if (this.item.application?.spouse?.supportingDocs) {
      for (let a = 0; a < this.item.application?.spouse?.supportingDocs.length; a++) {
        this.getURL(this.item.application?.spouse?.supportingDocs[a]);
      }
    }

    if (this.item.application?.mother?.supportingDocs) {
      for (let a = 0; a < this.item.application?.mother?.supportingDocs.length; a++) {
        this.getURL(this.item.application?.mother?.supportingDocs[a]);
      }
    }

    if (this.item.application?.father?.supportingDocs) {
      for (let a = 0; a < this.item.application?.father?.supportingDocs.length; a++) {
        this.getURL(this.item.application?.father?.supportingDocs[a]);

      }
    }

    if (this.item.application?.guardian?.supportingDocs) {
      for (let a = 0; a < this.item.application?.guardian?.supportingDocs.length; a++) {
        this.getURL(this.item.application?.guardian?.supportingDocs[a]);
      }
    }

    if (this.item.submittedDocuments) {
      if (this.item.submittedDocuments.disabilityAnnexure) {
        this.getURL(this.item.submittedDocuments.disabilityAnnexure);
      }
      if (this.item.submittedDocuments?.vulnerableChildOrNonSassDeclaration) {
        this.getURL(this.item.submittedDocuments.vulnerableChildOrNonSassDeclaration);
      }
      if (this.item.submittedDocuments?.spouse) {
        for (let a = 0; a < this.item.submittedDocuments?.spouse.length; a++) {
          this.getURL(this.item.submittedDocuments?.spouse[a]);
        }
      }

      if (this.item.submittedDocuments?.mother) {
        for (let a = 0; a < this.item.submittedDocuments?.mother.length; a++) {
          this.getURL(this.item.submittedDocuments.mother[a]);
        }
      }

      if (this.item.submittedDocuments?.father) {
        for (let a = 0; a < this.item.submittedDocuments?.father.length; a++) {
          this.getURL(this.item.submittedDocuments?.father[a]);

        }
      }

      if (this.item.submittedDocuments?.guardian) {
        for (let a = 0; a < this.item.submittedDocuments?.guardian.length; a++) {
          this.getURL(this.item.submittedDocuments.guardian[a]);
        }
      }

      if (this.item.submittedDocuments?.student) {
        for (let a = 0; a < this.item.submittedDocuments?.student.length; a++) {
          this.getURL(this.item.submittedDocuments.student[a]);
        }
      }

      if (this.item.submittedDocuments?.appeals) {
        for (let a = 0; a < this.item.submittedDocuments?.appeals.length; a++) {
          this.getURL(this.item.submittedDocuments.appeals[a]);
        }
      }
    }
    this.usersService.getProfile().subscribe((user: User) => {
      this.user = user;
    }, (error: HttpErrorResponse) => {
      this.blockUI.stop();
      this.gaService.exception(error.error?.message, true);
      if (error.status === 401) {
        this.activeModal.close();
        this.router.navigate(['/']);
        this.matSnackBar.open('Your session has expired', 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      } else {
        this.blockUI.stop();
        this.matSnackBar.open(error.error.message, 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }

    });
    this.requiredDocumentsService.getRequestedDocuments(this.item.id).subscribe((requestedDocuments: RequestedDocuments) => {
      this.requestedDocuments = requestedDocuments;
    }, (error: HttpErrorResponse) => {
      this.blockUI.stop();
      this.gaService.exception(error.error?.message, true);
      if (error.status === 401) {
        this.activeModal.close();
        this.router.navigate(['/']);
        this.matSnackBar.open('Your session has expired', 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      } else {
        this.blockUI.stop();
        this.matSnackBar.open(error.error.message, 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }
    });

    this.servicesService.getDetails(this.item.id).subscribe((applicationEvents: Array<ApplicationEvent>) => {
      this.applicationEvents = applicationEvents;
    }, (error: HttpErrorResponse) => {
      this.blockUI.stop();
      this.gaService.exception(error.error?.message, true);
      if (error.status === 401) {
        this.activeModal.close();
        this.router.navigate(['/']);
        this.matSnackBar.open('Your session has expired', 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      } else {
        this.blockUI.stop();
        this.matSnackBar.open(error.error.message, 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }
    });
    const that = this;
    if (this.item?.application?.dhaRelatives) {
      this.item.application.dhaRelatives.forEach((dhaRelative: DhaRelative) => {
        if (dhaRelative.relationship === 'Father') {
          that.father = dhaRelative;
          if (!that.item?.application?.father) {
            that.item.application.father = new Spouse();
          }
        } else if (dhaRelative.relationship === 'Mother') {
          that.mother = dhaRelative;
          if (!that.item?.application?.mother) {
            that.item.application.mother = new Spouse();
          }

        } else if (dhaRelative.relationship === 'Spouse') {
          that.spouse = dhaRelative;
          if (!that.item?.application?.spouse) {
            that.item.application.spouse = new Spouse();
          }
        } else if (dhaRelative.relationship === 'Guardian') {
          that.guardian = dhaRelative;
          if (!that.item?.application?.guardian) {
            that.item.application.guardian = new Spouse();
          }
        }
      });
    }
  }

   getURL(attachment: Attachment) {
    const url = this.configServices.getApi() + 'file/' + attachment.id + '/' + this.sanitiseFilename(attachment.name);
    this.http.get(url,{observe: 'response', responseType: 'blob'}).subscribe((res) => {
      const objectURL = URL.createObjectURL(res.body);
      const image = this.sanitizer.bypassSecurityTrustResourceUrl(objectURL);
      attachment.url = image;
    });
  }

  selectReason(attachment: Attachment) {

    this.attachment = attachment;
    if (attachment.selected !== true) {
      $('#reasonModal').modal('show');
    }

  }

  hasReasons(attachment: Attachment): boolean {
    let ret = false;
    attachment.reasons.forEach((att: Attachment) => {
      if (att.selected === true) {
        ret = true;
        return;
      }
    });
    return ret;
  }

  hideReason() {
    $('#reasonModal').modal('hide');
  }

  requestDocuments() {

    this.blockUI.start();
    const requestedDocuments = new RequestedDocuments();
    if (this.requestedDocuments.disabilityAnnexure && this.requestedDocuments.disabilityAnnexure.selected === true) {
      requestedDocuments.disabilityAnnexure = this.requestedDocuments.disabilityAnnexure;
    }

    if (this.requestedDocuments.spouse) {
      requestedDocuments.spouse = new Array();
      this.requestedDocuments.spouse.forEach((attachment: Attachment) => {

        attachment.reasons.forEach((att: Attachment) => {
          if (att.selected === true) {
            requestedDocuments.spouse.push(att);
          }
        });

      });
    }

    if (this.requestedDocuments.father) {
      requestedDocuments.father = new Array();
      this.requestedDocuments.father.forEach((attachment: Attachment) => {

        attachment.reasons.forEach((att: Attachment) => {
          if (att.selected === true) {
            requestedDocuments.father.push(att);
          }
        });
      });
    }

    if (this.requestedDocuments.mother) {
      requestedDocuments.mother = new Array();
      this.requestedDocuments.mother.forEach((attachment: Attachment) => {
        attachment.reasons.forEach((att: Attachment) => {
          if (att.selected === true) {
            requestedDocuments.mother.push(att);
          }
        });
      });
    }
    if (this.requestedDocuments.guardian) {
      requestedDocuments.guardian = new Array();
      this.requestedDocuments.guardian.forEach((attachment: Attachment) => {
        attachment.reasons.forEach((att: Attachment) => {
          if (att.selected === true) {
            requestedDocuments.guardian.push(att);
          }
        });
      });
    }

    if (this.requestedDocuments.student) {
      requestedDocuments.student = new Array();
      this.requestedDocuments.student.forEach((attachment: Attachment) => {
        attachment.reasons.forEach((att: Attachment) => {
          if (att.selected === true) {
            requestedDocuments.student.push(att);
          }
        });
      });
    }
    const requestDocument = new RequestDocument();
    requestDocument.caseId = this.item.id;
    requestDocument.requiredDocuments = requestedDocuments;
    this.servicesService.requestDocuments(requestDocument).subscribe(() => {

      this.matSnackBar.open('Request for application ref ' + this.item?.application.reference + ' has been sent', 'Okay', {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      this.blockUI.stop();
      this.activeModal.close();
    }, (error: HttpErrorResponse) => {
      this.blockUI.stop();
      this.gaService.exception(error.error?.message, true);
      if (error.status === 401) {
        this.activeModal.close();
        this.router.navigate(['/']);
        this.matSnackBar.open('Your session has expired', 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      } else {
        this.blockUI.stop();
        this.matSnackBar.open(error.error.message, 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }
    });
  }

  update(spouse: Spouse) {

    if (spouse.edit === false || spouse.edit === undefined) {
      spouse.edit = true;
    } else {
      spouse.edit = false;
      this.blockUI.start();
      this.servicesService.update(this.item.application).subscribe((application: Application) => {
        this.blockUI.stop();
      }, (error: HttpErrorResponse) => {
        this.gaService.exception(error.error?.message, true);
        this.blockUI.stop();
        if (error.status === 401) {
          this.activeModal.close();
          this.router.navigate(['/']);
          this.matSnackBar.open('Your session has expired', 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
      });
    }

  }

  getStatus(status: string) {

    if (status === 'NotStarted') {
      return 'Not Submitted';
    }
    return _.startCase(status);

  }

  onSubmit() {
    if (!this.ngForm.valid) {
      this.validated = true;
      $('html, body').animate({
        scrollTop: 0
      }, 1000, function () {
      });
      return;
    } else {

      if (this.status === 'Approve') {

        if (this.item.application.disabilityAnnexure && this.item.application.disabilityAnnexure.id) {
          if (this.item.application.disabilityAnnexure.verified !== true) {
            this.matSnackBar.open('Disability Annexure needs to be verified', 'Okay', {
              duration: 5000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
            return;
          }
        }
        if (this.item.application.vulnerableChildOrNonSassDeclaration && this.item.application.vulnerableChildOrNonSassDeclaration.id) {
          if (this.item.application.vulnerableChildOrNonSassDeclaration.verified !== true) {
            this.matSnackBar.open('Non Sassa Declaration needs to be verified', 'Okay', {
              duration: 5000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
            return;
          }
        }
        let verified = true;
        if (this.item.requestedDocuments) {
          if (this.item.requestedDocuments.student) {
            this.item.requestedDocuments.student.forEach((attachment: Attachment) => {
              if (attachment.verified !== true) {
                this.matSnackBar.open(attachment.caption + ' needs to be verified', 'Okay', {
                  duration: 5000,
                  horizontalPosition: 'center',
                  verticalPosition: 'top',
                });
                verified = false;
                return;
              }
            })
          }
          if (this.item.requestedDocuments.spouse) {
            this.item.requestedDocuments.spouse.forEach((attachment: Attachment) => {
              if (attachment.verified !== true) {
                this.matSnackBar.open(attachment.caption + ' needs to be verified', 'Okay', {
                  duration: 5000,
                  horizontalPosition: 'center',
                  verticalPosition: 'top',
                });
                verified = false;
                return;
              }
            })
          }

          if (this.item.requestedDocuments.father) {
            this.item.requestedDocuments.father.forEach((attachment: Attachment) => {
              if (attachment.verified !== true) {
                this.matSnackBar.open(attachment.caption + ' needs to be verified', 'Okay', {
                  duration: 5000,
                  horizontalPosition: 'center',
                  verticalPosition: 'top',
                });
                verified = false;
                return;
              }
            })
          }

          if (this.item.requestedDocuments.mother) {
            this.item.requestedDocuments.mother.forEach((attachment: Attachment) => {
              if (attachment.verified !== true) {
                this.matSnackBar.open(attachment.caption + ' needs to be verified', 'Okay', {
                  duration: 5000,
                  horizontalPosition: 'center',
                  verticalPosition: 'top',
                });
                verified = false;
                return;
              }
            })
          }

          if (this.item.requestedDocuments.guardian) {
            this.item.requestedDocuments.guardian.forEach((attachment: Attachment) => {
              if (attachment.verified !== true) {
                this.matSnackBar.open(attachment.caption + ' needs to be verified', 'Okay', {
                  duration: 5000,
                  horizontalPosition: 'center',
                  verticalPosition: 'top',
                });
                verified = false;
                return;
              }
            })
          }
        }

        if (verified !== true) {
          return;
        }
        this.blockUI.start();
        this.servicesService.approveCase(this.item.id).subscribe((item: Case) => {
          this.blockUI.stop();
          this.item = item;
          this.activeModal.close();
          this.matSnackBar.open('Application ' + this.item?.application.reference + ' has been reviewed', 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }, (error: HttpErrorResponse) => {
          this.blockUI.stop();
          this.gaService.exception(error.error?.message, true);
          if (error.status === 401) {
            this.activeModal.close();
            this.router.navigate(['/']);
            this.matSnackBar.open('Your session has expired', 'Okay', {
              duration: 5000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          } else {
            this.blockUI.stop();
            this.matSnackBar.open(error.error.message, 'Okay', {
              duration: 5000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          }
        });
      } else if (this.status === 'Reject') {
        this.blockUI.start();
        this.servicesService.rejectCaseWithReason(this.item.id, this.item.caseRejectReason.id).subscribe((item: Case) => {
          this.blockUI.stop();
          this.item = item;
          this.activeModal.close();
          this.matSnackBar.open('Application ' + this.item?.application.reference + ' has been rejected', 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }, (error: HttpErrorResponse) => {
          this.blockUI.stop();
          this.gaService.exception(error.error?.message, true);
          if (error.status === 401) {
            this.activeModal.close();
            this.router.navigate(['/']);
            this.matSnackBar.open('Your session has expired', 'Okay', {
              duration: 5000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          } else {
            this.blockUI.stop();
            this.matSnackBar.open(error.error.message, 'Okay', {
              duration: 5000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          }
        });
      } else {
        this.requestDocuments();
      }
    }
  }
  
  sanitiseFilename(filename: string): string {
    return this.filenameUtilsService.sanitiseFilename(filename);
  }

  fetchCaseDetails() {
    // Set isLoading to true to indicate loading
    this.isLoading = true;

    // Simulate an asynchronous API call using setTimeout
    setTimeout(() => {
      // After a simulated delay, perform case status check and update isLoading accordingly
      if (this.usersService.hasAnyRole(this.user,['CaseManager'])) {
        // For case manager role, set isLoading to false immediately
        this.isLoading = false;
      } else {
        // For caseworkers and case team leads, check if the case status is closed or completed
        if (this.item.status === 'Closed' || this.item.status === 'Completed') {
          // Hide the nav item for caseworkers and case team leads when the case is closed or completed
          this.isLoading = false;
        } else {
          // Show the nav item for other cases
          this.isLoading = false;
        }
      }
    }, 1000); // Assuming API call takes 1 second
  }

  shouldShowNavItem(): boolean {
    // Always show navItem for case managers
    if (this.usersService.hasAnyRole(this.user,['CaseManager'])) {
      return true;
    }

    // Don't show navItem while data is loading
    if (this.isLoading) {
      return false;
    }

    // Logic to determine if the navItem should be shown based on user role and case status
    if (this.item.status === 'Closed' || this.item.status === 'Completed') {
      return false; // Hide navItem for caseworkers and case team leads when case is closed or completed
    } else {
      return true; // Show navItem for other cases
    }
  }

  disabilityChangeRequest(application: Application) {

    const modalRef = this.modalService.open(DisabilityChangeRequestComponent, {fullscreen: true, modalDialogClass: 'animate-bottom'});
    modalRef.componentInstance.application = application;
    modalRef.closed.subscribe(()=>{
      this.search();
    });
  }


}
