import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { ConfigServices } from "src/app/core/config";
import { ServicesService } from "src/app/users/services.service";



@Injectable({
  providedIn: 'root'
})

export class CaseRejectReasonService extends ServicesService {
  constructor(public http: HttpClient, public configService: ConfigServices, public cookieService: CookieService, private router: Router) {
    super('case-reject-reasons',http,configService)
  }
}
