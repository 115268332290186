import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {CoreComponent} from '../../../core/core.component';
import {NgForm} from '@angular/forms';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Laptop} from './laptop';
import {LaptopsService} from '../laptops.service';
import {HttpErrorResponse} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Meta, Title} from '@angular/platform-browser';
import {Attachment} from '../../../core/models';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
declare var $: any;
@Component({
  selector: 'app-laptop',
  templateUrl: './laptop.component.html',
  styleUrls: ['./laptop.component.scss']
})
export class LaptopComponent extends CoreComponent<Laptop> {

  @Input() item: Laptop = new Laptop();
  validated= false;
  @ViewChild('ngForm', { static: false }) public ngForm: NgForm;
  @BlockUI() blockUI: NgBlockUI;
  constructor(protected servicesService: LaptopsService, public route: ActivatedRoute,public meta: Meta, public title: Title,
              protected gaService: GoogleAnalyticsService,

              public activeModal: NgbActiveModal,private modalService: NgbModal, private router: Router, private matSnackBar: MatSnackBar) {
    super(servicesService,route);
    this.title.setTitle('Laptop');
  }

  public saveLaptop() {

    this.blockUI.start();
    this.servicesService.save(this.item).subscribe((item: Laptop) => {
      this.blockUI.stop();
      this.activeModal.close();
      this.item = item;
    }, (error: HttpErrorResponse) => {
      this.blockUI.stop();
      this.gaService.exception(error.error?.message, true);
      if (error.status === 401) {
        this.activeModal.close();
        this.router.navigate(['/']);
        this.matSnackBar.open('Your session has expired', 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }else {
        this.blockUI.stop();
        this.matSnackBar.open(error.error.message, 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }
    });
  }

  onSubmit() {

    if (!this.ngForm.valid) {
      this.validated = true;
      $('html, body').animate({
        scrollTop: 0
      }, 1000, function() {
      });
      return;
    } else {

      if (this.item.image &&this.item.image.file && (this.item.image.file instanceof Blob)) {
        this.servicesService.upload(this.item.image.file).subscribe((image: Attachment) => {
          if (image) {
            this.item.image = image;
            this.saveLaptop();
          }
        }, (error: HttpErrorResponse) => {
          this.blockUI.stop();
          this.gaService.exception(error.error?.message, true);
          if (error.status === 401) {
            this.activeModal.close();
            this.router.navigate(['/']);
            this.matSnackBar.open('Your session has expired', 'Okay', {
              duration: 5000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          }else {
            this.blockUI.stop();
            this.matSnackBar.open(error.error.message, 'Okay', {
              duration: 5000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          }
        });
      } else {
        this.saveLaptop();
      }
    }

  }

}
