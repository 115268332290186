import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApplicationsService} from '../applications/applications.service';
import {Application} from '../applications/application/application';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {HttpErrorResponse} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NgForm} from '@angular/forms';
import {CdkStepper} from '@angular/cdk/stepper';
import {TermConditionsService} from '../admin/term-conditions/term-conditions.service';
import {Results} from '../core/results';
import {TermCondition} from '../admin/term-conditions/term-condition/term-condition';
import {Observable, ObservableInput} from 'rxjs/Observable';
import {Attachment} from '../core/models';
import {ApplicationsComponent} from '../apps/apps.component';
import {Router} from '@angular/router';
import {GoogleAnalyticsService} from 'ngx-google-analytics';



@Component({
  selector: 'app-apply',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.scss']
})
export class ApplyComponent implements OnInit {
  agree: boolean;
  public validated: boolean;
  @BlockUI() blockUI: NgBlockUI;
  success: boolean;
  @ViewChild('termsCondition', {static: false})
  public termsCondition: NgForm;
  @ViewChild('#cdkStepper')
  public cdkStepper: CdkStepper;
  item: TermCondition;

  constructor(private modalService: NgbModal,
              public applicationsService: ApplicationsService,
              public activeModal: NgbActiveModal,) {
  }

  ngOnInit(): void {
  }


  closeModal() {
    this.activeModal.close();
  }

  viewApplication() {
      const modalRef = this.modalService.open(ApplicationsComponent, {fullscreen: true, modalDialogClass: 'animate-bottom'});
      modalRef.componentInstance.name = 'World';
      modalRef.closed.subscribe(() => {
        this.ngOnInit();
      });
  }

}
