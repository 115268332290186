<app-card>
<div *ngIf="item" [innerHTML]="item.content"></div>
<form (ngSubmit)="onSubmit()" #termsCondition="ngForm" class="px-5 needs-validation" [ngClass]="validated===true?'was-validated':''">

  <div class="form-group text-left mb-5 text-start">
    <div class="checkbox checkbox-fill d-inline">
      <input type="checkbox" name="checkbox-fill-2" id="checkbox-fill-2"  required [(ngModel)]="agree" name="agree"
             required>
      <label for="checkbox-fill-2" class="cr">I have read the full Terms and Conditions.</label>

      <div class="invalid-feedback">
        Please agree to the terms and conditions
      </div>
    </div>

    <label for="checkbox-fill-2" class="cr">Read and understood T&C's.
      <span class="text-end">
              <a style="color: #000"
                 routerLink="/terms-conditions/Registration"
                 target="_blank">View T's&C's</a>

            </span></label>
    <br/>
    <label>
      <a style="color: #000" href="assets/NSFAS%20APP%20TERMS%20AND%20CONDITIONS%2021423%20Student%20App%20FINAL.docx" target="_blank">Download Terms and conditions</a>
    </label>



  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="d-grid gap-2">

        <button class="btn btn-outline-dark btn-lg mb-3" type="button" (click)="closeModal()">Decline</button>
      </div>

    </div>
    <div class="col-md-6">
      <div class="d-grid gap-2">

        <button class="btn btn-primary btn-lg mb-3" type="submit" >Accept</button>
      </div>

    </div>
  </div>
</form>
</app-card>
