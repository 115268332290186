import {Address} from '../../core/address';
import {Course} from '../../courses/course/course';
import {Attachment} from '../../core/models';
import {HigherEducationInstitution} from '../../higher-education-institutions/higher-education-institution/higher-education-institution';
import {Grade} from '../../admin-config/grades/grade/grade';
import {StudyTerm} from '../../admin-config/study-terms/study-term/study-term';
import {
  HigherEducationInstitutionType
} from '../../higher-education-institution-types/higher-education-institution-type/higher-education-institution-type';
import {RequiredDocument} from '../../admin/required-documents/required-document/required-document';
import {User} from '../../admin/users/user/user';
import {WithdrawReason} from '../../admin-config/withdraw-reasons/withdraw-reason/withdraw-reason';
import {Laptop} from '../../admin-config/laptops/laptop/laptop';
import {PlaceOfApplication} from '../../admin-config/places-of-application/place-of-application/place-of-application';
import {Province} from '../../admin-config/provinces/province/province';
import {Municipality} from '../../admin-config/municipalities/municipality/municipality';
import {School} from '../../admin-config/schools/school/school';
import {Race} from '../../admin-config/races/race/race';
import {RejectReason} from '../../admin-config/reject-reasons/reject-reason/reject-reason';
import {SubFunder} from '../../admin-config/sub-funders/sub-funder/sub-funder';
import {InstitutionRegistration} from '../../admin/contact-center/registration-details/registration-detail/institution.registration';
import { TermsAndConditions } from 'src/app/core/termsandconditions';

export class Application {
  address: Address;
  courseOne: Course;
  courseThree: Course;
  courseTwo: Course;
  currentlyStudying: boolean;
  disabilityAnnexure: Attachment;
  vulnerableChildOrNonSassDeclaration: Attachment;
  consentForm: Attachment;
  provisionalOfferLetter:Attachment;
  disabilityConfirmed: boolean;
  disabled: boolean;
  highSchoolLocation: Address;
  highSchoolName: string;
  higherEducationInstitution: HigherEducationInstitution;
  selectedHigherEducationInstitutionOne: HigherEducationInstitution;
  selectedHigherEducationInstitutionTwo: HigherEducationInstitution;
  selectedHigherEducationInstitutionThree: HigherEducationInstitution;
  higherEducationInstitutionType: HigherEducationInstitutionType;
  higherEducationInstitutionTypes: Array<HigherEducationInstitutionType>;
  highestGrade: Grade;
  laptopNeeded: boolean;
  laptop: Laptop;
  married :boolean
  institution: HigherEducationInstitution;
  mother: Spouse;
  spouse: Spouse;
  father: Spouse;
  guardian: Spouse;
  saFather : boolean;
  canEdit: boolean;
  canAppeal: boolean;
  hasAnAppeal: boolean;
  canRequestDisabilityChange: boolean;
  canRequestChangeFunder: boolean;
  saGuardian : boolean;
  saMother : boolean;
  saSpouse: boolean;
  selectedHigherEducationInstitution: HigherEducationInstitution;
  studyTerm: StudyTerm;
  studying: boolean;
  year: number;
  id: string;
  reference: string;
  createDate: string;
  status: string;
  user: User;
  caseWorker: User;
  qualityAssurer:User;
  race: Race;
  requiredDocuments: RequiredDocument[];
  withdrawReason: WithdrawReason;
  withdrawId: Attachment;
  placeOfApplication: PlaceOfApplication;
  lastHighSchoolProvince: Province;
  lastHighSchoolMunicipality: Municipality;
  lastHighSchool: School;
  rejectReason: RejectReason;
  rejectReasons: Array<RejectReason>;
  studentType: string;
  idNumber: string;
  canWithdraw: boolean;

  sassaFunded: boolean;
  source: string;
  applicationType: string;
  isImmediatelyFunded: boolean;
  dhaRelatives: Array<DhaRelative>;
  fundingCondition: string;
  metadata: MetaData;
  checked: boolean;
  institutionRegistration: InstitutionRegistration;
  registeredInstitution: HigherEducationInstitution;
  subFunder:SubFunder;
  appealableWindowsInDaysLeft: number;
  processingInfo: ProcessingInfo;
  fundingBucket: string;
  loanEligible: boolean;
  age: number;
  appealClosedReason: string;
  applied:boolean;
  termsAndConditions:TermsAndConditions;
  provisionalOffer:boolean;
  changeReason:string;
  houseHoldIncome:String;

  constructor() {

      this.address = new Address();
      this.spouse = new Spouse();
      this.father = new Spouse();
      this.mother = new Spouse();
      this.guardian = new Spouse();
      this.termsAndConditions = new TermsAndConditions();
  }
}

export class DhaRelative {
  financiallyDependant: boolean;
  employed : boolean;
  idNumber : string;
  firstName : string;
  lastName : string;
  relationship :string;
  hasSouthAfricanId: boolean;
  income : number;
  foundOnSars : boolean;
  alive : true;
  validated : true;
  supportingDocs : Array<any>;
  childrenIds : Array<string>;
}
export class Spouse {
  edit: boolean;
  income: number;
  firstName: string;
  idNumber: string;
  lastName: string;
  relationship: string;
  validated: boolean;
  alive: boolean;
  requiredDocuments: Array<Attachment> = new Array<Attachment>();
  supportingDocs: Array<Attachment> = new Array<Attachment>();

}


export class Documets {

  mother: Spouse;
  spouse: Spouse;
  father: Spouse;
  guardian: Spouse;
  requiredDocuments: Array<Attachment>;
  metadata: MetaData;
}


export class ErrorMessage {

  code: string;
  message: string;

}

export class MetaData {
  skipParentsScreen;
}

export class ProcessingInfo{
  public lastCheck: Date;
  public requiredData: Array<AppRequiredData>
}


export class AppRequiredData {
  public description: string;
  public confirmed: boolean;
  public dateConfirmed: Date;
}
