export class Results<T> {

  public content: Array<T>;
  public totalElements: number;
  public numberOfElements: number;
  public totalPages: number;
  public first: boolean;
  public last: boolean;
  public pageable: Pageable;

  constructor() {
    this.content = new Array();
    this.totalElements = 0;
    this.numberOfElements = 0;
    this.totalPages = 0;
    this.first = false;
    this.last = false;
    this.pageable = new Pageable();
  }

}
export class Pageable {
  offset: number;
  pageNumber: number;
  pageSize: number;

  constructor() {
    this.offset = 0;
    this.pageNumber =1;
    this.pageSize = 10;
  }
}
