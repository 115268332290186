import {Directive, ElementRef, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ApplicationsService} from '../applications.service';
import {ConfigService} from '../../config.service';
declare var $: any;
@Directive({
  selector: '[appApplicationStatus]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ApplicationStatusDirective),
    multi: true
  }]
})
export class ApplicationStatusDirective implements ControlValueAccessor, OnInit {

  @Input() private name: string;
  @Input() private disabled = false;
  select2: any;

  constructor(private el: ElementRef, private service: ApplicationsService, public configService: ConfigService) {
  }

  ngOnInit(): void {

    const that = this;
    this.select2 = $(this.el.nativeElement).select2({
      disabled: that.disabled,
      width: '100%',
      ajax: {
        transport: function(params, success, failure) {
          const page = 1;
          const perPage = 10;
          that.service.getStatuses().subscribe((ret: Array<string>) => {
            success(ret);
          });

        },
        dataType: 'json',
        type: 'GET',
        processResults: function(data) {
          const arr = [];
          data.forEach(function(obj) {
            arr.push({
              id: obj,
              text: obj,
            });
          });
          return {
            results: arr
          };
        }
      }
    });
    $(this.el.nativeElement).on('select2:select', function(e) {

      that.name = e.params.data.id;
      that.propagateChange(that.name);

    });

    $(this.el.nativeElement).on('select2:unselect', function(e) {
      that.name = null;
      that.propagateChange(that.name);
    });


  }

  onTouched = () => {
  };

  propagateChange = (_: any) => {
  };

  writeValue(name: string): void {

    this.name = name;
    this.propagateChange(this.name);
    const that = this;
    if (this.name !== undefined && this.name !== null) {
      const option = new Option(name, name, true, true);
      that.select2.append(option);
      that.select2.trigger('change');
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  get value(): any {
    return this.name;
  }

  set value(v: any) {
    if (v !== this.name) {
      this.name = v;
      this.propagateChange(v);
    }
  }
}
