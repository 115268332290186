import { Injectable } from '@angular/core';
import {ServicesService} from '../../users/services.service';
import {HttpClient} from '@angular/common/http';
import {ConfigServices} from '../../core/config';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GradesService extends ServicesService {

  constructor(public http: HttpClient, public configService: ConfigServices, public cookieService: CookieService, private router: Router) {
    super('grades', http, configService);
  }
}
