import {Directive, ElementRef, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Address} from "../../core/address";
import {AddressesService} from "../addresses.service";
declare var $: any;
@Directive({
  selector: '[appAddress]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AddressDirective),
    multi: true
  }]
})
export class AddressDirective implements ControlValueAccessor, OnInit {

  @Input() private address: Address;
  @Input() private disabled = false;
  @Input() private title;
  select2: any;

  constructor(private el: ElementRef, private service: AddressesService) {
  }

  ngOnInit(): void {

    const that = this;
    this.select2 = $(this.el.nativeElement).select2({
      width: '100%',
      placeholder: that.title?that.title:'Address',
      allowClear: true,
      ajax: {
        transport: function(params, success, failure) {
          that.service.search(params.data.term).subscribe((ret: Array<Address>) => {
            success(ret);
          });

        },
        dataType: 'json',
        type: 'GET',
        processResults: function(data) {
          const arr = [];
          data.forEach(function(obj) {
            arr.push({
              id: JSON.stringify(obj),
              text: obj.formattedAddress,
            });
          });
          return {
            results: arr
          };
        }
      }
    });
    $(this.el.nativeElement).on('select2:select', function(e) {

      that.address = JSON.parse(e.params.data.id);
      that.propagateChange(that.address);

    });

    $(this.el.nativeElement).on('select2:unselect', function(e) {

      that.address = null;
      that.propagateChange(that.address);
    });


  }

  onTouched = () => {
  };

  propagateChange = (_: any) => {
  };

  writeValue(address: Address ): void {

    this.address = address;
    this.propagateChange(this.address);
    const that = this;
    if (this.address !== undefined && this.address !== null) {
      const option = new Option(address.formattedAddress, JSON.stringify(address), true, true);
      that.select2.append(option);
      that.select2.trigger('change');
    }

  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  get value(): any {
    return this.address;
  }

  set value(v: any) {
    if (v !== this.address) {
      this.address = v;
      this.propagateChange(v);
    }
  }


}
