import { Component, OnInit } from '@angular/core';
import {CoreComponent} from '../core/core.component';

import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {ActivatedRoute} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Results} from '../core/results';
import {HigherEducationInstitutionsService} from './higher-education-institutions.service';
import {HigherEducationInstitutionComponent} from './higher-education-institution/higher-education-institution.component';
import {HigherEducationInstitution} from './higher-education-institution/higher-education-institution';
import {HttpErrorResponse} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {FundingListService} from '../admin-config/institution/funding-list/funding-list.service';
declare var bootbox: any;
@Component({
  selector: 'app-higher-education-institutions',
  templateUrl: './higher-education-institutions.component.html',
  styleUrls: ['./higher-education-institutions.component.scss']
})
export class HigherEducationInstitutionsComponent  extends CoreComponent<HigherEducationInstitution> {
  @BlockUI() blockUI: NgBlockUI;
  constructor(protected servicesService: HigherEducationInstitutionsService,
              protected gaService: GoogleAnalyticsService,
              private fundingListService: FundingListService,
              public route: ActivatedRoute,private modalService: NgbModal, private matSnackBar: MatSnackBar) {
    super(servicesService,route);
  }

  public search() {
    this.blockUI.start();
    this.servicesService.search(this.currentPage, this.perPage, this.order, this.orderBy, this.keyword)
      .subscribe((data: Results<HigherEducationInstitution>) => {
        this.results = data;
        this.blockUI.stop();
      }, (error: HttpErrorResponse) => {
        this.blockUI.stop();
        this.gaService.exception(error.error?.message, true);
        this.matSnackBar.open(error.error.message, 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      });
  }

  remove(item: HigherEducationInstitution){
    const that = this;
    bootbox.confirm({
      title: 'Are you sure you want to delete?',
      message: '<strong>'+item.name+'</strong>',
      callback: function(result) {
        if (result === null) {
        } else if (result === true) {

          that.servicesService.delete(item.id).subscribe(()=>{
            that.search();
          }, (error: HttpErrorResponse) => {
            that.blockUI.stop();
            that.gaService.exception(error.error?.message, true);
            that.matSnackBar.open(error.error.message, 'Okay', {
              duration: 5000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          });
        }
      }
    });
  }

  newItem() {
    this.item = new HigherEducationInstitution();
    this.open(this.item);
  }
  open(item: HigherEducationInstitution) {
    const modalRef = this.modalService.open(HigherEducationInstitutionComponent, {fullscreen:true, modalDialogClass:'animate-bottom'});
    modalRef.componentInstance.item= item;
    modalRef.componentInstance.name = 'World';
    modalRef.closed.subscribe(()=>{
      this.search();
    });
  }

  download(item: HigherEducationInstitution, year: string) {

    this.blockUI.start();
    this.fundingListService.downloadFundedList(item.code, year).subscribe((res) => {
      this.blockUI.stop();
      const url = window.URL.createObjectURL(res);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download',  item.name+'.csv');
      document.body.appendChild(link);
      link.click();
    },error => {
      this.blockUI.stop();
      this.gaService.exception(error.error?.message, true);
    });

  }


}
