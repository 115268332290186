import {Directive, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Municipality} from './municipality';
import {Results} from '../../../core/results';
import {MunicipalitiesService} from '../municipalities.service';
import {
  HigherEducationInstitutionType
} from '../../../higher-education-institution-types/higher-education-institution-type/higher-education-institution-type';
import {Province} from '../../provinces/province/province';
import {HigherEducationInstitution} from '../../../higher-education-institutions/higher-education-institution/higher-education-institution';
declare var $: any;
@Directive({
  selector: '[appMunicipality]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MunicipalityDirective),
    multi: true
  }]
})
export class MunicipalityDirective implements ControlValueAccessor, OnInit {

  @Input() private municipality: Municipality;
  @Input() private province: Province;
  @Input() private disabled = false;
  @Input() private title;
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  select2: any;

  constructor(private el: ElementRef, private service: MunicipalitiesService) {
  }

  ngOnInit(): void {
    const that = this;
    this.select2 = $(this.el.nativeElement).select2({
      width: '100%',
      placeholder: that.title?that.title:'Select Municipality',
      allowClear: true,
      ajax: {
        transport: function(params, success, failure) {
          const page = 1;
          const perPage = 10;
          if(that.province) {
            that.service.searchByType(page, perPage, 'ASC', 'name', params.data.term, that.province.id).subscribe((ret: Results<Municipality>) => {
              success(ret);
            });
          } else {
            that.service.search(page, perPage, 'ASC', 'name', params.data.term).subscribe((ret: Results<Municipality>) => {
              success(ret);
            });
          }



        },
        dataType: 'json',
        type: 'GET',
        processResults: function(data) {
          const arr = [];
          data.content.forEach(function(obj) {
            arr.push({
              id: JSON.stringify(obj),
              text: obj.name,
            });
          });
          return {
            results: arr
          };
        }
      }
    });
    $(this.el.nativeElement).on('select2:select', function(e) {

      that.municipality = JSON.parse(e.params.data.id);
      that.propagateChange(that.municipality);
      if(that.onSelect) {
        that.onSelect.emit();
      }

    });

    $(this.el.nativeElement).on('select2:unselect', function(e) {

      that.municipality = null;
      that.propagateChange(that.municipality);
      if(that.onSelect) {
        that.onSelect.emit();
      }
    });


  }

  onTouched = () => {
  };

  propagateChange = (_: any) => {
  };

  writeValue(municipality: Municipality): void {

    this.municipality = municipality;
    this.propagateChange(this.municipality);
    const that = this;
    if (this.municipality !== undefined && this.municipality !== null) {
      const option = new Option(municipality.name, JSON.stringify(municipality), true, true);
      that.select2.append(option);
      that.select2.trigger('change');
    }else {
      const option = new Option('', '', true, true);
      that.select2.append(option);
      that.select2.trigger('change');
    }

  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  get value(): any {
    return this.municipality;
  }

  set value(v: any) {
    if (v !== this.municipality) {
      this.municipality = v;
      this.propagateChange(v);
    }
  }

}
