import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Appeal } from 'src/app/admin/appeal/appeal';
import { Application } from 'src/app/applications/application/application';
import { CoreComponent } from 'src/app/core/core.component';
import { ContactCenterService } from '../../../contact-center.service';
import { AppealsService } from 'src/app/admin/appeal/appeals.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CaseManagementService } from 'src/app/admin/case-management/case-management.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApplicationsService } from 'src/app/applications/applications.service';
import { Results } from 'src/app/core/results';

@Component({
  selector: 'app-appeal-histroy',
  templateUrl: './appeal-histroy.component.html',
  styleUrls: ['./appeal-histroy.component.scss']
})
export class AppealHistroyComponent extends CoreComponent<Appeal> {
  // @Input() public application:Application;
 // @Input() appeal: Appeal;
  @Input() public application:Application;
  appeal:Appeal;
  appealId:any;
  constructor(public modalService: NgbModal, public servicesService: AppealsService,
    public activeModal: NgbActiveModal,protected gaService: GoogleAnalyticsService,public contactCenterService: ContactCenterService,
    public route: ActivatedRoute, private matSnackBar: MatSnackBar,private router: Router
  ) {  super(servicesService,route)

  }

  ngOnInit(): void {
    this.blockUI.start();
    this.servicesService.search(this.currentPage, this.perPage, 'ASC', 'firstName', this.contactCenterService.idNumber).subscribe((results: Results<Appeal>) => {
      this.results = results;
      this.appeal = this.results.content[0];
      this.blockUI.stop();
    }, (error: HttpErrorResponse) => {
      this.blockUI.stop();
      this.gaService.exception(error.error?.message, true);
      if (error.status === 401) {
        this.activeModal.close();
        this.router.navigate(['/']);
        this.matSnackBar.open('Your session has expired', 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      } else {
        this.blockUI.stop();
        this.matSnackBar.open(error.error.message, 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }
    });
  }
}


