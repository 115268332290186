import { CdkStepper } from '@angular/cdk/stepper';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ApplicationsService } from 'src/app/applications/applications.service';
import { ApplicationsComponent } from 'src/app/apps/apps.component';

@Component({
  selector: 'app-apply-submitted',
  templateUrl: './apply-submitted.component.html',
  styleUrls: ['./apply-submitted.component.scss']
})
export class ApplySubmittedComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  @Input()cdkStepper: CdkStepper;
  constructor(private modalService: NgbModal,
    public applicationsService: ApplicationsService,
    public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.activeModal.close();
  }

  viewApplication() {
      const modalRef = this.modalService.open(ApplicationsComponent, {fullscreen: true, modalDialogClass: 'animate-bottom'});
      modalRef.componentInstance.name = 'World';
      modalRef.closed.subscribe(() => {
        this.ngOnInit();
      });
  }

}
