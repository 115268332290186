import {
  HigherEducationInstitutionType
} from '../../higher-education-institution-types/higher-education-institution-type/higher-education-institution-type';

export class HigherEducationInstitution {
  id: string;
  name: string;
  institutionId:string;
  code: string;
  shortName: string;
  higherEducationInstitutionType: HigherEducationInstitutionType;
}
