import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Application} from '../../applications/application/application';
import {ConfigService} from '../../config.service';
import {ApplicationEvent} from '../../applications/application/application.event';
import {ApplicationsService} from '../../applications/applications.service';
import {Meta, Title} from '@angular/platform-browser';
import * as _ from "lodash";
import {GoogleAnalyticsService} from 'ngx-google-analytics';
@Component({
  selector: 'app-track-application',
  templateUrl: './track-application.component.html',
  styleUrls: ['./track-application.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TrackApplicationComponent implements OnInit {
  @Input() status: string;
  @Input() application: Application = new Application();
  @Output() withdrawApplication = new EventEmitter<Application>();
  @Input() applicationEvents = new Array<ApplicationEvent>();

  constructor(public activeModal: NgbActiveModal, public meta: Meta, public title: Title, public configService: ConfigService, private applicationsService: ApplicationsService,
              protected gaService: GoogleAnalyticsService) {
    this.title.setTitle('Track Application');
  }

  ngOnInit() {


  }

  close() {
    this.activeModal.close();
  }

  withdraw() {
    this.withdrawApplication.emit(this.application);
  }

  getStatus(application: Application) {
    if(application.status ==='NotStarted') {
      return '';
    } else if(application.status ==='ProvisionallyFunded') {
      return 'Provisionally funded- Funded subject to registration and availability of funds';
    }else if(application.status ==='Withdrawn') {
      return 'Cancelled';
    }else if(application.status ==='Filtering' || application.status ==='Validation') {
      return 'Application in progress';
    }else if(application.status ==='AwaitingEvaluation') {
      return 'Verification';
    }else if(application.status ==='ApplicationInProgress') {
      return 'Requesting financial and academic data';
    }
    return _.startCase(application.status);
  }

  getIndex() {
    let indx = 0;
    if (this.getStatus(this.application) === 'Not Started') {
      indx = 0;
    }  else if (this.getStatus(this.application) === 'Submitted') {
      indx = 0;
    } else if (this.getStatus(this.application) === 'Rejected') {
      indx = 6;
    } else if (this.getStatus(this.application)=== 'Awaiting Evaluation') {
      if (indx < 1) {
        indx = 1;
      }
    }else if (this.getStatus(this.application) === 'Finance Eligibility' || this.getStatus(this.application) === 'Assessing Financial Eligibility') {
      if (indx < 2) {
        indx = 2;
      }
    } else if (this.getStatus(this.application) === 'Awaiting Academic Eligibility Check') {
      if (indx < 3) {
        indx = 3;
      }

    }else if (this.getStatus(this.application) === 'Awaiting Academic Results') {
      if (indx < 4) {
        indx = 4;
      }

    } else if (this.getStatus(this.application) === 'Provisionally Funded') {
      if (indx < 5) {
        indx = 5;
      }

    }   else if (this.getStatus(this.application) === 'Awaiting Institution Data') {
      if (indx < 3) {
        indx = 3;
      }
    } else if (this.getStatus(this.application) === 'Agreement Signed') {
      if (indx < 6) {
        indx = 6;
      }
    }
    return indx;
  }

  back() {
  }


}
