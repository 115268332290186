import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {QuestionsService} from "../../questions/questions.service";
import {UserQuestionsService} from "./user-questions.service";
import {UserQuestion} from "./user-question/user-question";
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/forkJoin';
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {HttpErrorResponse} from "@angular/common/http";
import {MatSnackBar} from "@angular/material/snack-bar";
import {NgForm} from "@angular/forms";
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import { AuthOTPComponent } from 'src/app/authentication/auth-otp/auth-otp.component';
import { TvetUnivesityComponent } from 'src/app/apply/tvet-univesity/tvet-univesity.component';

@Component({
  selector: 'app-psychometric',
  templateUrl: './user-questions.component.html',
  styleUrls: ['./user-questions.component.scss']
})
export class UserQuestionsComponent implements OnInit {

  @ViewChild('userQuestions', {static: false}) public userQuestions: NgForm;
  @BlockUI() blockUI: NgBlockUI;
  validated: boolean = false;
  constructor(public activeModal: NgbActiveModal,
              private modalService: NgbModal,
              public router: Router,
              public questionsService: QuestionsService,
              public userQuestionsService: UserQuestionsService,
              protected gaService: GoogleAnalyticsService,
              private matSnackBar: MatSnackBar) {
  }

  ngOnInit() {
  }

  back() {
//this.appcomp.appeal = false;
  }

  onSubmit() {

    if (!this.userQuestions.valid) {
      this.validated = true;
    } else {
      this.blockUI.start();
      this.userQuestionsService.save(this.questionsService.userQuestions).subscribe(()=>{
        this.activeModal.close()
        this.blockUI.stop();

    // const modalRef = this.modalService.open(AuthOTPComponent, {
    //   fullscreen: true,
    //   modalDialogClass: 'animate-bottom'
    // });
    // modalRef.componentInstance.name = 'World';
   // this.router.navigate(['/readyForFunding'])
        this.router.navigate(['/dashboard/dashboard/default'])
        this.activeModal.close();
        const modalRef = this.modalService.open(TvetUnivesityComponent, {
          fullscreen: true,
          modalDialogClass: 'animate-bottom'
      });
    modalRef.componentInstance.name = 'World';
      }, (error: HttpErrorResponse) => {
        this.blockUI.stop();
        this.gaService.exception(error.error?.message, true);
        this.matSnackBar.open(error.error.message, "Okay", {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      });
    }

  }


}
