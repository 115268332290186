
<div class="row justify-content-center">
  <div class="col-md-12 text-center">

    <div class="row">
      <div class="table-responsive">
        <table class="table table-borderless text-start mt-4 table-hover">
          <thead>
          <tr>
            <th>Applicant name</th>
            <th>ID number</th>
            <th>Reference</th>
            <th>Status</th>
            <th>Disabled</th>
            <th>Spouse Verified</th>
            <th>Father Verified</th>
            <th>Mother Verified</th>
            <th>guardian Verified</th>
            <th>Case Reason</th>
            <th>SLA</th>
            <th>
              Case Worker
            </th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr style="cursor: pointer;" *ngFor="let case of cases"  >
            <td>{{case.application?.user?.firstName}} {{case.application?.user?.lastName}}</td>
            <td>{{case.application?.user?.idNumber}}</td>
            <td>{{case.application?.reference}}</td>
            <td>{{case.status}}</td>
            <td>{{case.application?.disabled ==true?'Yes':'No'}}</td>
            <td>{{case.application?.spouse?.validated ==true?'Yes':'No'}}</td>
            <td>{{case.application?.father?.validated ==true?'Yes':'No'}}</td>
            <td>{{case.application?.mother?.validated ==true?'Yes':'No'}}</td>
            <td>{{case.application?.guardian?.validated ==true?'Yes':'No'}}</td>



            <td>
           <span class="badge bg-primary" *ngFor="let caseReason of case.caseReasons">
               {{caseReason}}
            </span>
            </td>
            <td>{{case.slaHours}}</td>
            <td>
              {{case.assigned?.firstName}} {{case.assigned?.lastName}}</td>
          </tr>
          </tbody>
          <tfoot *ngIf="results.totalElements > 0">
          <td>
            <div class="dataTables_info" id="dynamic-table_info" role="status" aria-live="polite">Showing {{((currentPage-1)*perPage)+1}} to {{results.numberOfElements*currentPage}} of
              {{results.totalElements}} entries
            </div>
          </td>
          <td class="text-right" colspan="7">

            <ngb-pagination [collectionSize]="results.totalElements"
                            [pageSize]="perPage"
                            [(page)]="currentPage"
                            [maxSize]="10"
                            [rotate]="true"
                            (pageChange)="search()"></ngb-pagination>
          </td>
          </tfoot>
        </table>
      </div>
    </div>


  </div>
</div>
