<div class="row justify-content-center">
    <div class="col-md-5">

        
        <h2 class="mb-3" style="text-align: center;">NSFAS funding application</h2>
        <form (ngSubmit)="onSubmit()" #offerForm="ngForm" class="px-5 needs-validation"
            [ngClass]="validated===true?'was-validated':''">

            <p class="text-center"><b>Did you receive a provisional/firm/pre-enrolment offer letter from an institution?</b></p>

            <div class="row" style="padding-left: 50px;">
                <div class="col-md-9">
                    <div class="radio d-block mb-3">
                        <input class="form-check-input" type="radio" name="offerletter" id="offerletterNo"
                            [value]="false" [(ngModel)]="applicationsService.application.provisionalOffer">
                        <label class="cr" for="offerletterNo">No</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="radio d-block mb-3">
                        <input class="form-check-input" type="radio" name="offerletter" id="offerletterYes"
                            [value]="true" [(ngModel)]="applicationsService.application.provisionalOffer">
                        <label class="cr" for="offerletterYes">Yes</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                  <div class="d-grid gap-2">
        
                    <button class="btn btn-outline-dark btn-lg mb-3" type="button" (click)="previous()">Previous</button>
                  </div>
        
                </div>
                <div class="col-md-6">
                  <div class="d-grid gap-2">
        
                    <button class="btn btn-primary btn-lg mb-3" type="submit"
                      [disabled]="applicationsService.application.provisionalOffer===undefined">Next</button>
                  </div>
        
                </div>
              </div>
              <div class="row">
                <div class="alert alert-light text-start text-dark text-center">
                  <small id="alert">If you did not receive a provisional/firm/pre-enrolment offer, you may still proceed with an application.</small>
                </div>
              </div>
        </form>

    </div>

</div>