import {Directive, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {AppealReasonsService} from '../appeal-reasons.service';
import {AppealReason} from './appeal-reason';
import {Results} from '../../core/results';
import {Application} from '../../applications/application/application';
import {RejectReason} from '../../admin-config/reject-reasons/reject-reason/reject-reason';

declare var $: any;

@Directive({
  selector: '[appAppealReason]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AppealReasonDirective),
    multi: true
  }]
})
export class AppealReasonDirective implements ControlValueAccessor, OnInit {

  @Input() private rejectReason: RejectReason;
  @Input() private showReason = false;
  @Input() private appealReason: AppealReason;
  @Input() private application: Application;
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Input() private disabled = false;
  select2: any;

  constructor(private el: ElementRef, private service: AppealReasonsService) {
  }

  ngOnInit(): void {
    const that = this;
    this.select2 = $(this.el.nativeElement).select2({
      width: '100%',
      placeholder: 'Appeal reason',
      allowClear: true,
      ajax: {
        transport: function(params, success, failure) {
          const page = 1;
          const perPage = 100;

          if (that.application) {
            that.service.getAppealReasons(that.application).subscribe((ret: Array<AppealReason>) => {
              success(ret);
            });
          } else if(that.rejectReason){
            that.service.getAppealReasonsByRejectReason(that.rejectReason).subscribe((ret: Array<AppealReason>) => {
              success(ret);
            });
          } else {
            that.service.search(page, perPage, 'ASC', 'description', params.data.term).subscribe((ret: Results<AppealReason>) => {
              success(ret.content);
            });
          }
        },
        dataType: 'json',
        type: 'GET',
        processResults: function(data) {
          const arr = [];
          data.forEach((obj: AppealReason) => {
            arr.push({
              id: JSON.stringify(obj),
              text: that.showReason === true ? obj.rejectReason?.description+'/'+obj.description : obj.description,
            });
          });
          return {
            results: arr
          };
        }
      }
    });
    $(this.el.nativeElement).on('select2:select', function(e) {

      that.appealReason = JSON.parse(e.params.data.id);
      that.propagateChange(that.appealReason);
      if (that.onSelect) {
        that.onSelect.emit();
      }

    });

    $(this.el.nativeElement).on('select2:unselect', function(e) {

      that.appealReason = null;
      that.propagateChange(that.appealReason);
      if (that.onSelect) {
        that.onSelect.emit();
      }
    });


  }

  onTouched = () => {
  };

  propagateChange = (_: any) => {
  };

  writeValue(appealReason: AppealReason): void {

    this.appealReason = appealReason;
    this.propagateChange(this.appealReason);
    const that = this;
    if (this.appealReason !== undefined && this.appealReason !== null) {
      const option = new Option(this.showReason===true? appealReason.rejectReason?.description + '/'+appealReason.description : appealReason.description, JSON.stringify(appealReason), true, true);
      that.select2.append(option);
      that.select2.trigger('change');
    }

  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  get value(): any {
    return this.appealReason;
  }

  set value(v: any) {
    if (v !== this.appealReason) {
      this.appealReason = v;
      this.propagateChange(v);
    }
  }

}
