import { Injectable } from '@angular/core';
import {ServicesService} from '../../users/services.service';
import {HttpClient} from '@angular/common/http';
import {ConfigServices} from '../../core/config';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {Application} from '../../applications/application/application';
import {AppealReason} from '../../appeal-reasons/appeal-reason/appeal-reason';

@Injectable({
  providedIn: 'root'
})
export class AppealDocumentsService extends ServicesService {

  constructor(public http: HttpClient, public configService: ConfigServices, public cookieService: CookieService, private router: Router) {
    super('appeal-documents', http, configService);
  }

  public getByApplication(application: Application) {

    const url = this.configService.getApi() + this.type + '/by-application/'+application.id;
    return this.http.get(url,{  withCredentials: true });

  }

  public getByAppealReason(appealReason: AppealReason) {

    const url = this.configService.getApi() + this.type + '/by-appeal-reason/'+appealReason.id;
    return this.http.get(url,{  withCredentials: true });

  }
}
