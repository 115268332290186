import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {ConfigServices} from "../core/config";

@Injectable({
  providedIn: 'root'
})
export class AddressesService {

  constructor(public http: HttpClient,
              public configService: ConfigServices) {
  }

  public search(keyword: string) {

    if (keyword === undefined) {
      keyword = '';
    }
    let httpParams = new HttpParams();
    httpParams = httpParams.set('keyword', keyword);
    const url = this.configService.getApi()+ 'addresses';
    return this.http.get(url, {params: httpParams});

  }
}
