import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {CoreComponent} from '../../core/core.component';
import { AppealReason } from './appeal-reason';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {NgForm} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AppealReasonsService} from '../appeal-reasons.service';
import {ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HttpErrorResponse} from '@angular/common/http';
import {Meta, Title} from '@angular/platform-browser';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
declare var $: any;
@Component({
  selector: 'app-appeal-reason',
  templateUrl: './appeal-reason.component.html',
  styleUrls: ['./appeal-reason.component.scss']
})
export class AppealReasonComponent extends CoreComponent<AppealReason> {

  @Input() item: AppealReason = new AppealReason();
  validated= false;
  @ViewChild('ngForm', { static: false }) public ngForm: NgForm;
  @BlockUI() blockUI: NgBlockUI;
  constructor(protected servicesService: AppealReasonsService, public route: ActivatedRoute,protected gaService: GoogleAnalyticsService,
              public activeModal: NgbActiveModal, private matSnackBar: MatSnackBar,public meta: Meta, public title: Title) {
    super(servicesService,route);
    this.title.setTitle('Appeal Reason');
  }

  onSubmit() {

    if (!this.ngForm.valid) {
      this.validated = true;
      $('html, body').animate({
        scrollTop: 0
      }, 1000, function() {
      });
      return;
    } else {
      this.blockUI.start();
      this.servicesService.save(this.item).subscribe((item: AppealReason) => {
        this.blockUI.stop();
        this.activeModal.close();
        this.item = item;

      }, (error: HttpErrorResponse) => {
        this.gaService.exception(error.error?.message, true);
        this.blockUI.stop();
        this.matSnackBar.open(error.error.message, 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      });
    }

  }


}
