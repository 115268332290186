import {SafeResourceUrl} from '@angular/platform-browser';
import {AppealRequiredDocument} from '../../admin/appeal/appeal-required-document';
import {AppealDocument} from '../../admin-config/appeal-documents/appeal-document/appeal-document';

export class Attachment {
    public content: string;
    public contentType: string;
    public id: string;
    public name: string;
    public created: Date;
    public caption:	string;
    public file: File;
    public verified: boolean;
    public checked: boolean;
    public reason: string;
    public documentType: string;
    public appealSupportingDocument: AppealDocument;
    public selected: boolean;
    public userIdNumber: string;
    public url: SafeResourceUrl;
    public reasons: Array<Attachment>;
    public size: number;
    public version: number;
}


