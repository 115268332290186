import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {CoreComponent} from '../../../core/core.component';
import {Municipality} from '../../municipalities/municipality/municipality';
import {NgForm} from '@angular/forms';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HttpErrorResponse} from '@angular/common/http';
import {School} from './school';
import {SchoolsService} from '../schools.service';
import {Meta, Title} from '@angular/platform-browser';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
declare var $: any;
@Component({
  selector: 'app-school',
  templateUrl: './school.component.html',
  styleUrls: ['./school.component.scss']
})
export class SchoolComponent extends CoreComponent<School> {

  @Input() item: School = new School();
  validated= false;
  @ViewChild('ngForm', { static: false }) public ngForm: NgForm;
  @BlockUI() blockUI: NgBlockUI;
  constructor(protected servicesService: SchoolsService, public route: ActivatedRoute,private modalService: NgbModal,

              private router: Router,protected gaService: GoogleAnalyticsService,

              public activeModal: NgbActiveModal, private matSnackBar: MatSnackBar,public meta: Meta, public title: Title) {
    super(servicesService,route);
    this.title.setTitle('School');
  }

  onSubmit() {

    if (!this.ngForm.valid) {
      this.validated = true;
      $('html, body').animate({
        scrollTop: 0
      }, 1000, function() {
      });
      return;
    } else {
      this.blockUI.start();
      this.servicesService.save(this.item).subscribe((item: School) => {
        this.blockUI.stop();
        this.activeModal.close();
        this.item = item;
      }, (error: HttpErrorResponse) => {
        this.blockUI.stop();
        this.gaService.exception(error.error?.message, true);
        if (error.status === 401) {
          this.activeModal.close();
          this.router.navigate(['/']);
          this.matSnackBar.open('Your session has expired', 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }else {
          this.blockUI.stop();
          this.matSnackBar.open(error.error.message, 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
      });
    }

  }

}
