import {
  Directive,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { StudyTerm } from "./study-term";
import { StudyTermsService } from "../study-terms.service";
import { Results } from "../../../core/results";
import { HigherEducationInstitutionType } from "../../../higher-education-institution-types/higher-education-institution-type/higher-education-institution-type";
import { HigherEducationInstitution } from "../../../higher-education-institutions/higher-education-institution/higher-education-institution";

declare var $: any;

@Directive({
  selector: "[appStudyTerm]",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StudyTermDirective),
      multi: true,
    },
  ],
})
export class StudyTermDirective implements ControlValueAccessor, OnInit {
  @Input() private studyTerm: StudyTerm;
  @Input()
  private higherEducationInstitutionType: HigherEducationInstitutionType;
  @Input() private disabled = false;
  @Input() private checkOpened = false;
  @Input() private placeholder: string;
  @Input() private showAll = true;
  @Input() private showAllT2 = true;
  @Input() private showDuplicates = false;

  @Input() private student = false;
  select2: any;

  constructor(private el: ElementRef, private service: StudyTermsService) {}

  ngOnInit(): void {
    const that = this;
    this.select2 = $(this.el.nativeElement).select2({
      width: "100%",
      placeholder: this.placeholder
        ? this.placeholder
        : "Desired study term to study",
      allowClear: true,
      disabled: that.disabled,
      ajax: {
        transport: function (params, success, failure) {
          const page = 1;
          const perPage = 10;

          if (that.higherEducationInstitutionType) {
            if (that.checkOpened) {
              that.service
                .searchByTypeOpened(
                  page,
                  perPage,
                  "ASC",
                  "name",
                  params.data.term,
                  that.higherEducationInstitutionType.id
                )
                .subscribe((ret: Results<HigherEducationInstitution>) => {
                  success(ret);
                });
            } else {
              that.service
                .searchByType(
                  page,
                  perPage,
                  "ASC",
                  "name",
                  params.data.term,
                  that.higherEducationInstitutionType.id
                )
                .subscribe((ret: Results<HigherEducationInstitution>) => {
                  success(ret);
                });
            }
          } else {
            if (!that.checkOpened) {
              that.service
                .search(page, perPage, "ASC", "name", params.data.term)
                .subscribe((ret: Results<HigherEducationInstitution>) => {
                  success(ret);
                });
            } else {
              that.service
                .searchOpened(page, perPage, "ASC", "name", params.data.term)
                .subscribe((ret: Results<HigherEducationInstitution>) => {
                  success(ret);
                });
            }
          }
        },
        dataType: "json",
        type: "GET",
        processResults: function (data) {
          const arr = [];
          data.content.forEach(function (obj) {
            let show = true;
            if (!that.showAllT2) {
              if (
                obj.name.toLowerCase() === "semester 2" ||
                obj.name.toLowerCase() === "trimester 3"
              ) {
                show = false;
              }
            }
            if (show === true) {
              if (!that.showDuplicates) {
                that.addUniqueTerms(obj, arr);
              } else {
                that.addDuplicateTerms(obj, arr);
              }
            }
          });
          return {
            results: arr,
          };
        },
      },
    });
    $(this.el.nativeElement).on("select2:select", function (e) {
      that.studyTerm = JSON.parse(e.params.data.id);
      that.propagateChange(that.studyTerm);
    });

    $(this.el.nativeElement).on("select2:unselect", function (e) {
      that.studyTerm = null;
      that.propagateChange(that.studyTerm);
    });
  }

  onTouched = () => {};

  propagateChange = (_: any) => {};

  addUniqueTerms(obj: any, arr: any[]) {
    if (obj.name.toLowerCase().includes("year")) {
      if (!arr.find((x) => x.text === "Annual")) {
        arr.push({
          id: JSON.stringify(obj),
          text: "Annual",
        });
      }
    } else {
      if (!arr.find((x) => x.text === obj.name)) {
        arr.push({
          id: JSON.stringify(obj),
          text: obj.name,
        });
      }
    }
  }

  addDuplicateTerms(obj: any, arr: any[]) {
    if (obj.name.toLowerCase().includes("year")) {
      if (!arr.find((x) => x.text === "Annual")) {
        arr.push({
          id: JSON.stringify(obj),
          text: "Annual " + obj.higherEducationInstitutionType.name,
        });
      }
    } else {
      arr.push({
        id: JSON.stringify(obj),
        text: obj.name + ' ' + obj.higherEducationInstitutionType.name,
      });
    }
  }

  writeValue(studyTerm: StudyTerm): void {
    this.studyTerm = studyTerm;
    this.propagateChange(this.studyTerm);
    const that = this;
    if (this.studyTerm !== undefined && this.studyTerm !== null) {
      const option = new Option(
        studyTerm.name,
        JSON.stringify(studyTerm),
        true,
        true
      );
      that.select2.append(option);
      that.select2.trigger("change");
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  get value(): any {
    return this.studyTerm;
  }

  set value(v: any) {
    if (v !== this.studyTerm) {
      this.studyTerm = v;
      this.propagateChange(v);
    }
  }
}
