<div class="row" *ngIf="appeal">
  <div class="col-md-12">

    <div class="row justify-content-center">

      <div class="col-md-5" >
        <form (ngSubmit)="onSubmit()" #ngForm="ngForm" #userForm="ngForm" [ngClass]="validated?'was-validated':''" style="z-index: 1000;">

          <div class="mb-4 text-center">
            <img src="/assets/images/nsfas_logo.png" class="mt-3 mb-5" style="height: 177px;">
          </div>
          <h3 class="mb-4 text-center" *ngIf="appeal.status !='Revoked'">
            Re-open withdrawn appeal
          </h3>
          <div class="mb-4 text-center" *ngIf="appeal.status !='Revoked'">
            Did you mistakenly withdrew your appeal?
          </div>

          <div class="input-group mb-3" *ngIf="appeal.status !='Revoked'">
            <select class="form-select" required [(ngModel)]="appeal.appealReOpenReason" name="appealReOpenReason"
                    appAppealReopenReason></select>
            <div class="invalid-feedback">
              Reopen reason is required
            </div>
          </div>


          <h3 class="mb-4 text-center" *ngIf="appeal.status =='Revoked'">
            Appeal reopened
          </h3>
          <div class="mb-4 text-center" *ngIf="appeal.status =='Revoked'">
            Your appeal has been reopened.
          </div>
          <div class="row" *ngIf="appeal.status !='Revoked'">
            <div class="col-md-6 text-end">
              <button class="btn btn-primary btn-outline-dark" type="button" (click)="close()">No, re-open</button>
            </div>
            <div class="col-md-6 text-start">
              <button class="btn btn-primary" type="submit" >Yes re-open</button>

            </div>
          </div>
        </form>
      </div>

    </div>
    <div class="row" *ngIf="appeal.status =='Revoked'">
      <div class="col-md-12 text-center">
        <button class="btn btn-primary" type="button" (click)="dashboard()">Dashboard</button>
      </div>
    </div>


  </div>
</div>
