import {Component, Input, OnInit} from '@angular/core';
import {CoreComponent} from '../../../../../core/core.component';
import {Case} from '../../../../case-management/case';
import {Application} from '../../../../../applications/application/application';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CaseManagementService} from '../../../../case-management/case-management.service';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {ContactCenterService} from '../../../contact-center.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {types} from 'sass';
import List = types.List;
import {HttpErrorResponse} from '@angular/common/http';
import {ExceptionManagementComponent} from '../../../../exception-management/exception-management.component';

@Component({
  selector: 'app-case-history',
  templateUrl: './case-history.component.html',
  styleUrls: ['./case-history.component.scss']
})
export class CaseHistoryComponent extends CoreComponent<Case> {
  @Input() public application:Application;
  cases: Array<Case>;
  constructor(public modalService: NgbModal, public servicesService: CaseManagementService,
              public activeModal: NgbActiveModal,protected gaService: GoogleAnalyticsService,public contactCenterService: ContactCenterService,
              public route: ActivatedRoute, private matSnackBar: MatSnackBar,private router: Router
  ) {  super(servicesService,route)

  }

  ngOnInit(): void {
    this.blockUI.start();
    this.servicesService.getCases(this.application).subscribe((cases: Array<Case>) => {
      this.cases = cases;
      this.blockUI.stop();
    }, (error: HttpErrorResponse) => {
      this.blockUI.stop();
      this.gaService.exception(error.error?.message, true);
      if (error.status === 401) {
        this.activeModal.close();
        this.router.navigate(['/']);
        this.matSnackBar.open('Your session has expired', 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      } else {
        this.blockUI.stop();
        this.matSnackBar.open(error.error.message, 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }
    });
  }

  openCase(cas: Case) {

    const modalRef = this.modalService.open(ExceptionManagementComponent, {fullscreen: true, modalDialogClass: 'animate-bottom'});
    modalRef.componentInstance.name = 'World';
    modalRef.componentInstance.item = cas;
    modalRef.closed.subscribe(() => {
      this.search();
    });
  }

}
