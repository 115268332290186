<app-card>
<div class="row justify-content-center">
  <div class="col-md-5">

    <h2 class="mb-3 text-center">Update disability Status for application ref # {{item.application.reference}}</h2>

    <form (ngSubmit)="onSubmit()" #ngForm="ngForm" class="px-5 needs-validation" [ngClass]="validated===true?'was-validated':''">


      <p *ngIf="this.user?.idNumber ===item.application?.user?.idNumber">Are you disabled?</p>
      <p *ngIf="this.user?.idNumber !==item.application?.user?.idNumber">Is the applicant disabled?</p>

      <div class="row">
        <div class="col-md-9">
          <div class="radio d-block mb-3">
            <input class="form-check-input" type="radio" name="disabled" id="disabilityNo" [value]="false"
                   [(ngModel)]="item.to">
            <label class="cr" for="disabilityNo">No</label>
          </div>
        </div>
        <div class="col-md-3">
          <div class="radio d-block mb-3">
            <input class="form-check-input" type="radio" name="disabled" id="disabilityYes" [value]="true"
                   [(ngModel)]="item.to">
            <label class="cr" for="disabilityYes">Yes</label>
          </div>
        </div>
      </div>

      <div class="alert alert-light text-start text-dark" *ngIf="item.to === true">
        <small>Please note, you will have to provide proof of disability

        </small>
        <a href="/assets/NSFAS%20Disability%20Annexure%20A_2023.pdf" target="_blank">Download annexure</a>
      </div>

      <div class="row justify-content-center" *ngIf="item.to === true">
        <div class="col-md-12 text-start">
          <div class="mb-5 mt-2">
            <div class="mb-3">
              <label for="formFileSm" class="form-label">Disability Annexure</label>
              <input required [(ngModel)]="item.disabilityAnnexure" appFileUpload   name="disabilityAnnexure" accept="image/*,.pdf" class="form-control form-control-sm" id="formFileSm" type="file">
              <div class="invalid-feedback">
                Disability Annexure is required
              </div>
            </div>
            <small class="ps-3" *ngIf="!item.disabilityAnnexure">PDF or Jpeg, max 20mb</small>
            <small class="ps-3" *ngIf="item.disabilityAnnexure">{{item.disabilityAnnexure.name}}</small>
          </div>
        </div>
      </div>

      <div class="row" >
        <button type="submit" class="btn btn-primary" >Request</button>
      </div>
    </form>
  </div>
</div>
</app-card>
