import {AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';

@Component({
  selector: 'app-sign-document',
  templateUrl: './sign-document.component.html',
  styleUrls: ['./sign-document.component.scss']
})
export class SignDocumentComponent implements OnInit,AfterViewInit {

  @ViewChild('docusealForm') docusealForm: ElementRef;
  constructor(private renderer: Renderer2,private matSnackBar: MatSnackBar,private router: Router) { }

  ngOnInit(): void {

  }

  ngAfterViewInit() {

    this.renderer.listen(this.docusealForm.nativeElement, 'completed', (event) => {
      this.router.navigate(['/']);
      this.matSnackBar.open('Signatures successfully submitted', 'Okay', {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    });
  }


}
