  <div class="row justify-content-center">
    <div class="col-md-5">
      <h3 class="text-center mt-3 mb-0">Student Details Details</h3>
      <form (ngSubmit)="onSubmit()" #ngForm="ngForm" #userForm="ngForm" [ngClass]="validated?'was-validated':''" style="z-index: 1000;">


        <div class="input-group mb-3">
          <input type="text" autocomplete="off" class="form-control"  name="firstName" placeholder="First Name" [(ngModel)]="item.firstName" required>
          <div class="invalid-feedback">
            First Name is required
          </div>
        </div>

        <div class="input-group mb-3">
          <input type="text" autocomplete="off" class="form-control"  name="lastName" placeholder="Last Name" [(ngModel)]="item.lastName" required>
          <div class="invalid-feedback">
            Last Name is required
          </div>
        </div>

        <div class="input-group mb-3">
          <input type="text" autocomplete="off" class="form-control"  name="email" placeholder="Email" [(ngModel)]="item.email" required>
          <div class="invalid-feedback">
            Email is required
          </div>
        </div>

        <div class="input-group mb-3">
          <input type="text" autocomplete="off" class="form-control"  name="cellphone" placeholder="Cellphone"  [(ngModel)]="item.cellphone" required>
          <div class="invalid-feedback">
            Cellphone is required
          </div>
        </div>

        <div class="input-group mb-3">
          <input type="text" autocomplete="off" class="form-control"  name="idNumber" placeholder="Id Number" [(ngModel)]="item.idNumber">

        </div>



        <div class="d-grid gap-2">

          <button class="btn btn-primary btn-block mb-5" type="submit">Next</button>
        </div>
      </form>
    </div>
  </div>
