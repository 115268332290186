import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ConfigServices } from 'src/app/core/config';
import { ServicesService } from 'src/app/users/services.service';

@Injectable({
  providedIn: 'root'
})
export class FundingListService  extends ServicesService {

  constructor(public http: HttpClient, public configService: ConfigServices, public cookieService: CookieService, private router: Router) {
    super('institutional-portal', http, configService);
  }


  downloadAppealsContinuing(institutionId: string, year: string) {
    const url = this.configService.getApi() + this.type + '/download-funding-report/'+institutionId+'/'+year;
    return this.http.get(url, {withCredentials: true,responseType: 'blob'});
  }

  downloadFundedList(institutionId: string, year: string) {
    const url = this.configService.getApi() + this.type + '/download-funding-report/'+institutionId+'/'+year;
    return this.http.get(url, {withCredentials: true,responseType: 'blob'});
  }
}
