import { Injectable } from '@angular/core';
import {ServicesService} from './services.service';
import {HttpClient} from '@angular/common/http';
import {ConfigServices} from '../core/config';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {HigherEducationInstitution} from '../higher-education-institutions/higher-education-institution/higher-education-institution';
import {User} from '../admin/users/user/user';

@Injectable({
  providedIn: 'root'
})
export class InstitutionalUsersService extends ServicesService {

  constructor(public http: HttpClient, public configService: ConfigServices,public cookieService: CookieService, private router: Router) {
    super('institution', http, configService);
  }

  public update(user: User) {
    const url = this.configService.getApi() + this.type+'/user/'+user.id+'/'+user.institution?.id;
    return this.http.patch(url, null, {withCredentials: true});
  }
}
