import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mask'
})
export class MaskPipe implements PipeTransform {

    transform(value: string, showMask: boolean): string {
        if (!value || value.length === 0) {
          return '';
        }
    
        if (showMask) {
          const lastFour = value.slice(-4);
          const maskedValue = '*'.repeat(value.length - 4) + lastFour;
          return maskedValue;
        } else {
          return value;
        }
      }

}