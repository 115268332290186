<app-card>
  <div class="row justify-content-center">
    <div class="col-md-5">
      <h3 class="text-center mt-3 mb-0">Security Question Details</h3>
      <form (ngSubmit)="onSubmit()" #ngForm="ngForm" [ngClass]="validated?'was-validated':''" style="z-index: 1000;">

        <div class="input-group mb-3">
          <input type="text" class="form-control"  name="name" [(ngModel)]="item.question" required>
          <div class="invalid-feedback">
            Question is required
          </div>
        </div>
        <div class="d-grid gap-2">
          <button class="btn btn-primary btn-block mb-5" type="submit">Save</button>
        </div>
      </form>
    </div>
  </div>
</app-card>
