
export class StudyStep {
    public studyField1: string;
    public studyField2: string;
    public studyField3: string;
    public desiredInstitution: string;
    public streetNo: string;
    public streetName: string;
    public town: string;
    public city: string;
    public postalCode: string;
}
