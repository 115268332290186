<app-card>
    <div class="container" style="text-align: center; padding:150px;height:80vh">
        <div class="row" style="padding-top: 20px;">
            <div class="col-md-12">
                <h2><b>Dear Applicant</b> </h2>
                <h4><b>Select the reason you're re-applying from the list below</b> </h4>
            </div>
        </div>
        <div class="container">
            <div class="row custom-wrapper" style="text-align: center;">
                <div class="col-md-12">
                    <form [formGroup]="changeReasonForm" (ngSubmit)="onSubmit()">
                        <!-- Gender -->
                        <div class="group-gap" style="text-align: left; padding-left: 30%;">
                            <div class="d-block my-3">

                                <div class="custom-control custom-radio">
                                    <input id="pathway" type="radio" class="custom-control-input" value="Moving through a progression pathway"
                                        name="changeReason" formControlName="changeReason">
                                    <label class="custom-control-label" for="pathway">Moving through a progression pathway</label>
                                </div>

                                <div class="custom-control custom-radio">
                                    <input id="sector" type="radio" class="custom-control-input" value="Moving from one sector to another (University to TVET or TVET to University)"
                                        name="changeReason" formControlName="changeReason">
                                    <label class="custom-control-label" for="sector">Moving from one sector to another (University to TVET or TVET to University)</label>
                                </div>

                                <div class="custom-control custom-radio">
                                    <input id="deregisterd" type="radio" class="custom-control-input" value="De-registered"
                                        name="changeReason" formControlName="changeReason">
                                    <label class="custom-control-label" for="deregisterd">De-registered</label>
                                </div>
                                <div class="custom-control custom-radio">
                                    <input id="dropout" type="radio" class="custom-control-input" value="Drop out"
                                        name="changeReason" formControlName="changeReason">
                                    <label class="custom-control-label" for="dropout">Drop out</label>
                                </div>

                                <div class="custom-control custom-radio">
                                    <input id="Nrule" type="radio" class="custom-control-input" value="Switching qualification (within N+ rule only)"
                                        name="changeReason" formControlName="changeReason">
                                    <label class="custom-control-label" for="Nrule">Switching qualification (within N+ rule only)</label>
                                </div>

                                <div class="invalid-feedback" >
                                    <p>Please select either value</p>
                                </div>
                            </div>
                        </div>
                        <div class="container-fluid" style="padding-left: 30%;">
                            <div class="row" style="color: coral;">
                              Please note that you can only apply for the above list and you will be reassessed for Financial Eligibility.
                            </div>
                          </div>

                        <!-- Submit Button -->
                        <button type="submit" class="btn btn-primary btn-lg mb-3">Submit</button>
                    </form><!-- Form ends -->
                </div>
            </div>
        </div>
    </div>
</app-card>