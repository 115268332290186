import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CookieService} from 'ngx-cookie-service';
import {UsersService} from '../../users/users.service';
import {ApplyStep} from 'src/app/core/models/apply';
import {ApplicationsService} from '../../applications/applications.service';
import {NgForm} from '@angular/forms';
import {CdkStepper} from '@angular/cdk/stepper';
import {User} from '../../admin/users/user/user';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {HttpErrorResponse} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';
import {AssistedService} from '../../applications/assisted.service';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {Application, Documets} from '../../applications/application/application';
import {HigherEducationInstitutionTypesService} from '../../higher-education-institution-types/higher-education-institution-types.service';
import { LoanBursaryDecisionComponent } from '../loan-bursary-decision/loan-bursary-decision.component';
import { NbaComponent } from 'src/app/authentication/nba/nba.component';
import { ApplyTermsComponent } from '../apply-terms/apply-terms.component';
declare var $: any;
enum IncomeThreshold{
  BELOW_600K = 'BELOW_OR_EQUALS_600K',
  BELOW_350K = 'BELOW_OR_EQUALS_350K',
  ABOVE_600K = 'ABOVE_600K',
  ABOVE_350K = 'ABOVE_350K',
  BETWEEN_350K_AND_600K = 'ABOVE_350K_AND_BELOW_600K',
  OUT_OF_RANGE = 'OUT_OF_RANGE'
}

@Component({
  selector: 'app-apply-institution',
  templateUrl: './apply-institution.component.html',
  styleUrls: ['./apply-institution.component.scss']
})
export class ApplyInstitutionComponent implements OnInit {

  public apply: ApplyStep;
  loanThreshold = "Is your household income before deductions above R350 000 and less than or equal to R600 000?";
  exceedloanThreshold = "Is your annual household income before deductions above R600 000?";
  bursaryDisabledThreshold = "Is your annual household income less than or equal to R600 000 before deductions?";
  bursaryNonDisabledThreshold = "Is your annual household income less than or equal to R350 000 before deductions?";
  @Input() kinType: string;
  public validated: boolean;
  @ViewChild('kinForm', {static: false})
  public kinForm: NgForm;
  @BlockUI() blockUI: NgBlockUI;
  @Input() cdkStepper: CdkStepper;
  years : Array<number>;
  diffDisability:string = 'Your disability status selection is different from that in the create profile. The selection in the application will now apply or you may switch to the previous selection.';
  documets: Documets = new Documets();
  incomEnum: typeof IncomeThreshold = IncomeThreshold;
  constructor(public modalService: NgbModal, public activeModal: NgbActiveModal, protected service: UsersService,protected gaService: GoogleAnalyticsService,
              public meta: Meta, public title: Title, protected assistedService: AssistedService,
              public cookieService: CookieService,private router: Router,
              protected higherEducationInstitutionTypesService: HigherEducationInstitutionTypesService,
              public applicationsService: ApplicationsService, public usersService: UsersService, private matSnackBar: MatSnackBar) {
    this.apply = new ApplyStep();
    this.title.setTitle('Apply Institution');
    this.activeModal.close();
  }

  ngOnInit() {
    this.higherEducationInstitutionTypesService.searchOpenedYear().subscribe((years : Array<number>)=>{
      this.years = years;
    });
    // this.applicationsService.application.year = 2025;
    this.clearDocumentation();
  }

  clearDocumentation(){
    this.applicationsService.application.vulnerableChildOrNonSassDeclaration = null;
    this.applicationsService.application.provisionalOfferLetter = null;
    this.applicationsService.application.consentForm = null;
    this.applicationsService.application.disabilityAnnexure = null;
  }

  onSelect() {
    this.applicationsService.application.higherEducationInstitution = null;
  }

  disabilityYes(){
    this.applicationsService.application.houseHoldIncome=undefined;
    if(!this.applicationsService.application.user.disabled){
      this.matSnackBar.open(this.diffDisability, 'Okay', {
        duration: 10000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }


  disabilityNo(){
    this.applicationsService.application.houseHoldIncome=undefined;
    if(this.applicationsService.application.user.disabled){
      this.matSnackBar.open(this.diffDisability, 'Okay', {
        duration: 10000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }

  onProvinceSelect() {
    this.applicationsService.application.lastHighSchoolMunicipality = null;
    this.applicationsService.application.lastHighSchool = null;
  }

  onMunicipalitySelect() {
    this.applicationsService.application.lastHighSchool = null;
  }

  previous() {
    this.activeModal.dismiss();
      const modalRef = this.modalService.open(NbaComponent, {fullscreen: true, modalDialogClass: 'animate-bottom'});
      modalRef.componentInstance.name = 'World';
      modalRef.closed.subscribe(() => {
      });
  }
  closeModal(){
    this.activeModal.close();
  }

  redirectToBursary(){

 
      this.matSnackBar.open('According to your income selection, you do not meet the criteria for a '+this.applicationsService.application.fundingBucket
        +'. You will be redirected to the Loans/Bursary for a different selection.', 'Okay', {
        duration: 10000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      this.applicationsService.application.houseHoldIncome = null;
      this.applicationsService.application.fundingBucket = 'BURSARY';
      this.activeModal.dismiss();
      const modalRef = this.modalService.open(LoanBursaryDecisionComponent, {fullscreen: true, modalDialogClass: 'animate-bottom'});
      modalRef.componentInstance.name = 'World';
      modalRef.closed.subscribe(() => {
      });
  }

  criteriaNotification(){
        this.matSnackBar.open('You do not meet the criteria for any type of funding by the  National Student Financial Aid Scheme', 'Okay', {
        duration: 10000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      this.applicationsService.application.houseHoldIncome = IncomeThreshold.ABOVE_600K;
  }

  redirectToLoan(){
    this.matSnackBar.open('According to your income selection, you do not meet the criteria for a '+this.applicationsService.application.fundingBucket
      +'. You will be redirected to the Loans/Bursary for a different selection.', 'Okay', {
      duration: 10000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
    this.applicationsService.application.houseHoldIncome = null;
    this.applicationsService.application.fundingBucket = 'LOAN';
    this.activeModal.dismiss();
    const modalRef = this.modalService.open(LoanBursaryDecisionComponent, {fullscreen: true, modalDialogClass: 'animate-bottom'});
      modalRef.componentInstance.name = 'World';
      modalRef.closed.subscribe(() => {
      });
  }
  normal() {
    this.blockUI.start();
    this.applicationsService.save().subscribe((application: Application)=>{
      this.blockUI.stop();
      this.applicationsService.application = application;
      if(this.applicationsService.application.sassaFunded && !this.applicationsService.application.disabled){
        this.activeModal.dismiss();
        const modalRef = this.modalService.open(ApplyTermsComponent, {fullscreen: true, modalDialogClass: 'animate-bottom'});
        modalRef.componentInstance.name = 'World';
        modalRef.closed.subscribe(() => {
      });
      }else{
        this.cdkStepper.next();
      }
       
    }, (error: HttpErrorResponse) => {
      this.gaService.exception(error.error?.message, true);
      this.blockUI.stop();
      if(error.status === 400) {
        this.documets = JSON.parse(JSON.stringify(error.error));
        this.applicationsService.application.metadata = this.documets.metadata;
        this.cdkStepper.next();
      } else if (error.status === 401 ) {
        this.router.navigate(['/']);
        this.matSnackBar.open('Your session has expired', 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }else if (error.status !== 400) {
        this.matSnackBar.open(error.error.message, 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }

    });
  }

  dataCapture() {
    this.blockUI.start();
    this.assistedService.save().subscribe((user: User)=>{
      this.blockUI.stop();
      this.cdkStepper.next();
    }, (error: HttpErrorResponse) => {
      this.gaService.exception(error.error?.message, true);
      this.blockUI.stop();
      if(error.status === 400) {
        this.cdkStepper.next();
      }if (error.status === 401 ) {
        this.router.navigate(['/']);
        this.matSnackBar.open('Your session has expired', 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }else if (error.status !== 400) {
        this.matSnackBar.open(error.error.message, 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }

    });
  }
  onSubmit() {
    if (!this.kinForm.valid) {
      this.validated = true;
      for (let controlsKey in this.kinForm.controls) {
        if (this.kinForm.controls[controlsKey].invalid) {
        }

      }
      $('html, body').animate({
        scrollTop: 0
      }, 1000, function() {
      });
      return;
    } else {
      if(this.assistedService.idNumber){
        this.dataCapture();
      }else {
        this.normal();
      }
    }
  }

  canEdit() {
    if(this.applicationsService?.application?.reference?.indexOf('MCS')>-1) {
      return true;
    }
    return false;

  }

}


