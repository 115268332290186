import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpEventType, HttpParams} from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {ConfigServices} from "../core/config";
import { BehaviorSubject } from 'rxjs';
import { HigherEducationInstitution } from '../higher-education-institutions/higher-education-institution/higher-education-institution';
import { HigherEducationInstitutionType } from '../higher-education-institution-types/higher-education-institution-type/higher-education-institution-type';
@Injectable({
  providedIn: 'root'
})

export abstract class ServicesService  {
  @BlockUI() blockUI: NgBlockUI;
  selectedStudent: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  constructor(@Inject(String) public type: string,
              public http: HttpClient,
              public configService: ConfigServices) {
  }

  public upload(file: Blob) {

    const that = this;
    const formData = new FormData();
    formData.append('file', file);
    this.blockUI.start('Saving');
    return this.http.post(this.configService.getApi() + 'file/upload', formData, {
      reportProgress: true,
      observe: 'events',
      withCredentials: true
    }).map(event => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          this.blockUI.update((event.loaded * 100 / event.total).toFixed(1) + '%');
          break;
        case HttpEventType.Response:
          that.blockUI.stop();
          return event.body;
      }
    }, ((error: HttpErrorResponse) => {
     that.blockUI.stop();
    }));
  }


  public search(page: number, perPage: number, sortOrder: string, sortField: string, keyword: string, institutionName?: HigherEducationInstitution) {

    if (keyword === undefined) {
      keyword = '';
    }

    let httpParams = new HttpParams();
    httpParams = httpParams.set('keyword', keyword);
    const url = this.configService.getApi() + this.type + '/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField;
    return this.http.get(url, {params: httpParams,withCredentials: true});

  }

  public searchByInstitution(page: number, perPage: number, sortOrder: string, sortField: string, keyword: string, status: string) {

    if (keyword === undefined) {
      keyword = '';
    }
    let httpParams = new HttpParams();
    httpParams = httpParams.set('keyword', keyword);
    const url = this.configService.getApi() + this.type + '/institution-status/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField+'/'+status;
    return this.http.get(url, {params: httpParams,withCredentials: true});

  }

  public filterByStatus(page: number, perPage: number, sortOrder: string, sortField: string, keyword: string, ) {

    if (keyword === undefined) {
      keyword = '';
    }


    const url = this.configService.getApi() + this.type + '/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField + '/' + keyword;
    return this.http.get(url);

  }


  public filterByAppealStatus(page: number, perPage: number, sortOrder: string, sortField: string, keyword: string, ) {

    if (keyword === undefined) {
      keyword = '';
    }


    const url = this.configService.getApi() + 'admin'+  '/' + 'appeals' + '/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField + '/' + keyword;
    return this.http.get(url);

  }

  public filterByInstitutionName(page: number, perPage: number, sortOrder: string, sortField: string,  institutionId: string) {

    // if (keyword === undefined) {
    //   keyword = '';
    // }


    const url = this.configService.getApi() + this.type + '/' + 'institution' + '/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField + '/'  + institutionId;
    return this.http.get(url);

  }

  public filter1(page: number, perPage: number, sortOrder: string, sortField: string, status?: string, institutionType?: HigherEducationInstitutionType,  institutionName?: HigherEducationInstitution, idNumber?: string) {

    // if (keyword === undefined) {
    //   keyword = '';
    // }
    let httpParams = new HttpParams();

    if (institutionType) {
      httpParams = httpParams.set('institutionType', institutionType.name);
    }
    if (institutionName) {
      httpParams = httpParams.set('institutionName', institutionName.id);


    if (idNumber) {
      httpParams = httpParams.set('idNumber', idNumber);

    }
    if (status!= "undefined") {
      httpParams = httpParams.set('status', status);
    }

    const url = this.configService.getApi() + this.type + '/application-report/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField ;
    return this.http.get(url, {params: httpParams, withCredentials: true});

  }
}
  public searchOpened(page: number, perPage: number, sortOrder: string, sortField: string, keyword: string) {

    if (keyword === undefined) {
      keyword = '';
    }

    let httpParams = new HttpParams();
    httpParams = httpParams.set('keyword', keyword);
    const url = this.configService.getApi() + this.type + '/opened/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField;
    return this.http.get(url, {params: httpParams,withCredentials: true});

  }


  public getAll() {

    const url = this.configService.getApi() + this.type + '/all';
    return this.http.get(url,{  withCredentials: true });

  }

  public save(item: any) {
    const url = this.configService.getApi() + this.type;
    return this.http.put(url, item,{  withCredentials: true });
  }

  public patch(item: any) {
    const url = this.configService.getApi() + this.type;
    return this.http.patch(url, item,{  withCredentials: true });
  }



  public delete(id: string) {
    const url = this.configService.getApi() + this.type + '/' + id;
    return this.http.delete(url,{  withCredentials: true });
  }


  public get(id: string) {

    const url = this.configService.getApi() + this.type + '/' + id;
    return this.http.get(url,{  withCredentials: true });

  }


}
