import { Component, OnInit } from '@angular/core';
import {CoreComponent} from '../core/core.component';
import {FrequentlyAskedQuestion} from '../frequently-asked-questions/frequently-asked-question/frequently-asked-question';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {ActivatedRoute} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Results} from '../core/results';
import {Question} from './question/question';
import {QuestionsService} from './questions.service';
import {QuestionComponent} from './question/question.component';
import {HttpErrorResponse} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
declare var bootbox: any;
@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent  extends CoreComponent<Question> {
  @BlockUI() blockUI: NgBlockUI;
  constructor(protected servicesService: QuestionsService, public route: ActivatedRoute,
              protected gaService: GoogleAnalyticsService,
              private modalService: NgbModal, private matSnackBar: MatSnackBar) {
    super(servicesService,route);
  }

  public search() {
    this.blockUI.start();
    this.servicesService.search(this.currentPage, this.perPage, this.order, this.orderBy, this.keyword)
      .subscribe((data: Results<Question>) => {
        this.results = data;
        this.blockUI.stop();
      }, (error: HttpErrorResponse) => {
        this.blockUI.stop();
        this.gaService.exception(error.error?.message, true);
        this.matSnackBar.open(error.error.message, 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      });
  }

  remove(item: Question){
    const that = this;
    bootbox.confirm({
      title: 'Are you sure you want to delete?',
      message: '<strong>'+item.question+'</strong>',
      callback: function(result) {
        if (result === null) {
        } else if (result === true) {

          that.servicesService.delete(item.id).subscribe(()=>{
            that.search();
          }
            , (error: HttpErrorResponse) => {
              that.blockUI.stop();
              that.gaService.exception(error.error?.message, true);
              that.matSnackBar.open(error.error.message, 'Okay', {
                duration: 5000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              });
            });
        }
      }
    });
  }

  newItem() {
    this.item = new Question();
    this.open(this.item);
  }
  open(item: Question) {
    const modalRef = this.modalService.open(QuestionComponent, {fullscreen:true, modalDialogClass:'animate-bottom'});
    modalRef.componentInstance.item= item;
    modalRef.componentInstance.name = 'World';
    modalRef.closed.subscribe(()=>{
      this.search();
    });
  }


}
