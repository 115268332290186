import {Directive, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Province} from './province';
import {ProvincesService} from '../provinces.service';
import {Results} from '../../../core/results';
declare var $: any;
@Directive({
  selector: '[appProvince]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ProvinceDirective),
    multi: true
  }]
})
export class ProvinceDirective implements ControlValueAccessor, OnInit {

  @Input() private province: Province;
  @Input() private disabled = false;
  @Input() private title;
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  select2: any;

  constructor(private el: ElementRef, private service: ProvincesService) {
  }

  ngOnInit(): void {
    const that = this;
    this.select2 = $(this.el.nativeElement).select2({
      width: '100%',
      placeholder: that.title?that.title:'Select Province',
      allowClear: true,
      ajax: {
        transport: function(params, success, failure) {
          const page = 1;
          const perPage = 10;

          that.service.search(page, perPage, 'ASC', 'name', params.data.term).subscribe((ret: Results<Province>) => {
            success(ret);
          });

        },
        dataType: 'json',
        type: 'GET',
        processResults: function(data) {
          const arr = [];
          data.content.forEach(function(obj) {
            arr.push({
              id: JSON.stringify(obj),
              text: obj.name,
            });
          });
          return {
            results: arr
          };
        }
      }
    });
    $(this.el.nativeElement).on('select2:select', function(e) {

      that.province = JSON.parse(e.params.data.id);
      that.propagateChange(that.province);
      if(that.onSelect) {
        that.onSelect.emit();
      }
    });

    $(this.el.nativeElement).on('select2:unselect', function(e) {

      that.province = null;
      that.propagateChange(that.province);
      if(that.onSelect) {
        that.onSelect.emit();
      }
    });


  }

  onTouched = () => {
  };

  propagateChange = (_: any) => {
  };

  writeValue(province: Province): void {

    this.province = province;
    this.propagateChange(this.province);
    const that = this;
    if (this.province !== undefined && this.province !== null) {
      const option = new Option(province.name, JSON.stringify(province), true, true);
      that.select2.append(option);
      that.select2.trigger('change');
    }

  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  get value(): any {
    return this.province;
  }

  set value(v: any) {
    if (v !== this.province) {
      this.province = v;
      this.propagateChange(v);
    }
  }

}
