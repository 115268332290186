<div class="row">
  <div class="col-md-11 offset-1">
    <img src="/assets/images/nsfas_logo.png" class="mt-3 mb-5" style="height: 77px;">
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <div class="row justify-content-center">
      <div class="col-md-4">
        <h2 class="mb-5 text-center">Security questions</h2>
        <div class="alert alert-light text-start mb-5 text-dark">
          <small>We care about your security. Take the time and select three security questions which will be used to recover
            your account and verify your identity</small>
        </div>
        <div class="mx-5">
          <p>Select atleast three security questions</p>
          <form (ngSubmit)="onSubmit()" #securityQuestionsForm="ngForm">
            <div class="checkbox checkbox-fill mb-3" *ngFor="let question of questions; let i = index">
              <input type="checkbox" class="form-check-input" [(ngModel)]="question.selected" [name]="'question'+i" type="checkbox" value="" [id]="i">
              <label class="form-check-label" [for]="i" class="cr">
                {{question.question}}
              </label>
            </div>


            <!-- <div class="form-group text-left mb-5 text-start">
              <div class="checkbox checkbox-fill d-inline">
                <input type="checkbox" name="checkbox-fill-2" id="checkbox-fill-2" [(ngModel)]="register.agree" name="agree" required>
                <label for="checkbox-fill-2" class="cr">Read and understood T&C's. <span class="text-end">
                  <a style="color: #000;" routerLink="/terms-conditions/Registration" target="_blank">View T's&C's</a>
      </span></label>

                <div class="invalid-feedback">
                  Please agree to the terms and conditions
                </div>
              </div>
            </div> -->



            <div class="d-grid gap-2">
              <button class="btn btn-primary btn-block mb-5" type="submit">Next</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
