import {HttpErrorResponse} from '@angular/common/http';
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CookieService} from 'ngx-cookie-service';
import {UsersService} from '../../users/users.service';
import {Kin} from '../../core/models/kin';
import {ApplicationsService} from '../../applications/applications.service';
import {CdkStepper} from '@angular/cdk/stepper';
import {NgForm} from '@angular/forms';
import {User} from '../../admin/users/user/user';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Documets, ErrorMessage, Spouse} from '../../applications/application/application';
import {Router} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';
import {AssistedService} from '../../applications/assisted.service';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
declare var $: any;
@Component({
  selector: 'app-apply-details',
  templateUrl: './apply-details.component.html',
  styleUrls: ['./apply-details.component.scss']
})
export class ApplyDetailsComponent implements OnInit {
  public kin: Kin;
  @Input() kinType: string;
  public validated: boolean;
  @ViewChild('kinForm', {static: false})
  public kinForm: NgForm;
  @Input() cdkStepper: CdkStepper;
  @BlockUI() blockUI: NgBlockUI;
  documets: Documets = new Documets();
  errorMessage: ErrorMessage = new ErrorMessage();
  constructor(public activeModal: NgbActiveModal, protected service: UsersService, public cookieService: CookieService,
              public meta: Meta, public title: Title, protected assistedService: AssistedService,protected gaService: GoogleAnalyticsService,
              public applicationsService: ApplicationsService,private router: Router, private matSnackBar: MatSnackBar) {
    this.kin = new Kin();
    this.kin.married = false;
    this.title.setTitle('Apply Details');
  }

  ngOnInit() {
    if(!this.applicationsService.application.spouse) {
      this.applicationsService.application.spouse = new Spouse();
    }
    if(!this.applicationsService.application.mother) {
      this.applicationsService.application.mother = new Spouse();
    }
    if(!this.applicationsService.application.father) {
      this.applicationsService.application.father = new Spouse();
    }
    if(!this.applicationsService.application.guardian) {
      this.applicationsService.application.guardian = new Spouse();
    }
    if(this.applicationsService?.application?.spouse?.idNumber?.length> -1) {
      this.kin.married = true;
    }
    if(this.applicationsService?.application?.mother?.idNumber?.length> -1) {
      this.kin.motherDetails = true;
    }
    if(this.applicationsService?.application?.father?.idNumber?.length> -1) {
      this.kin.fatherDetails = true;
    }
    if(this.applicationsService?.application?.guardian?.idNumber?.length> -1) {
      this.kin.guardianDetails = true;
    }
  }
  clearMother() {
    this.applicationsService.application.saMother=false;
    this.applicationsService.application.mother = new Spouse();
  }
  clearFather() {
    this.applicationsService.application.saFather=false;
    this.applicationsService.application.father = new Spouse();
  }

  clearGuardian() {
    this.applicationsService.application.saGuardian=false;
    this.applicationsService.application.guardian = new Spouse();
  }
  clear() {
    this.kin.fatherDetails=undefined;
    this.applicationsService.application.saFather=undefined;
    this.applicationsService.application.father = new Spouse();
    this.kin.motherDetails=undefined;
    this.applicationsService.application.saMother=undefined;
    this.applicationsService.application.mother = new Spouse();
    this.kin.guardianDetails=undefined;
    this.applicationsService.application.saGuardian=undefined;
    this.applicationsService.application.guardian = new Spouse();
  }

  previous() {
    this.cdkStepper.previous();
  }

  dataCapture() {

    this.blockUI.start();
    this.assistedService.save().subscribe((user: User)=>{
      this.blockUI.stop();
      this.assistedService.validate().subscribe(()=>{
        this.blockUI.stop();
        this.cdkStepper.selectedIndex  =6;
      }, (err: HttpErrorResponse) => {

        this.blockUI.stop();
        this.gaService.exception(err.error.message, true);
        if(err.status === 400) {
          this.documets = JSON.parse(JSON.stringify(err.error));
          this.errorMessage = JSON.parse(JSON.stringify(err.error));
          if(this.errorMessage.code || this.errorMessage.message) {
            this.matSnackBar.open(this.errorMessage.message, 'Okay', {
              duration: 5000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          }else {

            this.cdkStepper.next();
            if(this.documets.mother) {
              this.applicationsService.application.mother = this.documets.mother;
            }
            if(this.documets.father) {
              this.applicationsService.application.father = this.documets.father;
            }
            if(this.documets.guardian) {
              this.applicationsService.application.guardian = this.documets.guardian;
            }
            if(this.documets.spouse) {
              this.applicationsService.application.spouse = this.documets.spouse;
            }
          }

        }else if (err.status === 401 ) {
          this.router.navigate(['/']);
          this.matSnackBar.open('Your session has expired', 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }else {
          this.matSnackBar.open(err.error.message, 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
      });
    }, (error: HttpErrorResponse) => {
      this.blockUI.stop();
      this.gaService.exception(error.error?.message, true);
      if(error.status === 400) {
        this.documets = JSON.parse(JSON.stringify(error.error));
        this.errorMessage = JSON.parse(JSON.stringify(error.error));
        if(this.errorMessage.code || this.errorMessage.message) {
          this.matSnackBar.open(this.errorMessage.message, 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }else {
          this.cdkStepper.next();
          if(this.documets.mother) {
            this.applicationsService.application.mother = this.documets.mother;
          }
          if(this.documets.father) {
            this.applicationsService.application.father = this.documets.father;
          }
          if(this.documets.guardian) {
            this.applicationsService.application.guardian = this.documets.guardian;
          }
          if(this.documets.spouse) {
            this.applicationsService.application.spouse = this.documets.spouse;
          }
        }

      }
      else if (error.status === 401 ) {
        this.router.navigate(['/']);
        this.matSnackBar.open('Your session has expired', 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }else if (error.status !== 400) {
        this.matSnackBar.open(error.error.message, 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }
    });
  }
  normal() {
    this.blockUI.start();
    this.applicationsService.save().subscribe((user: User)=>{
      this.blockUI.stop();
      this.applicationsService.validate().subscribe(()=>{
        this.blockUI.stop();
        this.cdkStepper.selectedIndex  =4;
      }, (err: HttpErrorResponse) => {

        this.blockUI.stop();
        this.gaService.exception(err.error.message, true);
        if(err.status === 400) {
          this.documets = JSON.parse(JSON.stringify(err.error));
          this.errorMessage = JSON.parse(JSON.stringify(err.error));
          if(this.errorMessage.code || this.errorMessage.message) {
            this.matSnackBar.open(this.errorMessage.message, 'Okay', {
              duration: 5000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          }else {

            this.cdkStepper.next();
            if(this.documets.mother) {
              this.applicationsService.application.mother = this.documets.mother;
            }
            if(this.documets.father) {
              this.applicationsService.application.father = this.documets.father;
            }
            if(this.documets.guardian) {
              this.applicationsService.application.guardian = this.documets.guardian;
            }
            if(this.documets.spouse) {
              this.applicationsService.application.spouse = this.documets.spouse;
            }
          }

        }else if (err.status === 401 ) {
          this.router.navigate(['/']);
          this.matSnackBar.open('Your session has expired', 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }else {
          this.matSnackBar.open(err.error.message, 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
      });

    }, (error: HttpErrorResponse) => {
      this.blockUI.stop();
      this.gaService.exception(error.error?.message, true);
      if(error.status === 400) {
        this.documets = JSON.parse(JSON.stringify(error.error));
        this.errorMessage = JSON.parse(JSON.stringify(error.error));
        if(this.errorMessage.code || this.errorMessage.message) {
          this.matSnackBar.open(this.errorMessage.message, 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }else {
          this.blockUI.start();
          this.applicationsService.validate().subscribe(()=>{
            this.blockUI.stop();
            this.cdkStepper.next();
          }, (err: HttpErrorResponse) => {

            this.blockUI.stop();
            this.gaService.exception(err.error.message, true);
            if(err.status === 400) {
              this.documets = JSON.parse(JSON.stringify(err.error));
              this.errorMessage = JSON.parse(JSON.stringify(err.error));
              if(this.errorMessage.code || this.errorMessage.message) {
                this.matSnackBar.open(this.errorMessage.message, 'Okay', {
                  duration: 5000,
                  horizontalPosition: 'center',
                  verticalPosition: 'top',
                });
              }else {

                this.cdkStepper.next();
                if(this.documets.mother) {
                  this.applicationsService.application.mother = this.documets.mother;
                }
                if(this.documets.father) {
                  this.applicationsService.application.father = this.documets.father;
                }
                if(this.documets.guardian) {
                  this.applicationsService.application.guardian = this.documets.guardian;
                }
                if(this.documets.spouse) {
                  this.applicationsService.application.spouse = this.documets.spouse;
                }
              }

            }else if (err.status === 401 ) {
              this.router.navigate(['/']);
              this.matSnackBar.open('Your session has expired', 'Okay', {
                duration: 5000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              });
            }else {
              this.matSnackBar.open(err.error.message, 'Okay', {
                duration: 5000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              });
            }
          });


        }

      }
      else if (error.status === 401 ) {
        this.router.navigate(['/']);
        this.matSnackBar.open('Your session has expired', 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }else if (error.status !== 400) {
        this.matSnackBar.open(error.error.message, 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }
    });
  }
  onSubmit() {
    if (!this.kinForm.valid) {
      this.validated = true;
      $('html, body').animate({
        scrollTop: 0
      }, 1000, function() {
      });
      return;
    } else {

      if(this.applicationsService.application.disabled === undefined) {
        this.matSnackBar.open('Please indicate your disability status', 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
        return;
      }
      if(this.applicationsService.application.married === undefined) {
        this.matSnackBar.open('Please indicate your marriage status', 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
        return;
      }

      if(this.applicationsService.application.married===false &&( this.kin.guardianDetails===undefined || this.kin.motherDetails === undefined || this.kin.fatherDetails === undefined)) {
        this.matSnackBar.open('Please indicate if can provide mother, father or guardian details', 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
        return;
      }
      if(this.kin.guardianDetails !== undefined &&  this.applicationsService.application.saGuardian=== undefined) {
        this.matSnackBar.open('Please indicate if your guardian has a South African ID', 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
        return;
      }
      if(this.kin.fatherDetails !== undefined &&  this.applicationsService.application.saFather=== undefined) {
        this.matSnackBar.open('Please indicate if your father has a South African ID', 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
        return;
      }

      if(this.kin.motherDetails !== undefined &&  this.applicationsService.application.saMother=== undefined) {
        this.matSnackBar.open('Please indicate if your mother has a South African ID', 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
        return;
      }

      if(this.assistedService.idNumber) {
        this.dataCapture();
      }else {
        this.normal();
      }


    }
  }

}
