import { Component, OnInit } from '@angular/core';
import {CoreComponent} from '../../core/core.component';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Results} from '../../core/results';
import {Laptop} from './laptop/laptop';
import {LaptopsService} from './laptops.service';
import {LaptopComponent} from './laptop/laptop.component';
import {ConfigServices} from '../../core/config';
import {HttpErrorResponse} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Meta, Title} from '@angular/platform-browser';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
declare var bootbox: any;
@Component({
  selector: 'app-laptops',
  templateUrl: './laptops.component.html',
  styleUrls: ['./laptops.component.scss']
})
export class LaptopsComponent extends CoreComponent<Laptop> {
  @BlockUI() blockUI: NgBlockUI;
  constructor(protected servicesService: LaptopsService, public route: ActivatedRoute,public meta: Meta, public title: Title,

              public activeModal: NgbActiveModal,protected gaService: GoogleAnalyticsService,
             public configServices: ConfigServices,private modalService: NgbModal, private router: Router, private matSnackBar: MatSnackBar ) {
    super(servicesService,route);
    this.title.setTitle('Laptops');
  }

  public search() {
    this.blockUI.start();
    this.servicesService.search(this.currentPage, this.perPage, this.order, this.orderBy, this.keyword)
      .subscribe((data: Results<Laptop>) => {
        this.results = data;
        this.blockUI.stop();
      }, (error: HttpErrorResponse) => {
        this.blockUI.stop();
        this.gaService.exception(error.error?.message, true);
        if (error.status === 401) {
          this.activeModal.close();
          this.router.navigate(['/']);
          this.matSnackBar.open('Your session has expired', 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }else {
          this.blockUI.stop();
          this.matSnackBar.open(error.error.message, 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
      });
  }

  newItem() {
    this.item = new Laptop();
    this.open(this.item);
  }
  open(item: Laptop) {
    const modalRef = this.modalService.open(LaptopComponent, {fullscreen:true, modalDialogClass:'animate-bottom'});
    modalRef.componentInstance.item= item;
    modalRef.componentInstance.name = 'World';
    modalRef.closed.subscribe(()=>{
      this.search();
    });
  }

  remove(item: Laptop){
    const that = this;
    bootbox.confirm({
      title: 'Are you sure you want to delete?',
      message: '<strong>'+item.brand+'</strong>',
      callback: function(result) {
        if (result === null) {
        } else if (result === true) {

          that.servicesService.delete(item.id).subscribe(()=>{
            that.search();
          }, error => {
            this.gaService.exception(error.error?.message, true);
          });
        }
      }
    });
  }
}
