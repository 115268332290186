import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private api = '/api/';



  constructor() {

    if (location.href.indexOf('4200') > 0) {
      this.api = 'http://localhost:8080/api/';
    }
  }

  public getApi(): string {
    return this.api;
  }

}
