
export class ApplyStep {
    public studying = 'Yes';
    public academicLevel: string;
    public studyLocation: string;
    public institutionName: string;
    public academicYear: string;
    public academicTerm: string;
    public race: string;
    public location: string;
    public locationSchool: string;
    public schoolName: string;
}
