import { CdkStepper } from '@angular/cdk/stepper';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Application, Documets, Spouse } from 'src/app/applications/application/application';
import { ApplicationsService } from 'src/app/applications/applications.service';

@Component({
  selector: 'app-offer-letter',
  templateUrl: './offer-letter.component.html',
  styleUrls: ['./offer-letter.component.scss']
})
export class OfferLetterComponent implements OnInit {

  @ViewChild('offerForm', {static: false})
  public offerForm: NgForm;
  @Input() cdkStepper: CdkStepper;
  public validated:boolean;
  @BlockUI() blockUI: NgBlockUI;
  documets: Documets = new Documets();

  constructor(protected gaService: GoogleAnalyticsService, public title: Title, private router: Router,
     public applicationsService: ApplicationsService, private matSnackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  onSubmit() {

    if(!this.offerForm.valid){
      return;
    }else{
      this.blockUI.start();
    this.applicationsService.save().subscribe((application: Application)=>{
      this.blockUI.stop();
      this.applicationsService.application = application;
       this.cdkStepper.next();
    }, (error: HttpErrorResponse) => {
      this.gaService.exception(error.error?.message, true);
      this.blockUI.stop();
      if(error.status === 400) {
        this.documets = JSON.parse(JSON.stringify(error.error));
        this.applicationsService.application.metadata = this.documets.metadata;
        this.applicationsService.updateApplicationService(this.documets);
        this.cdkStepper.next();
      } else if (error.status === 401 ) {
        this.router.navigate(['/']);
        this.matSnackBar.open('Your session has expired', 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }else if (error.status !== 400) {
        this.matSnackBar.open(error.error.message, 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }

    });
    }

  }
  previous() {
    this.applicationsService.application.mother = new Spouse();
    this.applicationsService.application.father = new Spouse();
    this.applicationsService.application.guardian = new Spouse();
    this.applicationsService.application.spouse = new Spouse();
    // this.applicationsService.application.vulnerableChildOrNonSassDeclaration = null;
    // this.applicationsService.application.disabilityAnnexure = null;
    // this.applicationsService.application.provisionalOfferLetter = null;
    this.cdkStepper.previous();
  }

}
