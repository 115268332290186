<div class="row justify-content-center">
  <div class="col-md-9 text-center">
    <form class="d-inline-block">
      <input type="text" autocomplete="off"class="form-control" name="keyword" [(ngModel)]="keyword" (ngModelChange)="search()" placeholder="Search">
    </form>
  </div>
  <div class="col-md-3">
    <button type="button" (click)="newItem()"  class="btn btn-primary btn-block">Add</button>
  </div>

</div>
<div class="row justify-content-center">
  <div class="text-center">
    <div class="table-responsive">
    <table class="table table-borderless text-start mt-4 table-hover">
      <thead>
        <tr>
          <th>Created Date</th>
          <th>ID Number</th>
          <th>First Name</th>

          <th>Last Name</th>
          <th>Email</th>
          <th>Cellphone</th>
          <th>Role</th>
          <th *ngIf="role=='InstitutionAdministrator'">
            Institution
          </th>
          <th>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let template of results.content">

          <td (click)="open(template)">{{template.createDate| date: 'medium'}}</td>
          <td (click)="open(template)">{{template.idNumber}}</td>
          <td (click)="open(template)">{{template.firstName}}</td>
          <td (click)="open(template)">{{template.lastName}}</td>
          <td (click)="open(template)">{{template.email}}</td>
          <td (click)="open(template)">{{template.cellphone}}</td>
          <td (click)="open(template)">{{template.role}}</td>
          <td *ngIf="role=='InstitutionAdministrator'">
            <select [(ngModel)]="template.institution" appHigherEducationInstitution (onSelect)="assignInstitution(template)"></select>
          </td>
          <td (click)="open(template)">{{template.accountStatus}}</td>
          <td><i (click)="remove(template)" class="fa fa-remove"></i></td>
        </tr>
      </tbody>
      <tfoot *ngIf="results.totalElements > 0">
        <td>
          <div class="dataTables_info" id="dynamic-table_info" role="status" aria-live="polite">
            Showing {{((currentPage - 1) * perPage) + 1}} to {{results.numberOfElements * currentPage}} of
            {{results.totalElements}} entries
          </div>
        </td>
        <td class="text-right">

          <ngb-pagination [collectionSize]="results.totalElements"
                          [pageSize]="perPage"
                          [(page)]="currentPage"
                          [maxSize]="10"
                          [rotate]="true"
                          (pageChange)="search()"></ngb-pagination>
        </td>
        </tfoot>
      </table>
    </div>
  </div>
</div>
