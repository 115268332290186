import { Component, OnInit } from '@angular/core';
import {CoreComponent} from '../core/core.component';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {ActivatedRoute} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Results} from '../core/results';
import {FrequentlyAskedQuestionAnswerComponent} from './frequently-asked-question-answer/frequently-asked-question-answer.component';
import { FrequentlyAskedQuestionAnswer } from './frequently-asked-question-answer/frequently-asked-question-answer';
import {FrequentlyAskedQuestionAnswersService} from './frequently-asked-question-answers.service';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
declare var bootbox: any;
@Component({
  selector: 'app-frequently-asked-question-answers',
  templateUrl: './frequently-asked-question-answers.component.html',
  styleUrls: ['./frequently-asked-question-answers.component.scss']
})
export class FrequentlyAskedQuestionAnswersComponent extends CoreComponent<FrequentlyAskedQuestionAnswer> {
  @BlockUI() blockUI: NgBlockUI;
  constructor(protected servicesService: FrequentlyAskedQuestionAnswersService,
              protected gaService: GoogleAnalyticsService,
              public route: ActivatedRoute,private modalService: NgbModal) {
    super(servicesService,route);
  }

  public search() {
    this.blockUI.start();
    this.servicesService.search(this.currentPage, this.perPage, this.order, this.orderBy, this.keyword)
      .subscribe((data: Results<FrequentlyAskedQuestionAnswer>) => {
        this.results = data;
        this.blockUI.stop();
      },error => {
        this.gaService.exception(error.error?.message, true);
      });
  }
  remove(item: FrequentlyAskedQuestionAnswer){
    const that = this;
    bootbox.confirm({
      title: 'Are you sure you want to delete?',
      message: '<strong>'+item.answer+'</strong>',
      callback: function(result) {
        if (result === null) {
        } else if (result === true) {

          that.servicesService.delete(item.id).subscribe(()=>{
            that.search();
          },error => {
            that.gaService.exception(error.error?.message, true);
          });
        }
      }
    });
  }

  newItem() {
    this.item = new FrequentlyAskedQuestionAnswer();
    this.open(this.item);
  }
  open(item: FrequentlyAskedQuestionAnswer) {
    const modalRef = this.modalService.open(FrequentlyAskedQuestionAnswerComponent, {fullscreen:true, modalDialogClass:'animate-bottom'});
    modalRef.componentInstance.item= item;
    modalRef.closed.subscribe(()=>{
      this.search();
    });
    modalRef.componentInstance.name = 'World';
  }

}
