<div class="row">
  <div class="col-md-12">

    <div class="row justify-content-center">

      <div class="col-md-5">
        <form (ngSubmit)="onSubmit()" #ngForm="ngForm" #userForm="ngForm" [ngClass]="validated?'was-validated':''" style="z-index: 1000;">

        <div class="mb-4 text-center">
          <img src="/assets/images/nsfas_logo.png" class="mt-3 mb-5" style="height: 177px;">
        </div>
        <h3 class="mb-4 text-center" *ngIf="application.status !='Withdrawn'">
          Cancel application, are you sure?
        </h3>
        <div class="mb-4 text-center" *ngIf="application.status !='Withdrawn'">
          Please note, a cancelled application cannot be reversed and all related information to the application
          will be removed.
        </div>

        <div class="input-group mb-3" *ngIf="application.status !='Withdrawn'">
          <select class="form-select" required [(ngModel)]="application.withdrawReason" name="withdrawReason"
                  appWithdrawReason></select>
          <div class="invalid-feedback">
            Cancel reason is required
          </div>
        </div>

        <div class="input-group mb-3" *ngIf="application.status !='Withdrawn'" >
          <div class="checkbox checkbox-fill">
            <input
              type="checkbox"
              name="checkbox-fill-3"
              id="checkbox-fill-3"
              name="agree"
              [(ngModel)]="application.checked"
              required
            />
            <label for="checkbox-fill-3" class="cr">
            I understand the cancelled application cannot be reversed and all related Information to the application will be removed.</label>
            <div class="invalid-feedback">
              Please agree to the terms and conditions
            </div>

          </div>
        </div>

        <div class="mb-5 mt-2" *ngIf="application.status !='Withdrawn'">
          <div class="mb-3">
            <label for="formFileSm" class="form-label">Upload certified copy of ID to verify cancellation</label>
            <input required [(ngModel)]="application.withdrawId" appFileUpload name="withdrawId" accept="image/*,.pdf"
                   class="form-control form-control-sm" id="formFileSm" type="file">
            <div class="invalid-feedback">
              certified copy of ID is required
            </div>
          </div>
          <small class="ps-3" *ngIf="!application.withdrawId">PDF or Jpeg, max 20mb</small>
          <small class="ps-3" *ngIf="application.withdrawId">{{application.withdrawId.name}}</small>
        </div>

        <h3 class="mb-4 text-center" *ngIf="application.status =='Withdrawn'">
          Application cancelled
        </h3>
        <div class="mb-4 text-center" *ngIf="application.status =='Withdrawn'">
          Your application has been cancelled for funding.
        </div>
        <div class="row" *ngIf="application.status !='Withdrawn'">
          <div class="col-md-6 text-end">
            <button class="btn btn-primary btn-outline-dark" type="button" (click)="close()">No, don't cancel</button>
          </div>
          <div class="col-md-6 text-start">
            <button class="btn btn-primary" type="submit" >Yes cancel</button>

          </div>
        </div>
        </form>
      </div>

    </div>
    <div class="row" *ngIf="application.status =='Withdrawn'">
      <div class="col-md-12 text-center">
        <button class="btn btn-primary" type="button" (click)="dashboard()">Dashboard</button>
      </div>
    </div>


  </div>
</div>
