<div class="row justify-content-center text-center">
  <div class="col-md-5">
    <h2 class="mb-5">Apply for laptop</h2>
    <h4 class="mb-0">Laptop selection</h4>
    <p class="mb-5">Select between multiple laptops to assist your education</p>
    <div class="text-center">
      <form (ngSubmit)="onSubmit()" #kinForm="ngForm" class="needs-validation" [ngClass]="validated===true?'was-validated':''">
        <div class="alert alert-light mb-3 text-center text-dark">
          <small>Please note, the laptop request will be deducted from your Learning material allowance</small>
        </div>
      <p class="mb-1">Do you need a laptop with your funding?</p>
      <div class="form-check form-check-inline text-end">
        <div class="radio d-block">
          <input class="form-check-input" type="radio" name="laptopNeeded" id="laptopNeededNo" [value]="false"
                 [(ngModel)]="applicationsService.application.laptopNeeded">
          <label class="cr" for="laptopNeededNo">No</label>
        </div>
      </div>
        <div class="form-check form-check-inline">
          <div class="radio d-block mb-3">
            <input class="form-check-input" type="radio" name="laptopNeeded" id="laptopNeededYes" [value]="true"
                   [(ngModel)]="applicationsService.application.laptopNeeded">
            <label class="cr" for="laptopNeededYes">Yes</label>
          </div>
        </div>
      <div class="form-group mt-2 mb-3" *ngIf="applicationsService.application.laptopNeeded === true">
        <select class="form-select"  required [(ngModel)]="applicationsService.application.laptop" name="laptop" appLaptop></select>
      </div>
      <div class="mb-5" *ngIf="applicationsService.application.laptopNeeded === true && applicationsService.application.laptop &&applicationsService.application.laptop.image && applicationsService.application.laptop.image.id">
        <img [src]="configServices.getApi()+'file/'+applicationsService.application.laptop.image.id" style="width: 100%" class="p-5">
      </div>
        <div class="row">
          <div class="col-md-6">
            <div class="d-grid gap-2">

              <button class="btn btn-outline-dark btn-lg mb-3" type="button" (click)="previous()">Previous</button>
            </div>

          </div>
          <div class="col-md-6">
            <div class="d-grid gap-2">

              <button class="btn btn-primary btn-lg mb-3" type="submit" >Next</button>
            </div>

          </div>
        </div>
      </form>


    </div>


  </div>
</div>



