import { Component, OnInit } from '@angular/core';
import {CoreComponent} from '../../core/core.component';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Results} from '../../core/results';
import { RacesService } from './races.service';
import {Race} from './race/race';
import {RaceComponent} from './race/race.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HttpErrorResponse} from '@angular/common/http';
import {Meta, Title} from '@angular/platform-browser';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
declare var bootbox: any;
@Component({
  selector: 'app-races',
  templateUrl: './races.component.html',
  styleUrls: ['./races.component.scss']
})
export class RacesComponent extends CoreComponent<Race> {
  @BlockUI() blockUI: NgBlockUI;
  constructor(protected servicesService: RacesService, public route: ActivatedRoute,public meta: Meta, public title: Title,
              public activeModal: NgbActiveModal,protected gaService: GoogleAnalyticsService,
              private modalService: NgbModal, private matSnackBar: MatSnackBar,private router: Router) {
    super(servicesService,route);
    this.title.setTitle('Races');
  }

  public search() {
    this.blockUI.start();
    this.servicesService.search(this.currentPage, this.perPage, this.order, this.orderBy, this.keyword)
      .subscribe((data: Results<Race>) => {
        this.results = data;
        this.blockUI.stop();
      }, (error: HttpErrorResponse) => {
        this.blockUI.stop();
        this.gaService.exception(error.error?.message, true);
        if (error.status === 401) {
          this.activeModal.close();
          this.router.navigate(['/']);
          this.matSnackBar.open('Your session has expired', 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }else {
          this.blockUI.stop();
          this.matSnackBar.open(error.error.message, 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
      });
  }

  remove(item: Race){
    const that = this;
    bootbox.confirm({
      title: 'Are you sure you want to delete?',
      message: '<strong>'+item.name+'</strong>',
      callback: function(result) {
        if (result === null) {
        } else if (result === true) {
          that.servicesService.delete(item.id).subscribe(()=>{
            that.search();
          }, (error: HttpErrorResponse) => {
            this.blockUI.stop();
            this.gaService.exception(error.error?.message, true);
            if (error.status === 401) {
              this.activeModal.close();
              this.router.navigate(['/']);
              this.matSnackBar.open('Your session has expired', 'Okay', {
                duration: 5000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              });
            }else {
              this.blockUI.stop();
              this.matSnackBar.open(error.error.message, 'Okay', {
                duration: 5000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              });
            }
          });
        }
      }
    });
  }
  newItem() {
    this.item = new Race();
    this.open(this.item);
  }
  open(item: Race) {
    const modalRef = this.modalService.open(RaceComponent, {fullscreen:true, modalDialogClass:'animate-bottom'});
    modalRef.componentInstance.item= item;
    modalRef.componentInstance.name = 'World';
    modalRef.closed.subscribe(()=>{
      this.search();
    });
  }

}
