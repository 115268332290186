import {Component, Input, OnInit} from '@angular/core';
import {CoreComponent} from '../../core/core.component';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Results} from '../../core/results';
import {UserComponent} from './user/user.component';
import {UsersService} from '../../users/users.service';
import { User } from './user/user';
import {HigherEducationInstitution} from '../../higher-education-institutions/higher-education-institution/higher-education-institution';
import {InstitutionalUsersService} from '../../users/institutional-users.service';
import {HttpErrorResponse} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Meta, Title} from '@angular/platform-browser';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
declare var $: any;
declare var bootbox: any;
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent extends CoreComponent<User> {

  higherEducationInstitution: HigherEducationInstitution;
  @Input() role: string;
  constructor(protected servicesService: UsersService, public route: ActivatedRoute,private modalService: NgbModal,
              public activeModal: NgbActiveModal,protected gaService: GoogleAnalyticsService,
              public meta: Meta, public title: Title,
              public institutionalUsersService : InstitutionalUsersService,private matSnackBar: MatSnackBar, private router: Router) {
    super(servicesService,route);
    this.title.setTitle('Users');
  }

  newItem() {
    this.item = new User();
    this.item.role = this.role;
    this.open(this.item);
  }
  remove(item: User){
    const that = this;
    bootbox.confirm({
      title: 'Are you sure you want to delete?',
      message: '<strong>'+item.firstName+' '+item.lastName+'</strong>',
      callback: function(result) {
        if (result === null) {
        } else if (result === true) {

          that.servicesService.delete(item.id).subscribe(()=>{
            that.search();
          }, (error: HttpErrorResponse) => {
            this.blockUI.stop();
            this.gaService.exception(error.error?.message, true);
            if (error.status === 401) {
              this.activeModal.close();
              this.router.navigate(['/']);
              this.matSnackBar.open('Your session has expired', 'Okay', {
                duration: 5000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              });
            }else {
              this.blockUI.stop();
              this.matSnackBar.open(error.error.message, 'Okay', {
                duration: 5000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
              });
            }
          });
        }
      }
    });
  }

  open(item: User) {
    const modalRef = this.modalService.open(UserComponent, {fullscreen:true, modalDialogClass:'animate-bottom'});
    modalRef.componentInstance.item= item;
    modalRef.componentInstance.name = 'World';
    modalRef.closed.subscribe(()=>{
      this.search();
    });
  }

  public assignInstitution(user: User) {

     this.blockUI.start();
      this.institutionalUsersService.update(user).subscribe(() => {
        this.blockUI.stop();
        this.matSnackBar.open(user.firstName+' '+user.lastName+' has been successfully assigned to '+this.higherEducationInstitution.name, 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }, (error: HttpErrorResponse) => {
        this.blockUI.stop();
        this.gaService.exception(error.error?.message, true);
        if (error.status === 401) {
          this.activeModal.close();
          this.router.navigate(['/']);
          this.matSnackBar.open('Your session has expired', 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }else {
          this.blockUI.stop();
          this.matSnackBar.open(error.error.message, 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
      });
  }

  public search() {

    this.blockUI.start();
    this.servicesService.searchByRole(this.currentPage, this.perPage, this.order, this.orderBy, this.keyword, this.role)
      .subscribe((data: Results<User>) => {
        this.results = data;
        this.blockUI.stop();
      },error => {
        this.blockUI.stop();
      });

  }

}
