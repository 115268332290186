<div class="row">
  <div class="col-md-12">
    <app-card [showSave]="true">
      <div class="row justify-content-center">


        <div class="col-md-10" >
          <ng-stepper #cdkStepper [linear]="true" class="">
            <cdk-step [stepControl]="" [optional]="false">
              <ng-template cdkStepLabel>

              </ng-template>

              <app-apply-institution [cdkStepper]="cdkStepper"></app-apply-institution>
            </cdk-step>


<!--            <cdk-step [stepControl]="" [optional]="true">-->
<!--              <ng-template cdkStepLabel>-->
<!--                &lt;!&ndash; <div class="step-bullet">3</div>-->
<!--              <div class="step-title"></div> &ndash;&gt;-->
<!--              </ng-template>-->
<!--              <app-apply-laptop [cdkStepper]="cdkStepper"></app-apply-laptop>-->
<!--            </cdk-step>-->

            <cdk-step [stepControl]="" [optional]="true" *ngIf="applicationsService.application && ((applicationsService.application.metadata && applicationsService?.application?.metadata?.skipParentsScreen ===false) || !applicationsService?.application?.metadata) ">
              <ng-template cdkStepLabel>
              </ng-template>
              <app-apply-details [cdkStepper]="cdkStepper"></app-apply-details>
            </cdk-step>
            <cdk-step [stepControl]="" [optional]="true">
              <ng-template cdkStepLabel>
              </ng-template>
              <app-offer-letter [cdkStepper]="cdkStepper"></app-offer-letter>
            </cdk-step>
            <cdk-step [stepControl]="" [optional]="true" *ngIf="applicationsService?.application?.consentForm || applicationsService?.application?.disabilityAnnexure 
            || applicationsService?.application?.provisionalOfferLetter || applicationsService?.application?.vulnerableChildOrNonSassDeclaration ||
            applicationsService?.application?.father?.requiredDocuments?.length>0 || applicationsService?.application?.mother?.requiredDocuments?.length>0 
            || applicationsService?.application?.guardian?.requiredDocuments?.length>0 || applicationsService?.application?.spouse?.requiredDocuments?.length>0 ">
              <ng-template cdkStepLabel>
              </ng-template>
              <app-apply-upload-documents [cdkStepper]="cdkStepper"></app-apply-upload-documents>
            </cdk-step>

<!--            <cdk-step [stepControl]="" [optional]="true" *ngIf="applicationsService.application && applicationsService.application.metadata && applicationsService.application.metadata.skipParentsScreen ===false">-->
<!--              <ng-template cdkStepLabel>-->

            <!--              </ng-template>-->
            <!--              <app-apply-docs *ngIf="cdkStepper.selectedIndex==4" [cdkStepper]="cdkStepper"></app-apply-docs>-->
            <!--            </cdk-step>-->

            
            <cdk-step [stepControl]="" [optional]="true">
              <ng-template cdkStepLabel>

              </ng-template>
              <app-apply-confirm [cdkStepper]="cdkStepper"></app-apply-confirm>
            </cdk-step>
            <cdk-step [stepControl]="" [optional]="true">
              <ng-template cdkStepLabel>
              </ng-template>

              <app-apply-terms [cdkStepper]="cdkStepper"></app-apply-terms>
            </cdk-step>


            <cdk-step [stepControl]="" [optional]="true">
              <ng-template cdkStepLabel>
                <!-- <div class="step-bullet">5</div>
              <div class="step-title">Terms</div> -->
              </ng-template>
              <app-apply-submitted [cdkStepper]="cdkStepper"></app-apply-submitted>
            </cdk-step>

          </ng-stepper>

        </div>

      </div>
    </app-card>
  </div>
</div>
