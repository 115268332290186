import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {NgForm} from '@angular/forms';
import {CdkStepper} from '@angular/cdk/stepper';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {TermConditionsService} from '../../admin/term-conditions/term-conditions.service';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {MatSnackBar} from '@angular/material/snack-bar';
import {LoansService} from '../loans.service';
import {Application} from '../../applications/application/application';
import {HttpErrorResponse} from '@angular/common/http';
declare var $: any;
@Component({
  selector: 'app-loan',
  templateUrl: './loan.component.html',
  styleUrls: ['./loan.component.scss']
})
export class LoanComponent  {
  agree: boolean;
  public validated: boolean;
  @BlockUI() blockUI: NgBlockUI;
  @ViewChild('termsCondition', {static: false})
  public termsCondition: NgForm;
  @Input()application: Application;
  constructor(private router: Router,
              public activeModal: NgbActiveModal,
              public loansService: LoansService,
              protected servicesService: TermConditionsService,
              protected gaService: GoogleAnalyticsService,
              private matSnackBar: MatSnackBar) { }


  public onSubmit() {

    if (!this.termsCondition.valid) {
      this.validated = true;
      $('html, body').animate({
        scrollTop: 0
      }, 1000, function() {
      });
      return;
    } else {
      this.blockUI.start();
      this.loansService.apply(this.application).subscribe(()=>{
        this.blockUI.stop();
        this.activeModal.close();
        this.matSnackBar.open('Your loan application has been accepted', 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }, (error: HttpErrorResponse) => {
        this.blockUI.stop();
        this.gaService.exception(error.error?.message, true);
        if (error.status === 401 ) {
          this.router.navigate(['/']);
          this.matSnackBar.open('Your session has expired', 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }else {
          this.matSnackBar.open(error.error.message, 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
      });
    }
  }

  closeModal() {
    this.activeModal.close();
  }

}
