<div class="row justify-content-center">
  <div class="col-md-5">

    <h2 class="mb-3">NSFAS funding application</h2>
    <h5 class="mb-4">Make sure you have the following electronic documents</h5>
    <div class="alert alert-light mb-3 text-start text-dark">
      <small><ul>
        <li>Your South African identity document/card</li>
        <li>IDs of parents / spouse / guardian (or death certificate where applicable).</li>
        <li>Court order (Where applicable)</li>
        <li>Proof of income (Where applicable)</li>
        <li>Vulnerable Child (&lt; 18) NSFAS Declaration (Where applicable)</li>
        <li>Marriage certificate (Where applicable)</li>
        <li>No affidavits</li>
        <li>Disability annexure (Where applicable) </li>
      </ul></small>
    </div>
    <form (ngSubmit)="onSubmit()" #documentForm="ngForm" class="px-5 needs-validation" [ngClass]="validated===true?'was-validated':''">

    <div class="row justify-content-center" *ngIf="applicationsService.application.disabilityAnnexure">
      <div class="col-md-12 text-start">
        <div class="mb-5 mt-2">
          <div class="mb-3">
            <label for="formFileSm" class="form-label">{{applicationsService.application.disabilityAnnexure.caption}}</label>
            <input required [(ngModel)]="applicationsService.application.disabilityAnnexure" appFileUpload   name="disabilityAnnexure" accept="image/*,.pdf" class="form-control form-control-sm" id="formFileSm" type="file">
            <div class="invalid-feedback">
              {{applicationsService.application.disabilityAnnexure.caption}} is required
            </div>
          </div>
          <small class="ps-3" *ngIf="!applicationsService.application.disabilityAnnexure">PDF or Jpeg, max 20mb</small>
          <small class="ps-3" *ngIf="applicationsService.application.disabilityAnnexure">{{applicationsService.application.disabilityAnnexure.name}}</small>
        </div>
      </div>
    </div>
    <div class="row justify-content-center" *ngIf="!applicationsService.application.sassaFunded">
      <div class="col-md-12 text-start">
        <div class="mb-5 mt-2">
          <div class="mb-3">
            <label for="formFileSm" class="form-label">{{applicationsService.application.consentForm.caption}}</label>
            <input required [(ngModel)]="applicationsService.application.consentForm" appFileUpload   name="sarsConsent" accept="image/*,.pdf" class="form-control form-control-sm" id="formFileSm" type="file">
            <div class="invalid-feedback">
              {{applicationsService.application.consentForm.caption}} is required
            </div>
          </div>
          <small class="ps-3" *ngIf="!applicationsService.application.consentForm">PDF or Jpeg, max 20mb</small>
          <small class="ps-3" *ngIf="applicationsService.application.consentForm">{{applicationsService.application.consentForm.name}}</small>
        </div>
      </div>
    </div>
    <div class="row justify-content-center" *ngIf="applicationsService.application.provisionalOfferLetter">
      <div class="col-md-12 text-start">
        <div class="mb-5 mt-2">
          <div class="mb-3">
            <label for="formFileSm" class="form-label">{{applicationsService.application.provisionalOfferLetter.caption}}</label>
            <input required [(ngModel)]="applicationsService.application.provisionalOfferLetter" appFileUpload   name="provisionalOfferLetter" accept="image/*,.pdf" class="form-control form-control-sm" id="formFileSm" type="file">
            <div class="invalid-feedback">
              {{applicationsService.application.provisionalOfferLetter.caption}} is required
            </div>
          </div>
          <small class="ps-3" *ngIf="!applicationsService.application.provisionalOfferLetter">PDF or Jpeg, max 20mb</small>
          <small class="ps-3" *ngIf="applicationsService.application.provisionalOfferLetter">{{applicationsService.application.provisionalOfferLetter.name}}</small>
        </div>
      </div>
    </div>

    <div class="row justify-content-center" *ngIf="applicationsService.application.vulnerableChildOrNonSassDeclaration">
      <div class="col-md-12 text-start">
        <div class="mb-5 mt-2">
          <div class="mb-3">
            <label for="formFileSm" class="form-label">{{applicationsService.application.vulnerableChildOrNonSassDeclaration.caption}}</label>
            <input required [(ngModel)]="applicationsService.application.vulnerableChildOrNonSassDeclaration" appFileUpload   name="vulnerableChildOrNonSassDeclaration" accept="image/*,.pdf" class="form-control form-control-sm" id="vulnerableChildOrNonSassDeclaration" type="file">
            <div class="invalid-feedback">
              {{applicationsService.application.vulnerableChildOrNonSassDeclaration.caption}} is required
            </div>
          </div>
          <small class="ps-3" *ngIf="!applicationsService.application.vulnerableChildOrNonSassDeclaration">PDF or Jpeg, max 20mb</small>
          <small class="ps-3" *ngIf="applicationsService.application.vulnerableChildOrNonSassDeclaration">{{applicationsService.application.vulnerableChildOrNonSassDeclaration.name}}</small>
        </div>
      </div>
    </div>
    <div *ngIf="applicationsService.application.spouse && applicationsService.application.spouse.requiredDocuments">
        <div class="row justify-content-center" *ngFor="let requiredDocument of applicationsService.application.spouse.requiredDocuments; let i = index">
          <div class="col-md-12 text-start">
            <div class="mb-5 mt-2">
              <div class="mb-3">
                <label for="formFileSm" class="form-label">{{requiredDocument.caption}}</label>
                <input required [(ngModel)]="applicationsService.application.spouse.requiredDocuments[i]" appFileUpload [file]="applicationsService.application.spouse.requiredDocuments[i]" name="spouseRequiredDocument{{i}}" accept="image/*,.pdf" class="form-control form-control-sm" type="file">
                <div class="invalid-feedback">
                  {{applicationsService.application.spouse.requiredDocuments[i].name}} is required
                </div>
              </div>
              <small class="ps-3" *ngIf="!applicationsService.application.spouse.requiredDocuments[i]">PDF or Jpeg, max 20mb</small>
              <small class="ps-3" *ngIf="applicationsService.application.spouse.requiredDocuments[i]">{{applicationsService.application.spouse.requiredDocuments[i].name}}</small>
            </div>
          </div>
        </div>
      </div>
    <div *ngIf="applicationsService.application.mother && applicationsService.application.mother.requiredDocuments">
      <div class="row justify-content-center" *ngFor="let requiredDocument of applicationsService.application.mother.requiredDocuments; let i = index">
        <div class="col-md-12 text-start">
          <div class="mb-5 mt-2">
            <div class="mb-3">
              <label for="formFileSm" class="form-label">{{requiredDocument.caption}}</label>
                <input required [(ngModel)]="applicationsService.application.mother.requiredDocuments[i]" appFileUpload name="motherRequiredDocument{{i}}" accept="image/*,.pdf" [file]="applicationsService.application.mother.requiredDocuments[i]" class="form-control form-control-sm" type="file">
              <div class="invalid-feedback">
                {{applicationsService.application.mother.requiredDocuments[i].name}} is required
              </div>
            </div>
            <small class="ps-3" *ngIf="!applicationsService.application.mother.requiredDocuments[i]">PDF or Jpeg, max 20mb</small>
            <small class="ps-3" *ngIf="applicationsService.application.mother.requiredDocuments[i]">{{applicationsService.application.mother.requiredDocuments[i].name}}</small>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="applicationsService.application.father && applicationsService.application.father.requiredDocuments">
        <div class="row justify-content-center" *ngFor="let requiredDocument of applicationsService.application.father.requiredDocuments; let i = index">
          <div class="col-md-12 text-start">
            <div class="mb-5 mt-2">
              <div class="mb-3">
                <label for="formFileSm" class="form-label">{{requiredDocument.caption}}</label>
                <input required [(ngModel)]="applicationsService.application.father.requiredDocuments[i]" appFileUpload name="fatherRequiredDocument{{i}}" accept="image/*,.pdf" [file]="applicationsService.application.father.requiredDocuments[i]" class="form-control form-control-sm" type="file">
                <div class="invalid-feedback">
                  {{applicationsService.application.father.requiredDocuments[i].name}} is required
                </div>
              </div>
              <small class="ps-3" *ngIf="!applicationsService.application.father.requiredDocuments[i]">PDF or Jpeg, max 20mb</small>
              <small class="ps-3" *ngIf="applicationsService.application.father.requiredDocuments[i]">{{applicationsService.application.father.requiredDocuments[i].name}}</small>
            </div>
          </div>
        </div>


      </div>
    <div *ngIf="applicationsService.application.guardian && applicationsService.application.guardian.requiredDocuments">
        <div class="row justify-content-center" *ngFor="let requiredDocument of applicationsService.application.guardian.requiredDocuments; let i = index">
          <div class="col-md-12 text-start">
            <div class="mb-5 mt-2">
              <div class="mb-3">
                <label for="formFileSm" class="form-label">{{requiredDocument.caption}}</label>
                <input required [(ngModel)]="applicationsService.application.guardian.requiredDocuments[i]" appFileUpload name="guardianRequiredDocument{{i}}" [file]="applicationsService.application.guardian.requiredDocuments[i]" accept="image/*,.pdf" class="form-control form-control-sm" type="file">
                <div class="invalid-feedback">
                  {{applicationsService.application.guardian.requiredDocuments[i].name}} is required
                </div>
              </div>
              <small class="ps-3" *ngIf="!applicationsService.application.guardian.requiredDocuments[i]">PDF or Jpeg, max 20mb</small>
              <small class="ps-3" *ngIf="applicationsService.application.guardian.requiredDocuments[i]">{{applicationsService.application.guardian.requiredDocuments[i].name}}</small>
            </div>
          </div>
        </div>
      </div>



    <div class="row">
      <div class="col-md-6">
        <div class="d-grid gap-2">

          <button class="btn btn-outline-dark btn-lg mb-3" type="button" (click)="previous()">Previous</button>
        </div>

      </div>
      <div class="col-md-6">
        <div class="d-grid gap-2">

          <button class="btn btn-primary btn-lg mb-3" type="submit">Next</button>
        </div>

      </div>
    </div>
    </form>

  </div>

</div>
