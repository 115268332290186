import { Injectable } from '@angular/core';
import {ServicesService} from '../../users/services.service';
import {HttpClient} from '@angular/common/http';
import {ConfigServices} from '../../core/config';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RequiredDocumentsService extends ServicesService {

  constructor(public http: HttpClient, public configService: ConfigServices, public cookieService: CookieService, private router: Router) {
    super('admin/case-management', http, configService);
  }

  public getRequestedDocuments(caseId: string) {

    const url = this.configService.getApi() + this.type + '/requested-documents/'+caseId;
    return this.http.get(url,{  withCredentials: true });

  }
}
