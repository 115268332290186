import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class FilenameUtilsService {

  constructor() { }

  sanitiseFilename(filename: string): string {
    if (!filename) {
      return "";
    }
    
    // Define a regular expression to match invalid characters
    const invalidCharsRegex = /[<>:"/\\|?*\[\]\x00-\x1F]/g;
  
    // Replace invalid characters with a blank
    const sanitizedFilename = filename.replace(invalidCharsRegex, '');
  
    return sanitizedFilename;
  }
  

}
