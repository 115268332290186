import { Injectable } from '@angular/core';
import {ServicesService} from '../users/services.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ConfigServices} from '../core/config';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {Application} from '../applications/application/application';

@Injectable({
  providedIn: 'root'
})
export class LoansService extends ServicesService {

  constructor(public http: HttpClient, public configService: ConfigServices,public cookieService: CookieService, private router: Router) {
    super('loans', http, configService);
  }

  public apply(application: Application) {

    const url = this.configService.getApi() + this.type + '/applications/apply/'+application.id;
    return this.http.post(url,{}, {withCredentials: true});

  }
}
