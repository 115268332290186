import {Component, OnInit} from '@angular/core';
import {ApplicationsService} from '../../applications/applications.service';
import {NbaComponent} from '../../authentication/nba/nba.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-loan-bursary-decision',
  templateUrl: './loan-bursary-decision.component.html',
  styleUrls: ['./loan-bursary-decision.component.scss']
})
export class LoanBursaryDecisionComponent implements OnInit {

  constructor(public applicationService: ApplicationsService, public modalService: NgbModal) {
  }

  ngOnInit(): void {
   // this.applicationService.application.fundingBucket = 'BURSARY';
  }

  close() {
    this.modalService.dismissAll();
  }
  nbaDocuments() {
    const modalRef = this.modalService.open(NbaComponent, {fullscreen: true, modalDialogClass: 'animate-bottom'});
    modalRef.componentInstance.name = 'World';
    modalRef.closed.subscribe(() => {
    });

  }
}
