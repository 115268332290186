import { Component, OnInit } from '@angular/core';
import {CoreComponent} from '../core/core.component';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {ActivatedRoute} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Results} from '../core/results';
import {FrequentlyAskedQuestion} from './frequently-asked-question/frequently-asked-question';
import {FrequentlyAskedQuestionsService} from './frequently-asked-questions.service';
import {FrequentlyAskedQuestionComponent} from './frequently-asked-question/frequently-asked-question.component';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
declare var bootbox: any;
@Component({
  selector: 'app-frequently-asked-questions',
  templateUrl: './frequently-asked-questions.component.html',
  styleUrls: ['./frequently-asked-questions.component.scss']
})
export class FrequentlyAskedQuestionsComponent extends CoreComponent<FrequentlyAskedQuestion> {
  @BlockUI() blockUI: NgBlockUI;
  constructor(protected servicesService: FrequentlyAskedQuestionsService, public route: ActivatedRoute,
              private modalService: NgbModal,protected gaService: GoogleAnalyticsService) {
    super(servicesService,route);
  }

  public search() {
    this.blockUI.start();
    this.servicesService.search(this.currentPage, this.perPage, this.order, this.orderBy, this.keyword)
      .subscribe((data: Results<FrequentlyAskedQuestion>) => {
        this.results = data;
        this.blockUI.stop();
      },error => {
        this.gaService.exception(error.error?.message, true);
      });
  }
  remove(item: FrequentlyAskedQuestion){
    const that = this;
    bootbox.confirm({
      title: 'Are you sure you want to delete?',
      message: '<strong>'+item.question+'</strong>',
      callback: function(result) {
        if (result === null) {
        } else if (result === true) {

          that.servicesService.delete(item.id).subscribe(()=>{
            that.search();
          });
        }
      }
    });
  }
  newItem() {
    this.item = new FrequentlyAskedQuestion();
    this.open(this.item);
  }
  open(item: FrequentlyAskedQuestion) {
    const modalRef = this.modalService.open(FrequentlyAskedQuestionComponent, {fullscreen:true, modalDialogClass:'animate-bottom'});
    modalRef.componentInstance.item= item;
    modalRef.componentInstance.name = 'World';
    modalRef.closed.subscribe(()=>{
      this.search();
    });
  }


}
