<!--<div class="row justify-content-center" *ngIf="loading === false">-->
<!--  <div class="col-md-11">-->
<!--    <div class="tracker-container p-3 my-4">-->

<!--      <ng-stepper #cdkStepper [linear]="true" class="mt-4" [selectedIndex]="index" >-->

<!--        <cdk-step [editable]="false" [stepControl]="" [completed]="index >0" >-->
<!--          <ng-template cdkStepLabel>-->
<!--            <div class="step-bullet">1</div>-->
<!--            <div class="step-title">Appeal submitted</div>-->
<!--          </ng-template>-->
<!--          &lt;!&ndash; <app-apply-details kinType="Father"></app-apply-details> &ndash;&gt;-->
<!--        </cdk-step>-->
<!--        <cdk-step [editable]="false" [stepControl]="" [completed]="index >1">-->
<!--          <ng-template cdkStepLabel>-->
<!--            <div class="step-bullet">2</div>-->
<!--            <div class="step-title">Appeal in progress</div>-->
<!--          </ng-template>-->
<!--          &lt;!&ndash; <app-apply-details kinType="Gaurdian"></app-apply-details> &ndash;&gt;-->
<!--        </cdk-step>-->

<!--        <cdk-step [editable]="false" [stepControl]="" [completed]="index >2">-->
<!--          <ng-template cdkStepLabel>-->
<!--            <div class="step-bullet">3</div>-->
<!--            <div class="step-title">Appeal Pending Outstanding Documents</div>-->
<!--          </ng-template>-->
<!--          &lt;!&ndash; <app-apply-docs></app-apply-docs> &ndash;&gt;-->
<!--        </cdk-step>-->

<!--        <cdk-step [editable]="false" [stepControl]="" [completed]="index >3">-->
<!--          <ng-template cdkStepLabel>-->
<!--            <div class="step-bullet">4</div>-->
<!--            <div class="step-title">Appeal Rejected</div>-->
<!--          </ng-template>-->
<!--          &lt;!&ndash; <app-apply-details kinType="Mother"></app-apply-details> &ndash;&gt;-->
<!--        </cdk-step>-->

<!--        <cdk-step [editable]="false" [stepControl]="" [completed]="index >4">-->
<!--          <ng-template cdkStepLabel>-->
<!--            <div class="step-bullet">5</div>-->
<!--            <div class="step-title">Appeal Finalized</div>-->
<!--          </ng-template>-->
<!--          &lt;!&ndash; <app-apply-details kinType="Spouse"></app-apply-details> &ndash;&gt;-->
<!--        </cdk-step>-->
<!--        <cdk-step [editable]="false" [stepControl]="" [completed]="index >5">-->
<!--          <ng-template cdkStepLabel>-->
<!--            <div class="step-bullet">6</div>-->
<!--            <div class="step-title">Appeal Withdrawn</div>-->
<!--          </ng-template>-->
<!--          &lt;!&ndash; <app-apply-details kinType="Spouse"></app-apply-details> &ndash;&gt;-->
<!--        </cdk-step>-->
<!--      </ng-stepper>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<ng-container *ngFor="let appeal of appeals">
<div class="row justify-content-center">
  <div class="col-md-3" >
    <div class="tracker-container justify-content-center p-3 my-4" >
      Status: {{getAppealStatus(appeal)}}
      <p class="mb-1 text-muted" *ngIf="appeal.status==='ReProcess'">
        {{appeal.appealReProcessReason.name}}
      </p>
    </div>
  </div>
</div>
<div class="row justify-content-center" *ngIf="loading === false">
  <div class="col-md-5">

    <div class="row my-5">
      <div class="col-md-1 text-center mt-2"> <i class="fa fa-user fa-2x"></i>
      </div>
      <div class="col-md-11">
        <!--        <p class="text-muted float-end mt-2">-->
        <!--          Proline V1165C4 from Pinnacle Micro-->
        <!--        </p>-->
        <p class="notif-body mb-2">{{appeal.application?.user?.firstName}} {{appeal.application?.user?.lastName}}</p>

        <p class="mb-1 text-muted">
          Ref: {{appeal?.application?.reference}}
        </p>

        <p class="mb-1 text-muted" *ngIf="appeal?.appealReason?.rejectReason">
          Appeal Reject Reason: {{appeal?.appealReason?.rejectReason.description}}
        </p>
        <p class="mb-1">
          Application Status: {{getStatus(application)}}
        </p>
        <p class="mb-1 text-muted" *ngIf="appeal?.appealReProcessReason">
          Appeal Re-Process Reason: {{appeal?.appealReProcessReason?.name}}
        </p>

        <p class="mb-1" *ngIf="appeal?.rejectAppealReProcessReason">
          Appeal Re-Process Reject Reason: {{appeal?.rejectAppealReProcessReason?.name}}
        </p>

      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-8">


        <div class="mb-5" *ngIf="appeal.submittedDocuments?.appeals?.length>0">
          <p class="mb-0"><strong>Supporting Documents</strong></p>
          <p class="mb-0" *ngFor="let supportingDocument of appeal.submittedDocuments?.appeals">
            <a target="_blank" [href]="configService.getApi()+'file/'+supportingDocument.id">{{supportingDocument.name}}</a>
          </p>
        </div>

        <div class="mb-5" *ngIf="appeal.application?.disabilityAnnexure && appeal.application?.disabilityAnnexure.id">
          <p class="mb-0"><strong>Disability Annexure</strong></p>
          <p class="mb-0">
            <a target="_blank" [href]="configService.getApi()+'file/'+appeal.application?.disabilityAnnexure.id">{{appeal.application?.disabilityAnnexure.name}}</a>
          </p>
        </div>
        <div class="mb-5" *ngIf="appeal.application?.spouse && appeal.application?.spouse.idNumber">
          <p class="mb-0"><strong>Spouse</strong></p>
          <p class="mb-0">{{appeal.application?.spouse.firstName}} {{appeal.application?.spouse.lastName}}</p>
          <p class="mb-0">{{appeal.application?.spouse.idNumber}}</p>
          <div *ngIf="appeal.application?.spouse.supportingDocs">

            <p *ngFor="let document of appeal.application?.spouse.supportingDocs">
              {{document.caption}}<br/>
              <a target="_blank" [href]="configService.getApi()+'file/'+document.id">{{document.name}}</a>
            </p>
          </div>

        </div>
        <div class="mb-5" *ngIf="appeal.application?.father && appeal.application?.father.idNumber">
          <p class="mb-0"><strong>Father</strong></p>
          <p class="mb-0">{{appeal.application?.father.firstName}} {{appeal.application?.father.lastName}}</p>
          <p class="mb-0">{{appeal.application?.father.idNumber}}</p>
          <div *ngIf="appeal.application?.father.supportingDocs">
            <p *ngFor="let document of appeal.application?.father.supportingDocs">
              {{document.caption}}<br/>
              <a target="_blank" [href]="configService.getApi()+'file/'+document.id">{{document.name}}</a>
            </p>
          </div>
        </div>
        <div class="mb-5" *ngIf="appeal.application?.mother && appeal.application?.mother.idNumber">
          <p class="mb-0"><strong>Mother</strong></p>
          <p class="mb-0">{{appeal.application?.mother.firstName}} {{appeal.application?.mother.lastName}}</p>
          <p class="mb-0">{{appeal.application?.mother.idNumber}}</p>
          <div *ngIf="appeal.application?.mother.supportingDocs">
            <p *ngFor="let document of appeal.application?.mother.supportingDocs">
              {{document.caption}}<br/>
              <a target="_blank" [href]="configService.getApi()+'file/'+document.id">{{document.name}}</a>
            </p>
          </div>
        </div>
        <div class="mb-5" *ngIf="appeal.application?.guardian && appeal.application?.guardian.idNumber">
          <p class="mb-0"><strong>Guardian</strong></p>
          <p class="mb-0">{{appeal.application?.guardian.firstName}} {{appeal.application?.guardian.lastName}}</p>
          <p class="mb-0">{{appeal.application?.guardian.idNumber}}</p>
          <div *ngIf="appeal.application?.guardian.supportingDocs">
            <p *ngFor="let document of appeal.application?.guardian.supportingDocs">
              {{document.caption}}<br/>
              <a target="_blank" [href]="configService.getApi()+'file/'+document.id">{{document.name}}</a>
            </p>
          </div>
        </div>
        <div class="d-grid gap-2" *ngIf="appeal.canWithdraw">
          <button class="btn btn-primary btn-lg mb-3" (click)="withdraw(appeal)" type="submit">Cancel appeal</button>
        </div>
        <div class="d-grid gap-2" *ngIf="appeal.canReOpenWithdrawn">
          <button class="btn btn-primary btn-lg mb-3" (click)="reOpenWithdrawn(appeal)" type="submit">Re Open Appeal</button>
        </div>

        <div class="d-grid gap-2" *ngIf="appeal.canRequestReprocess">
          <button class="btn btn-primary btn-lg mb-3" (click)="reProcessAppeal(appeal)" type="submit">Request Appeal reprocess</button>
        </div>
      </div>
    </div>


  </div>
</div>
</ng-container>
