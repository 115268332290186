import {HttpErrorResponse} from '@angular/common/http';
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CookieService} from 'ngx-cookie-service';
import {UsersService} from '../../users/users.service';
import {StudyStep} from 'src/app/core/models/study';
import {ApplicationsService} from '../../applications/applications.service';
import {NgForm} from '@angular/forms';
import {CdkStepper} from '@angular/cdk/stepper';
import {Meta, Title} from '@angular/platform-browser';
import {MatSnackBar} from '@angular/material/snack-bar';
import {User} from '../../admin/users/user/user';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {Router} from '@angular/router';
import {AssistedService} from '../../applications/assisted.service';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {Application, Documets} from '../../applications/application/application';
declare var $: any;

@Component({
  selector: 'app-apply-study',
  templateUrl: './apply-study.component.html',
  styleUrls: ['./apply-study.component.scss']
})
export class ApplyStudyComponent implements OnInit {
  public study: StudyStep;

  @Input() kinType: string;
  @ViewChild('kinForm', {static: false})
  public kinForm: NgForm;
  public validated: boolean;
  @Input() cdkStepper: CdkStepper;
  @BlockUI() blockUI: NgBlockUI;
  documets: Documets = new Documets();
  constructor(public activeModal: NgbActiveModal, protected assistedService: AssistedService,
              public service: UsersService,protected gaService: GoogleAnalyticsService,
              public cookieService: CookieService,public meta: Meta, public title: Title,
              public applicationsService: ApplicationsService, private router: Router, private matSnackBar: MatSnackBar) {
    this.study = new StudyStep();
    this.title.setTitle('Apply Study');
  }

  ngOnInit() {
  }

  previous() {
    this.cdkStepper.previous();
  }

  normal() {
    this.blockUI.start();
    this.applicationsService.save().subscribe((user: User)=>{
      this.blockUI.stop();
      this.cdkStepper.next();
    }, (error: HttpErrorResponse) => {
      this.blockUI.stop();
      this.gaService.exception(error.error?.message, true);
      if(error.status === 400) {
        this.documets = JSON.parse(JSON.stringify(error.error));
        this.applicationsService.application.metadata = this.documets.metadata;
        this.cdkStepper.next();
      }else if (error.status === 401 ) {
        this.router.navigate(['/']);
        this.matSnackBar.open('Your session has expired', 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }else if (error.status !== 400) {
        this.matSnackBar.open(error.error.message, 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }
    });
  }
  dataCapture() {
    this.blockUI.start();
    this.assistedService.save().subscribe((application: Application)=>{
      this.blockUI.stop();
      this.applicationsService.application = application;
      this.cdkStepper.next();
    }, (error: HttpErrorResponse) => {
      this.blockUI.stop();
      this.gaService.exception(error.error?.message, true);
      if(error.status === 400) {
        this.documets = JSON.parse(JSON.stringify(error.error));
        this.applicationsService.application.metadata = this.documets.metadata;
        this.cdkStepper.next();
      }else if (error.status === 401 ) {
        this.router.navigate(['/']);
        this.matSnackBar.open('Your session has expired', 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }else if (error.status !== 400) {
        this.matSnackBar.open(error.error.message, 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }
    });
  }
  onSubmit() {

    if (!this.kinForm.valid) {
      this.validated = true;
      $('html, body').animate({
        scrollTop: 0
      }, 1000, function() {
      });
      return;
    } else {
      if(this.assistedService.idNumber) {
        this.dataCapture();
      }else {
        this.normal();
      }
    }
  }

}
