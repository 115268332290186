  // Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import {CookieService} from "ngx-cookie-service";
// RxJS
//import 'rxjs/add/operator/timeout';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {
  constructor(public cookieService: CookieService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    const authToken = this.cookieService.get('X_TOKEN');
    if (authToken !== undefined && authToken !== null && authToken !== '') {
      const dt = this.addMinutes(new Date(), 60);
      this.cookieService.set('X_TOKEN', authToken,null,null,null,true,'Strict');
      const headers = req.headers.set('X_TOKEN', authToken);
      const authReq = req.clone({headers: headers, params: req.params});
      return next.handle(authReq);
    }
    return next.handle(req);

  }

  public addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes * 60000);
  }
}
