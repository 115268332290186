import {Directive, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {HigherEducationInstitutionType} from './higher-education-institution-type/higher-education-institution-type';
import {HigherEducationInstitutionTypesService} from './higher-education-institution-types.service';
import {ConfigService} from '../config.service';
import {Results} from '../core/results';
declare var $: any;
@Directive({
  selector: '[appHigherEducationInstitutionTypes]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HigherEducationInstitutionTypesDirective),
    multi: true
  }]
})
export class HigherEducationInstitutionTypesDirective implements ControlValueAccessor, OnInit {

  private higherEducationInstitutionTypes: HigherEducationInstitutionType[];
  @Input() private disabled = false;
  @Input() private checkOpened = false;
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  select2: any;

  constructor(private el: ElementRef, private higherEducationInstitutionTypesService: HigherEducationInstitutionTypesService, public configService: ConfigService) {
  }

  ngOnInit(): void {

    this.higherEducationInstitutionTypes = new Array<HigherEducationInstitutionType>();
    const that = this;
    this.select2 = $(this.el.nativeElement).select2({
      width: '100%',
      placeholder: 'Select Higher Education Institution Types',
      ajax: {
        transport: function(params, success, failure) {
          const page = 1;
          const perPage = 10;

          if(that.checkOpened === true) {
            that.higherEducationInstitutionTypesService.searchOpened(page, perPage, 'ASC', 'name', params.data.term).subscribe((ret: Results<HigherEducationInstitutionType>) => {
              success(ret);
            });
          } else {
            that.higherEducationInstitutionTypesService.search(page, perPage, 'ASC', 'name', params.data.term).subscribe((ret: Results<HigherEducationInstitutionType>) => {
              success(ret);
            });
          }},
        dataType: 'json',
        type: 'GET',
        processResults: function (data) {
          const arr = [];
          data.content.forEach(function (obj) {
            arr.push({
              id: JSON.stringify(obj),
              text: obj.name,
            });
          });
          return {
            results: arr
          };
        }
      }
    });
    $(this.el.nativeElement).on('select2:select', function (e) {

      if (that.higherEducationInstitutionTypes === undefined || that.higherEducationInstitutionTypes === null) {
        that.higherEducationInstitutionTypes = new Array<HigherEducationInstitutionType>();
      }
      that.higherEducationInstitutionTypes.push(JSON.parse(e.params.data.id));
      that.propagateChange(that.higherEducationInstitutionTypes);

    });

    $(this.el.nativeElement).on('select2:unselect', function (e) {

      const role = JSON.parse(e.params.data.id);
      let idx = -1;
      for (let i = 0; i < that.higherEducationInstitutionTypes.length; i++) {

        if (that.higherEducationInstitutionTypes[i] !== undefined && that.higherEducationInstitutionTypes[i] !== null) {
          if (role.id === that.higherEducationInstitutionTypes[i].id) {
            idx = i;
          }
        }

      }
      that.higherEducationInstitutionTypes.splice(idx, 1);
      that.propagateChange(that.higherEducationInstitutionTypes);
    });


  }

  onTouched = () => {
  };

  propagateChange = (_: any) => {
  };

  writeValue(higherEducationInstitutionTypes: HigherEducationInstitutionType[]): void {

    this.higherEducationInstitutionTypes = higherEducationInstitutionTypes;
    this.propagateChange(this.higherEducationInstitutionTypes);
    const that = this;
    that.select2.empty().trigger('change');
    if (this.higherEducationInstitutionTypes !== undefined && this.higherEducationInstitutionTypes !== null) {
      this.higherEducationInstitutionTypes.forEach(function (user) {
        if (user !== undefined && user !== null) {
          const option = new Option(user.name, JSON.stringify(user), true, true);
          that.select2.append(option);
        }

      });
      that.select2.trigger('change');
    }

  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  get value(): any {
    return this.higherEducationInstitutionTypes;
  }

  set value(v: any) {
    if (v !== this.higherEducationInstitutionTypes) {
      this.higherEducationInstitutionTypes = v;
      this.propagateChange(v);
    }
  }

}
