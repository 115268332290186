import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { FrequentlyAskedQuestion } from './frequently-asked-question';
import {CoreComponent} from '../../core/core.component';
import {NgForm} from '@angular/forms';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {FrequentlyAskedQuestionsService} from '../frequently-asked-questions.service';
import {ActivatedRoute} from '@angular/router';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
declare var $: any;
@Component({
  selector: 'app-frequently-asked-question',
  templateUrl: './frequently-asked-question.component.html',
  styleUrls: ['./frequently-asked-question.component.scss']
})
export class FrequentlyAskedQuestionComponent extends CoreComponent<FrequentlyAskedQuestion> {

  @Input() item: FrequentlyAskedQuestion = new FrequentlyAskedQuestion();
  validated= false;
  @ViewChild('ngForm', { static: false }) public ngForm: NgForm;
  @BlockUI() blockUI: NgBlockUI;
  constructor(protected servicesService: FrequentlyAskedQuestionsService, public route: ActivatedRoute,public activeModal: NgbActiveModal) {
    super(servicesService,route);
  }

  onSubmit() {

    if (!this.ngForm.valid) {
      this.validated = true;
      $('html, body').animate({
        scrollTop: 0
      }, 1000, function() {
      });
      return;
    } else {
      this.blockUI.start();
      this.servicesService.save(this.item).subscribe((item: FrequentlyAskedQuestion) => {
        this.blockUI.stop();
        this.activeModal.close();
        this.item = item;

      });
    }
  }
}
