import {Directive, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {School} from './school';
import {SchoolsService} from '../schools.service';
import {Results} from '../../../core/results';
import {Province} from '../../provinces/province/province';
declare var $: any;
@Directive({
  selector: '[appSchool]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SchoolDirective),
    multi: true
  }]
})
export class SchoolDirective implements ControlValueAccessor, OnInit {

  @Input() private school: School;
  @Input() private disabled = false;
  @Input() private province: Province;
  @Input() private title;
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  select2: any;

  constructor(private el: ElementRef, private service: SchoolsService) {
  }

  ngOnInit(): void {
    const that = this;
    this.select2 = $(this.el.nativeElement).select2({
      width: '100%',
      placeholder: that.title?that.title:'Select School',
      allowClear: true,
      ajax: {
        transport: function(params, success, failure) {
          const page = 1;
          const perPage = 10;

          if(that.province) {
            that.service.searchByType(page, perPage, 'ASC', 'name', params.data.term, that.province.id).subscribe((ret: Results<School>) => {
              success(ret);
            });
          } else {
            that.service.search(page, perPage, 'ASC', 'name', params.data.term).subscribe((ret: Results<School>) => {
              success(ret);
            });
          }


        },
        dataType: 'json',
        type: 'GET',
        processResults: function(data) {
          const arr = [];
          data.content.forEach(function(obj) {
            arr.push({
              id: JSON.stringify(obj),
              text: obj.name,
            });
          });
          return {
            results: arr
          };
        }
      }
    });
    $(this.el.nativeElement).on('select2:select', function(e) {

      that.school = JSON.parse(e.params.data.id);
      that.propagateChange(that.school);
      if(that.onSelect) {
        that.onSelect.emit();
      }

    });

    $(this.el.nativeElement).on('select2:unselect', function(e) {

      that.school = null;
      that.propagateChange(that.school);
      if(that.onSelect) {
        that.onSelect.emit();
      }
    });


  }

  onTouched = () => {
  };

  propagateChange = (_: any) => {
  };

  writeValue(school: School): void {

    this.school = school;
    this.propagateChange(this.school);
    const that = this;
    if (this.school !== undefined && this.school !== null) {
      const option = new Option(school.name, JSON.stringify(school), true, true);
      that.select2.append(option);
      that.select2.trigger('change');
    }else {
      const option = new Option('', '', true, true);
      that.select2.append(option);
      that.select2.trigger('change');
    }

  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  get value(): any {
    return this.school;
  }

  set value(v: any) {
    if (v !== this.school) {
      this.school = v;
      this.propagateChange(v);
    }
  }

}
