import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Application} from '../../applications/application/application';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable, ObservableInput} from 'rxjs/Observable';
import {Attachment} from '../../core/models';
import {CdkStepper} from '@angular/cdk/stepper';
import {Results} from '../../core/results';
import {TermCondition} from '../../admin/term-conditions/term-condition/term-condition';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {ApplicationsService} from '../../applications/applications.service';
import {TermConditionsService} from '../../admin/term-conditions/term-conditions.service';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {NgForm} from '@angular/forms';
import { ApplySubmittedComponent } from '../apply-submitted/apply-submitted.component';
declare var $: any;
@Component({
  selector: 'app-apply-terms',
  templateUrl: './apply-terms.component.html',
  styleUrls: ['./apply-terms.component.scss']
})
export class ApplyTermsComponent implements OnInit {
  agree: boolean;
  public validated: boolean;
  @BlockUI() blockUI: NgBlockUI;
  success: boolean;
  @ViewChild('termsCondition', {static: false})
  public termsCondition: NgForm;
  @Input()cdkStepper: CdkStepper;
  item: TermCondition;
  constructor(private modalService: NgbModal,
              private router: Router,
              public activeModal: NgbActiveModal,
              public applicationsService: ApplicationsService,
              protected servicesService: TermConditionsService,
              protected gaService: GoogleAnalyticsService,
              private matSnackBar: MatSnackBar) { }



  public save() {

    this.blockUI.start();
    this.applicationsService.save().subscribe((application: Application) => {
      this.applicationsService.process(application.id).subscribe((app: Application) => {
        this.blockUI.stop();
        this.applicationsService.application = application;
        this.applicationsService.submitted = true;
        this.applicationsService.application.reference = app.reference;
        this.modalService.dismissAll();
        const modalRef = this.modalService.open(ApplySubmittedComponent, {fullscreen: true, modalDialogClass: 'animate-bottom'});
        modalRef.componentInstance.name = 'World';
        modalRef.closed.subscribe(() => {
    });
      }, (error: HttpErrorResponse) => {
        this.blockUI.stop();
        this.gaService.exception(error.error?.message, true);
        if (error.status === 401 ) {
          this.router.navigate(['/']);
          this.matSnackBar.open('Your session has expired', 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }else  {
          this.matSnackBar.open(error.error.message, 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }

      });
    }, (err: HttpErrorResponse) => {
      this.blockUI.stop();
      this.matSnackBar.open(err.error.message, 'Okay', {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    });

  }

  public onSubmit() {

    if (!this.termsCondition.valid) {
      this.validated = true;
      $('html, body').animate({
        scrollTop: 0
      }, 1000, function() {
      });
      return;
    } else {
      const requests = new Array<ObservableInput<Attachment>>();
      if (this.applicationsService.application.disabled === true && this.applicationsService.application.disabilityAnnexure && this.applicationsService.application.disabilityAnnexure.file && (this.applicationsService.application.disabilityAnnexure.file instanceof Blob)) {
        requests.push(this.applicationsService.upload(this.applicationsService.application.disabilityAnnexure.file));
      }
      if (this.applicationsService.application.consentForm && this.applicationsService.application.consentForm.file && (this.applicationsService.application.consentForm.file instanceof Blob)) {
        requests.push(this.applicationsService.upload(this.applicationsService.application.consentForm.file));
      }
      if (this.applicationsService.application.provisionalOffer === true && this.applicationsService.application.provisionalOfferLetter && this.applicationsService.application.provisionalOfferLetter.file && (this.applicationsService.application.provisionalOfferLetter.file instanceof Blob)) {
        requests.push(this.applicationsService.upload(this.applicationsService.application.provisionalOfferLetter.file));
      }
      if (this.applicationsService.application.vulnerableChildOrNonSassDeclaration && this.applicationsService.application.vulnerableChildOrNonSassDeclaration.file && (this.applicationsService.application.vulnerableChildOrNonSassDeclaration.file instanceof Blob)) {
        requests.push(this.applicationsService.upload(this.applicationsService.application.vulnerableChildOrNonSassDeclaration.file));
      }
      if (this.applicationsService.application.spouse && this.applicationsService.application.spouse.requiredDocuments) {
        this.applicationsService.application.spouse.requiredDocuments.forEach((attachment: Attachment) => {
          if (attachment.file && attachment.file instanceof Blob) {
            requests.push(this.applicationsService.upload(attachment.file));
          }
        });
      }
      if (this.applicationsService.application.father && this.applicationsService.application.father.requiredDocuments) {
        this.applicationsService.application.father.requiredDocuments.forEach((attachment: Attachment) => {
          if (attachment.file && attachment.file instanceof Blob) {
            requests.push(this.applicationsService.upload(attachment.file));
          }
        });
      }
      if (this.applicationsService.application.mother && this.applicationsService.application.mother.requiredDocuments) {
        this.applicationsService.application.mother.requiredDocuments.forEach((attachment: Attachment) => {
          if (attachment.file && attachment.file instanceof Blob) {
            requests.push(this.applicationsService.upload(attachment.file));
          }
        });
      }
      if (this.applicationsService.application.guardian && this.applicationsService.application.guardian.requiredDocuments) {
        this.applicationsService.application.guardian.requiredDocuments.forEach((attachment: Attachment) => {
          if (attachment.file && attachment.file instanceof Blob) {
            requests.push(this.applicationsService.upload(attachment.file));
          }
        });
      }
      if(requests && requests.length >0) {
        this.blockUI.start();
        Observable.forkJoin(requests).subscribe((attachments: Array<Attachment>) => {
          let idx = 0;
          if (attachments) {
            if (this.applicationsService.application.disabled === true && this.applicationsService.application.disabilityAnnexure && this.applicationsService.application.disabilityAnnexure.file && (this.applicationsService.application.disabilityAnnexure.file instanceof Blob)) {
              if(attachments[idx]) {
                attachments[idx].documentType = this.applicationsService.application.disabilityAnnexure.documentType;
                attachments[idx].caption = this.applicationsService.application.disabilityAnnexure.caption;
                this.applicationsService.application.disabilityAnnexure = attachments[idx];
              }
              idx++;
            }
            if (this.applicationsService.application.consentForm && this.applicationsService.application.consentForm.file && (this.applicationsService.application.consentForm.file instanceof Blob)) {
              if(attachments[idx]) {
                attachments[idx].documentType = this.applicationsService.application.consentForm.documentType;
                attachments[idx].caption = this.applicationsService.application.consentForm.caption;
                this.applicationsService.application.consentForm = attachments[idx];
              }
              idx++;
            }
            if (this.applicationsService.application.provisionalOffer === true && this.applicationsService.application.provisionalOfferLetter && this.applicationsService.application.provisionalOfferLetter.file && (this.applicationsService.application.provisionalOfferLetter.file instanceof Blob)) {
              if(attachments[idx]) {
                attachments[idx].documentType = this.applicationsService.application.provisionalOfferLetter.documentType;
                attachments[idx].caption = this.applicationsService.application.provisionalOfferLetter.caption;
                this.applicationsService.application.provisionalOfferLetter = attachments[idx];
              }
              idx++;
            }
            if (this.applicationsService.application.vulnerableChildOrNonSassDeclaration  && this.applicationsService.application.vulnerableChildOrNonSassDeclaration.file && (this.applicationsService.application.vulnerableChildOrNonSassDeclaration.file instanceof Blob)) {
              if(attachments[idx]) {
                attachments[idx].documentType = this.applicationsService.application.vulnerableChildOrNonSassDeclaration.documentType;
                attachments[idx].caption = this.applicationsService.application.vulnerableChildOrNonSassDeclaration.caption;
                this.applicationsService.application.vulnerableChildOrNonSassDeclaration = attachments[idx];
              }
              idx++;
            }
            if (this.applicationsService.application.spouse && this.applicationsService.application.spouse.requiredDocuments) {
              let indx =0;
              this.applicationsService.application.spouse.requiredDocuments.forEach((attachment: Attachment) => {
                if (attachment.file && attachment.file instanceof Blob) {
                  if(attachments[idx]) {
                    attachments[idx].documentType = this.applicationsService.application.spouse.requiredDocuments[indx].documentType;
                    attachments[idx].caption = this.applicationsService.application.spouse.requiredDocuments[indx].caption;
                    this.applicationsService.application.spouse.requiredDocuments[indx] = attachments[idx];
                    indx++;
                  }
                  idx++;
                }
              });
            }
            if (this.applicationsService.application.father && this.applicationsService.application.father.requiredDocuments) {

              let indx =0;
              this.applicationsService.application.father.requiredDocuments.forEach((attachment: Attachment) => {
                if (attachment.file && attachment.file instanceof Blob) {
                  if(attachments[idx]) {
                    attachments[idx].documentType = this.applicationsService.application.father.requiredDocuments[indx].documentType;
                    attachments[idx].caption = this.applicationsService.application.father.requiredDocuments[indx].caption;
                    this.applicationsService.application.father.requiredDocuments[indx] = attachments[idx];
                    indx++;
                  }
                  idx++;
                }
              });
            }
            if (this.applicationsService.application.mother && this.applicationsService.application.mother.requiredDocuments) {
              let indx =0;
              this.applicationsService.application.mother.requiredDocuments.forEach((attachment: Attachment) => {
                if (attachment.file && attachment.file instanceof Blob) {
                  if(attachments[idx]) {
                    attachments[idx].documentType = this.applicationsService.application.mother.requiredDocuments[indx].documentType;
                    attachments[idx].caption = this.applicationsService.application.mother.requiredDocuments[indx].caption;
                    this.applicationsService.application.mother.requiredDocuments[indx] = attachments[idx];
                    indx++;
                  }
                  idx++;
                }
              });
            }
            if (this.applicationsService.application.guardian && this.applicationsService.application.guardian.requiredDocuments) {

              let indx =0;
              this.applicationsService.application.guardian.requiredDocuments.forEach((attachment: Attachment) => {
                if (attachment.file && attachment.file instanceof Blob) {
                  if(attachments[idx]) {
                    attachments[idx].documentType = this.applicationsService.application.guardian.requiredDocuments[indx].documentType;
                    attachments[idx].caption = this.applicationsService.application.guardian.requiredDocuments[indx].caption;
                    this.applicationsService.application.guardian.requiredDocuments[indx] = attachments[idx];
                    indx++;
                  }
                  idx++;
                }
              });
            }
            this.save();
          } else {
            this.save();
          }
          this.blockUI.stop();
        }, (error: HttpErrorResponse) => {
          this.blockUI.stop();
          this.gaService.exception(error.error?.message, true);
          if (error.status === 401 ) {
            this.router.navigate(['/']);
            this.matSnackBar.open('Your session has expired', 'Okay', {
              duration: 5000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          }else {
            this.blockUI.stop();
            this.gaService.exception(error.error?.message, true);
            this.matSnackBar.open(error.error.message, 'Okay', {
              duration: 5000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          }
        });
      } else {
        this.save();
      }
    }
  }

  public ngOnInit(): void {

    this.blockUI.start('Loading');
    this.servicesService.searchByType(1, 1, 'DESC', 'createDate', '', 'SubmitApplication').subscribe((results: Results<TermCondition>) => {
      this.blockUI.stop();
      if (results.numberOfElements > 0) {
        this.item = results.content[0];
      }
    }, (error: HttpErrorResponse) => {
      this.blockUI.stop();
      this.gaService.exception(error.error?.message, true);
      if (error.status === 401 ) {
        this.router.navigate(['/']);
        this.matSnackBar.open('Your session has expired', 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }else {
        this.matSnackBar.open(error.error.message, 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }
    });

  }



  closeModal() {
    this.activeModal.close();
  }
}
