import {Injectable} from '@angular/core';
import {ServicesService} from '../../users/services.service';
import {Application} from '../../applications/application/application';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ConfigServices} from '../../core/config';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {User} from '../users/user/user';
import {Case} from './case';
import {RequestDocument} from './request-document';
import {StudyTerm} from '../../admin-config/study-terms/study-term/study-term';

declare var moment: any;

@Injectable({
  providedIn: 'root'
})
export class CaseManagementService extends ServicesService {


  application: Application = new Application();

  constructor(public http: HttpClient, public configService: ConfigServices, public cookieService: CookieService, private router: Router) {
    super('admin/case-management', http, configService);
  }

  public allocate(item: User, numberToAllocate: number) {
    const url = this.configService.getApi() + this.type;
    return this.http.post(url + '/allocate/' + numberToAllocate, item, {withCredentials: true});
  }
  public getCaseStatusCount(year: number,studyTerm: StudyTerm) {
    const url = this.configService.getApi() + this.type;
    let httpParams = new HttpParams();
    httpParams = httpParams.set('year', year);
    if(studyTerm != null) {
      httpParams = httpParams.set('studyTerm', studyTerm.id);
    }
    return this.http.get(url + '/case-status-count', {params: httpParams, withCredentials: true});
  }

  public getCaseAssignedCount(year: number,studyTerm: StudyTerm) {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('year', year);
    if(studyTerm != null) {
      httpParams = httpParams.set('studyTerm', studyTerm.id);
    }
    const url = this.configService.getApi() + this.type;
    return this.http.get(url + '/case-assigned-count', {params: httpParams,withCredentials: true});
  }
  // public getAllocation(date: Date,firstName:string) {

  //   if (firstName === undefined) {
  //     firstName = '';
  //   }

  //   let httpParams = new HttpParams();
  //   httpParams = httpParams.set('firstName', firstName);
  //   const url = this.configService.getApi() + this.type;
  //   return this.http.get(url + '/cases/allocation/' + moment(date).format('yyyy-MM-D'), {params: httpParams,withCredentials: true});

  // }


  public downloadWorkAllocation(date: Date,user:User,roles: Array<string> ) {
    let httpParams = new HttpParams();
    if(user) {
    httpParams = httpParams.set('userId', user.id);
   }
    if(roles) {
      httpParams = httpParams.set('userRoles', roles.join(', '));
    }

    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Accept', 'text/csv');
    const url = this.configService.getApi() + this.type + '/cases/download-work-allocation/' + moment(date).format('yyyy-MM-D');
    return this.http.get(url, {params: httpParams, responseType: 'blob'});

  }
  public downloadHourlyProgress(date: Date,user:User, roles: Array<string>) {
    let httpParams = new HttpParams();
    if(user) {
    httpParams = httpParams.set('userId', user.id);
   }
    if(roles) {
      httpParams = httpParams.set('userRoles', roles.join(', '));
    }

    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Accept', 'text/csv');
    const url = this.configService.getApi() + this.type + '/cases/download-hourly-progress/'+ moment(date).format('yyyy-MM-DD');
    return this.http.get(url, {params: httpParams, responseType: 'blob'});

  }

  public downloadProductivity(date: Date,user:User, roles: Array<string>) {
    let httpParams = new HttpParams();
    if(user) {
    httpParams = httpParams.set('userId', user.id);
   }
    if(roles) {
      httpParams = httpParams.set('userRoles', roles.join(', '));
    }

    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Accept', 'text/csv');
    const url = this.configService.getApi() + this.type + '/cases/download-productivity/'+ moment(date).format('yyyy-MM-D');
    return this.http.get(url, {params: httpParams, responseType: 'blob'});

  }
  public update(application: Application) {
    const url = this.configService.getApi() + this.type;
    return this.http.post(url + '/cases/application',application, {withCredentials: true});
  }

  public getCases(application: Application) {
    const url = this.configService.getApi() + this.type;
    return this.http.get(url + '/cases/application/'+application.id, {withCredentials: true});
  }


  public getAllocation(date: Date,page: number, perPage: number, sortOrder: string, sortField: string, user: User) {
    console.log('Checking type:::', this.type, user)
    let httpParams = new HttpParams();
    if(user && user.id) {
      httpParams = httpParams.set('userId', user.id);
    }
    const url = this.configService.getApi() + this.type + '/cases/allocation/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField +'/' + moment(date).format('yyyy-MM-DD');
    return this.http.get(url, {params: httpParams,withCredentials: true});
  }

  public getAllocationByRole(date: Date,page: number, perPage: number, sortOrder: string, sortField: string, user: User, roles: Array<string>) {
    let httpParams = new HttpParams();
    if(user) {
      httpParams = httpParams.set('userId', user.id);
     }
     if(roles) {
      httpParams = httpParams.set('userRoles', roles.join(', '));
     }
    const url = this.configService.getApi() + this.type + '/cases/allocation/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField +'/' + moment(date).format('yyyy-MM-DD');
    return this.http.get(url, {params: httpParams,withCredentials: true});
  }

  public getProductivity(date: Date,page: number, perPage: number, sortOrder: string, sortField: string, user: User) {
    let httpParams = new HttpParams();
    if(user && user.id) {
      httpParams = httpParams.set('userId', user.id);
    }

    const url = this.configService.getApi() + this.type + '/cases/productivity/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField +'/' + moment(date).format('yyyy-MM-DD');
    return this.http.get(url, {params: httpParams,withCredentials: true});
  }

  public getProductivityByRole(date: Date,page: number, perPage: number, sortOrder: string, sortField: string, user: User,roles: Array<string>) {
    let httpParams = new HttpParams();

      httpParams = httpParams.set('userRoles',  roles.join(', '));

    const url = this.configService.getApi() + this.type + '/cases/productivity/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField +'/' + moment(date).format('yyyy-MM-DD');
    return this.http.get(url, {params: httpParams,withCredentials: true});
  }
  public getHourlyProgress(date: Date,page: number, perPage: number, sortOrder: string, sortField: string, user: User) {
    let httpParams = new HttpParams();
    if(user && user.id) {
      httpParams = httpParams.set('userId', user.id);
    }
    const url = this.configService.getApi() + this.type + '/cases/hourlyprogress/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField +'/' + moment(date).format('yyyy-MM-DD');
    return this.http.get(url, {params: httpParams,withCredentials: true});
  }

  public getHourlyProgressByRole(date: Date,page: number, perPage: number, sortOrder: string, sortField: string, user: User,roles: Array<string>) {
    let httpParams = new HttpParams();

      httpParams = httpParams.set('userRoles', roles.join(', '));

    const url = this.configService.getApi() + this.type + '/cases/hourlyprogress/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField +'/' + moment(date).format('yyyy-MM-DD');
    return this.http.get(url, {params: httpParams,withCredentials: true});
  }


  public getAllocated(userId: string, status: string) {
    const url = this.configService.getApi() + this.type + '/allocated/' + userId + '/' + status;
    return this.http.get(url, {withCredentials: true});

  }

  public getDetails(id: string) {
    const url = this.configService.getApi() + this.type + '/cases/' + id + '/details';
    return this.http.get(url, {withCredentials: true});

  }

  public approve(id: string) {
    const url = this.configService.getApi() + this.type + '/cases/approve/';
    return this.http.post(url, null, {withCredentials: true});

  }

  public assign(id: string, userId: string) {
    const url = this.configService.getApi() + this.type + '/cases/assign/' + id + '/' + userId;
    return this.http.post(url, null, {withCredentials: true});

  }

  public unassign(id: string) {
    const url = this.configService.getApi() + this.type + '/cases/unassign/' + id;
    return this.http.post(url, null, {withCredentials: true});

  }


  public getEvaluated(userId: string) {
    const url = this.configService.getApi() + this.type + '/cases/evaluated/' + userId + '/cases';
    return this.http.post(url, null, {withCredentials: true});

  }

  public getEvaluatedCount(userId: string) {
    const url = this.configService.getApi() + this.type + '/cases/evaluated/' + userId + '/count';
    return this.http.post(url, null, {withCredentials: true});

  }

  public requestDocuments(requestDocument: RequestDocument) {
    const url = this.configService.getApi() + this.type + '/cases/request-documents';
    return this.http.post(url, requestDocument, {withCredentials: true});

  }

  public revokeCase(caseId: string) {
    const url = this.configService.getApi() + this.type + '/case/revoke/' + caseId;
    return this.http.post(url, null, {withCredentials: true});

  }
  public revoke(caseId: string) {
    const url = this.configService.getApi() + this.type + '/cases/revoke/' + caseId;
    return this.http.post(url, null, {withCredentials: true});

  }

  public authorizeRevoke(caseId: string) {
    const url = this.configService.getApi() + this.type + '/cases/revoke/authorize/' + caseId;
    return this.http.post(url, null, {withCredentials: true});

  }

  public searchByStatus(page: number, perPage: number, sortOrder: string, sortField: string, keyword: string, status: string, year: string, studyTerm: StudyTerm, date: Date) {

    if (keyword === undefined) {
      keyword = '';
    }
    let httpParams = new HttpParams();
    httpParams = httpParams.set('keyword', keyword);
    httpParams = httpParams.set('year', year);
    if(studyTerm) {
      httpParams = httpParams.set('cycle', studyTerm.id);
    }
    if(date) {
      httpParams = httpParams.set('date', moment(date).format('yyyy-MM-D'));
    }
    const url = this.configService.getApi() + this.type + '/status/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField + '/' + status;
    return this.http.get(url, {params: httpParams, withCredentials: true});

  }

  public unallocate(item: User) {
    const url = this.configService.getApi() + this.type;
    return this.http.post(url + '/unallocate', item, {withCredentials: true});
  }

  public unallocateAll() {
    const url = this.configService.getApi() + this.type;
    return this.http.get(url + '/unallocate-all', {withCredentials: true});
  }

  public approveCase(id: string) {
    const url = this.configService.getApi() + this.type;
    return this.http.post(url + '/cases/approve/' + id, null, {withCredentials: true});
  }

  public rejectCase(id: string) {
    const url = this.configService.getApi() + this.type;
    return this.http.post(url + '/cases/reject/' + id, null, {withCredentials: true});
  }

  public rejectCaseWithReason(caseId: string, caseReasonId: string) {
    const url = this.configService.getApi() + this.type;
    return this.http.post(url + '/cases/reject/' + caseId +'/'+ caseReasonId, null, {withCredentials: true});
  }

  public getUnassignedCases(page: number, perPage: number, sortOrder: string, sortField: string, keyword: string) {

    if (keyword === undefined) {
      keyword = '';
    }

    let httpParams = new HttpParams();
    httpParams = httpParams.set('keyword', keyword);
    const url = this.configService.getApi() + this.type + '/unassigned/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField;
    return this.http.get(url, {params: httpParams,withCredentials: true});

  }

  public getAllCases(page: number, perPage: number, sortOrder: string, sortField: string, keyword: string, status: string, year: string, studyTerm: StudyTerm, date: Date){

    if (keyword === undefined) {
      keyword = '';
    }
    let httpParams = new HttpParams();
    httpParams = httpParams.set('keyword', keyword);
    httpParams = httpParams.set('year', year);
    if(studyTerm) {
      httpParams = httpParams.set('cycle', studyTerm.id);
    }
    if(date) {
      httpParams = httpParams.set('date', moment(date).format('yyyy-MM-D'));
    }
   // httpParams = httpParams.set('keyword', keyword);
    const url = this.configService.getApi() + this.type + '/view-all-case/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField ;
    return this.http.get(url, {params: httpParams,withCredentials: true});
  }

  public getDailyCases(date: Date,page: number, perPage: number, sortOrder: string, sortField: string, user: User) {
    console.log('Checking type:::', this.type, user)
    let httpParams = new HttpParams();
    if(user && user.id) {
      httpParams = httpParams.set('userId', user.id);
    }
    const url = this.configService.getApi() + this.type + '/cases/allocation/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField +'/' + moment(date).format('yyyy-MM-DD');
    return this.http.get(url, {params: httpParams,withCredentials: true});
  }

  public downloadDailyCases(userRole:string,userId:any) {
    let httpParams = new HttpParams();
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Accept', 'text/csv');
    const url = this.configService.getApi() + this.type + '/cases/download-daily-progress/'+ userRole + '/' + userId  ;
    return this.http.get(url, {params: httpParams, responseType: 'blob'});

  }
}
