import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {User} from '../../admin/users/user/user';
import {StudyStep} from '../../core/models/study';
import {NgForm} from '@angular/forms';
import {CdkStepper} from '@angular/cdk/stepper';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {HttpErrorResponse} from '@angular/common/http';
import {UsersService} from '../../users/users.service';
import {CookieService} from 'ngx-cookie-service';
import {ApplicationsService} from '../../applications/applications.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ConfigServices} from '../../core/config';
import {Router} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';
import {AssistedService} from '../../applications/assisted.service';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
declare var $: any;
@Component({
  selector: 'app-apply-laptop',
  templateUrl: './apply-laptop.component.html',
  styleUrls: ['./apply-laptop.component.scss']
})
export class ApplyLaptopComponent implements OnInit {
  profile: User;
  success = false
  public study: StudyStep;

  @Input() kinType: string;
  @ViewChild('kinForm', {static: false})
  public kinForm: NgForm;
  public validated: boolean;
  @Input() cdkStepper: CdkStepper;
  @BlockUI() blockUI: NgBlockUI;
  constructor(public activeModal: NgbActiveModal, protected assistedService: AssistedService, protected service: UsersService, public cookieService: CookieService,
              public configServices: ConfigServices,private router: Router,public meta: Meta, public title: Title,
              protected gaService: GoogleAnalyticsService,
              public applicationsService: ApplicationsService, public usersService: UsersService, private matSnackBar: MatSnackBar) {
    this.title.setTitle('Apply Laptop');
  }

  ngOnInit() {
  }

  previous() {
    this.cdkStepper.previous();
  }

  normal() {
    this.blockUI.start();
    this.applicationsService.save().subscribe((user: User)=>{
      this.blockUI.stop();
      this.cdkStepper.next();
    }, (error: HttpErrorResponse) => {
      this.blockUI.stop();
      this.gaService.exception(error.error?.message, true);
      if(error.status === 400) {
        this.cdkStepper.next();
      }else if (error.status === 401 ) {
        this.router.navigate(['/']);
        this.matSnackBar.open('Your session has expired', 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }else if (error.status !== 400) {
        this.matSnackBar.open(error.error.message, 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }
    });
  }

  dataCapture() {
    this.blockUI.start();
    this.assistedService.save().subscribe((user: User)=>{
      this.blockUI.stop();
      this.cdkStepper.next();
    }, (error: HttpErrorResponse) => {
      this.blockUI.stop();
      this.gaService.exception(error.error?.message, true);
      if(error.status === 400) {
        this.cdkStepper.next();
      }else if (error.status === 401 ) {
        this.router.navigate(['/']);
        this.matSnackBar.open('Your session has expired', 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }else if (error.status !== 400) {
        this.matSnackBar.open(error.error.message, 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }
    });
  }
  onSubmit() {

    if (!this.kinForm.valid) {
      this.validated = true;
      $('html, body').animate({
        scrollTop: 0
      }, 1000, function() {
      });
      return;
    } else {
      if(this.assistedService.idNumber) {
        this.dataCapture();
      }else {
        this.normal();
      }
    }
  }

}
