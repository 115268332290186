import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {CoreComponent} from '../../../core/core.component';
import {NgForm} from '@angular/forms';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UsersService} from '../../../users/users.service';
import {User} from './user';
import {HttpErrorResponse} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Meta, Title} from '@angular/platform-browser';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
declare var $: any;
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent  extends CoreComponent<User> {

  @Input() item: User = new User();
  user: User;
  role: string ='CaseTeamLead';
  validated= false;
  @ViewChild('ngForm', { static: false }) public ngForm: NgForm;
  @BlockUI() blockUI: NgBlockUI;
  constructor(protected servicesService: UsersService, public route: ActivatedRoute,private router: Router,public meta: Meta, public title: Title,
              public activeModal: NgbActiveModal, private matSnackBar: MatSnackBar, private modalService: NgbModal,protected gaService: GoogleAnalyticsService) {
    super(servicesService,route);
    this.title.setTitle('User');
  }

  public ngOnInit(): void {

    if(this.item.role ==='CaseWorker') {
        this.role = 'CaseTeamLead';
    }else if(this.item.role ==='CaseQualityAssurance') {
      this.role = 'CaseTeamLead';
    }else if(this.item.role ==='AppealWorker') {
      this.role = 'AppealTeamLead';
    }else if(this.item.role ==='AppealTeamLead') {
      this.role = 'AppealManager';
    }

  }

  onSubmit() {

    if (!this.ngForm.valid) {
      this.validated = true;
      $('html, body').animate({
        scrollTop: 0
      }, 1000, function() {
      });
      return;
    } else {
      this.blockUI.start();
      this.servicesService.save(this.item).subscribe((item: User) => {
        this.blockUI.stop();
        this.activeModal.close();
        this.item = item;
      }, (error: HttpErrorResponse) => {
        this.blockUI.stop();
        this.gaService.exception(error.error?.message, true);
        if (error.status === 401) {
          this.activeModal.close();
          this.router.navigate(['/']);
          this.matSnackBar.open('Your session has expired', 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }else {
          this.blockUI.stop();
          this.matSnackBar.open(error.error.message, 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }
      });
    }

  }

}
