<app-card>
  <h2 class="mb-4 text-center">Disability Change Request</h2>
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs justify-content-center">

    <li [ngbNavItem]="1">
      <a ngbNavLink>Request Details</a>
      <ng-template ngbNavContent>
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">

                <div class="row">
                  <p class="notif-body mb-0">Current Disability Status</p>
                  <p class="mb-1 text-muted">
                    {{disabilityChangeRequest?.from}}
                  </p>
                </div>
                <div class="row">
                  <p class="notif-body mb-0">Requested Disability Status</p>
                  <p class="mb-1 text-muted">
                    {{disabilityChangeRequest?.to}}
                  </p>
                </div>


                <div class="row">
                  <p class="notif-body mb-0">Application Ref#</p>
                  <p class="mb-1 text-muted">
                    {{application?.reference}}
                  </p>
                </div>
                <div class="row">
                  <p class="notif-body mb-0">Applicant Name</p>
                  <p class="mb-1 text-muted">
                    {{application?.user?.firstName}} {{application?.user?.lastName}}
                  </p>
                </div>
                <div class="row">
                  <p class="notif-body mb-0">Application date</p>
                  <p class="mb-1 text-muted">
                    {{application?.createDate | date : 'dd MMMM yyyy'}}
                  </p>
                </div>
                <div class="row">
                  <p class="notif-body mb-0">Funded</p>
                  <p class="mb-1 text-muted">
                    {{(application?.status==='ProvisionallyFunded' || application?.status==='RegistrationReceived')?'Yes':'No'}}
                  </p>
                </div>

                <div class="row" *ngIf="application?.rejectReason || application?.rejectReasons">
                  <p class="notif-body mb-0">Application Rejection Reason</p>
                  <!--        <p class="mb-1 text-muted" *ngIf="application.rejectReason">-->
                  <!--          {{application.rejectReason.description}}-->
                  <!--        </p>-->

                  <p class="mb-1 text-muted" *ngFor="let rejectReason of application?.rejectReasons">
                    {{rejectReason?.description}}
                  </p>
                </div>

              </div>
              <div class="col-md-6" *ngIf="disabilityChangeRequest.disabilityAnnexure">
                <div class="row">
                  <embed [src]="disabilityChangeRequest.disabilityAnnexure.url"  [type]="disabilityChangeRequest?.disabilityAnnexure.contentType"  style="width:100%;height:70vh">
                </div>
                </div>

              </div>
            </div>
        </div>

      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink>Application History</a>
      <ng-template ngbNavContent>
        <app-application-histroy [application]="application"></app-application-histroy>
      </ng-template>
    </li>

    <li [ngbNavItem]="3">
      <a ngbNavLink>Appeal History</a>
      <ng-template ngbNavContent>
        <app-appeal-histroy [application]="application"></app-appeal-histroy>
      </ng-template>
    </li>

    <li [ngbNavItem]="4">
      <a ngbNavLink>Cases</a>
      <ng-template ngbNavContent>
        <app-case-history [application]="application"></app-case-history>
      </ng-template>
    </li>

  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</app-card>
