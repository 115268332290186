import { Injectable } from '@angular/core';
import {ServicesService} from '../../users/services.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ConfigServices} from '../../core/config';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class StudyTermsService extends ServicesService {

  constructor(public http: HttpClient, public configService: ConfigServices, public cookieService: CookieService, private router: Router) {
    super('study-terms', http, configService);
  }

  public searchByType(page: number, perPage: number, sortOrder: string, sortField: string, keyword: string, type: string) {

    if (keyword === undefined) {
      keyword = '';
    }

    let httpParams = new HttpParams();
    httpParams = httpParams.set('keyword', keyword);
    const url = this.configService.getApi() + this.type + '/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField + '/' + type;
    return this.http.get(url, {params: httpParams, withCredentials: true});

  }

  public searchByTypeOpened(page: number, perPage: number, sortOrder: string, sortField: string, keyword: string, type: string) {

    if (keyword === undefined) {
      keyword = '';
    }

    let httpParams = new HttpParams();
    httpParams = httpParams.set('keyword', keyword);
    const url = this.configService.getApi() + this.type + '/opened/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField + '/' + type;
    return this.http.get(url, {params: httpParams, withCredentials: true});

  }

  public searchOpened(page: number, perPage: number, sortOrder: string, sortField: string, keyword: string) {

    if (keyword === undefined) {
      keyword = '';
    }

    let httpParams = new HttpParams();
    httpParams = httpParams.set('keyword', keyword);
    const url = this.configService.getApi() + this.type + '/opened/' + page + '/' + perPage + '/' + sortOrder + '/' + sortField;
    return this.http.get(url, {params: httpParams, withCredentials: true});

  }
}
