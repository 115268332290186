<app-card>
<div class ="container justify-content-start">
  <div  class ="row" style="text-align: center; ">
    <div class="col-md-12">
      <h4><b>What would you prefer to apply for?</b> </h4>
      <br/>
      <br/>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <div class="d-grid gap-2">

        <button class="btn btn-sm mb-3" [ngClass]="applicationService.application.fundingBucket==='BURSARY'?'btn-primary':'btn-outline-dark'" type="button" (click)="applicationService.application.fundingBucket='BURSARY'">I want to apply for a bursary</button>
      </div>


    </div>
    <div class="col-md-6">
      <div class="d-grid gap-2">
        <button class="btn btn-sm mb-3" [disabled]="applicationService.application.sassaFunded" [ngClass]="applicationService.application.fundingBucket==='LOAN'?'btn-primary':'btn-outline-dark'" type="button" (click)="applicationService.application.fundingBucket='LOAN'" >I want to apply for a loan</button>

      </div>

    </div>

  </div>

  <div class="row">
    <div class="col-md-12">
    <br/>
    <br/>
    Loan applicants, please note the following criteria:
      <ul>
        <li>
          Annual household income above R350 000 and less than or equal to R600 000
        </li>
        <li>
          Accepted to study at either a TVET College or public university for either under- or postgraduate studies
        </li>
        <li>
          Can apply in years 1, 2, 3 or 4
        </li>
        <li>
          Must sign a loan agreement if they qualify for a loan
        </li>
      </ul>
      <p>Bursary applicants, please note that, amongst other criteria, your household income must be less than or equal to R350 000.
         For disabled students, your household income must be less than or equal to R600 000.</p>
         
        <p>Bursary applicants, who are not disabled, and have been rejected for a bursary based on a
           household income exceeding R350 000, will be allowed to apply for a loan if their family income is less than or equal to R600 000. </p>
      <br/>
      <br/>
  </div>
  </div>

  <div class="row justify-content-center">

    <br/>
    <br/>
    <div class="col-md-4">
      <div class="d-grid gap-2">
        <button class="btn btn-outline-dark btn-md mb-3" type="button" (click)="close()">Cancel</button>
      </div>

    </div>
    <div class="col-md-4">
      <div class="d-grid">

        <button class="btn btn-primary btn-sm mb-6" [disabled]="!applicationService.application.fundingBucket"  type="button" (click)="nbaDocuments()">Continue</button>
      </div>

    </div>

  </div>
</div>

</app-card>
