import {Application, Spouse} from '../../applications/application/application';
import {AppealReason} from '../../appeal-reasons/appeal-reason/appeal-reason';
import {User} from '../../admin/users/user/user';
import {WithdrawReason} from '../../admin-config/withdraw-reasons/withdraw-reason/withdraw-reason';
import {Attachment} from '../../core/models';
import {RequestedDocuments} from '../../admin/case-management/requested-documents';
import {AppealReopenReason} from '../../admin-config/appeal-reopen-reasons/appeal-reopen-reason/appeal-reopen-reason';
import {AppealReprocessReason} from '../../admin-config/appeal-reprocess-reasons/appeal-reprocess-reason/appeal-reprocess-reason';
import {AppealRejectReason} from '../../admin-config/appeal-reject-reasons/appeal-reject-reason/appeal-reject-reason';
import {
  RejectAppealReprocessReason
} from '../../admin-config/reject-appeal-reprocess-reasons/reject-appeal-reprocess-reason/reject-appeal-reprocess-reason';

export class Appeal {
  id: string;
  createDate: Date;
  application: Application;
  appealReason: AppealReason;
  supportingDocuments: Array<Attachment>;
  withdrawReason: WithdrawReason;
  requestedDocuments: RequestedDocuments;
  submittedDocuments: RequestedDocuments;
  status:string;
  user: User;
  canWithdraw: boolean;
  canReOpenWithdrawn: boolean;
  canRequestReprocess: boolean;
  married: boolean;
  father: Spouse;
  saFather: boolean;
  mother: Spouse;
  saMother: boolean;
  guardian: Spouse;
  saGuardian: boolean;
  spouse: Spouse;
  saSpouse: boolean;
  fatherDetails: boolean;
  motherDetails: boolean;
  guardianDetails: boolean;
  appealReOpenReason: AppealReopenReason;
  appealReProcessReason: AppealReprocessReason;
  appealRejectReason: AppealRejectReason;
  rejectAppealReProcessReason: RejectAppealReprocessReason;
  constructor() {
    this.spouse = new Spouse();
    this.father = new Spouse();
    this.mother = new Spouse();
    this.guardian = new Spouse();
  }
}
