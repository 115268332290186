import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class FilenameUtilsService {

  constructor() { }

  sanitiseFilename(filename: string): string {
    if (filename === null || filename === undefined) {
      return "";
    }

    // Define a regular expression to match invalid characters
    const invalidCharsRegex = /[<>:"/\\|?*\[\]\x00-\x1F]/g;

    // Replace invalid characters with a blank
    const sanitisedFilename = filename.replace(invalidCharsRegex, '');

    return sanitisedFilename;
  }

}
