import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class Utils {
    constructor() {}

    static replaceAll(str: string, search: string, replace: string): string {
        if (str.includes(search)) {
            return this.replaceAll(str.replace(search, replace), search, replace);
        }
        return str;
    }
}