import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApplyInstitutionComponent} from 'src/app/apply/apply-institution/apply-institution.component';
import {ApplyComponent} from '../../apply/apply.component';
import {ApplicationsService} from '../../applications/applications.service';
import {Application} from '../../applications/application/application';
import {UsersService} from '../../users/users.service';
import {User} from '../../admin/users/user/user';

@Component({
  selector: 'app-nba',
  templateUrl: './nba.component.html',
  styleUrls: ['./nba.component.scss']
})
export class NbaComponent implements OnInit {

  universityTC = false;
  tvetTC = false;
  loanTC = false;

  constructor(public applicationServices: ApplicationsService, private router: Router,
              private modalService: NgbModal,
              public usersService: UsersService) {
  }

  ngOnInit(): void {

  }

  readMore() {
    let ele = document.getElementById('text');
    ele.classList.add('fullHeight');
  }

  readLess() {
    let ele = document.getElementById('text');
    ele.classList.remove('fullHeight');
  }

  readTvetMore() {
    let ele = document.getElementById('tvetText');
    ele.classList.add('fullHeight');
  }

  readTvetLess() {
    let ele = document.getElementById('tvetText');
    ele.classList.remove('fullHeight');
  }

  close() {
    this.modalService.dismissAll();
  }

  openApply() {
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(ApplyComponent, {fullscreen: true, modalDialogClass: 'animate-bottom'});
    modalRef.componentInstance.name = 'World';
    modalRef.closed.subscribe(() => {
      window.location.reload();
    });

  }
}
