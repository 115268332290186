import { Directive, ElementRef, Input, OnInit, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { CaseRejectReasonService } from "./case-reject-reasons.service";
import { CaseRejectReason } from "./case-reject-reason";
import { Results } from "src/app/core/results";
declare var $: any;
@Directive({
  selector: '[appCaseRejectReason]',
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CaseRejectReasonDirective),
    multi: true
  }]
})

export class CaseRejectReasonDirective implements ControlValueAccessor, OnInit {

@Input() private caseRejectReason: CaseRejectReason;
@Input() private disabled = false;
  select2: any;
  constructor(private el: ElementRef, private service: CaseRejectReasonService) {
  }


  ngOnInit(): void {
    const that = this;
    this.select2 = $(this.el.nativeElement).select2({
      width: '100%',
      placeholder: 'Reject Reason',
      allowClear: true,
      ajax: {
        transport: function(params, success, failure) {
          const page = 1;
          const perPage = 10;
          that.service.search(page, perPage, 'ASC', 'name', params.data.term).subscribe((ret: Results<CaseRejectReason>) => {
            success(ret);
          });

        },
        dataType: 'json',
        type: 'GET',
        processResults: function(data) {
          const arr = [];
          data.content.forEach(function(obj) {
            arr.push({
              id: JSON.stringify(obj),
              text: obj.name,
            });
          });
          return {
            results: arr
          };
        }
      }
    });
    $(this.el.nativeElement).on('select2:select', function(e) {

      that.caseRejectReason = JSON.parse(e.params.data.id);
      that.propagateChange(that.caseRejectReason);

    });

    $(this.el.nativeElement).on('select2:unselect', function(e) {

      that.caseRejectReason = null;
      that.propagateChange(that.caseRejectReason);
    });


  }

  onTouched = () => {
  };

  propagateChange = (_: any) => {
  };

  writeValue(caseRejectReason: CaseRejectReason): void {

    this.caseRejectReason = caseRejectReason;
    this.propagateChange(this.caseRejectReason);
    const that = this;
    if (this.caseRejectReason !== undefined && this.caseRejectReason !== null) {
      const option = new Option(caseRejectReason.name, JSON.stringify(caseRejectReason), true, true);
      that.select2.append(option);
      that.select2.trigger('change');
    }

  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  get value(): any {
    return this.caseRejectReason;
  }

  set value(v: any) {
    if (v !== this.caseRejectReason) {
      this.caseRejectReason = v;
      this.propagateChange(v);
    }
  }

}
