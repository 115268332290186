export class LoginBean {
  public password: string;
  public username: string;
  public message: string;
  public key: string;
  public robot: string;
  public otp: string;
  public contactNumber:string;

}
