import {Component, Input, OnInit} from '@angular/core';
import {Application} from '../../applications/application/application';
import {DisabilityChangeRequest} from '../disability-change-request/disability-change-request';
import {Attachment} from '../../core/models';
import {ConfigServices} from '../../core/config';
import {DomSanitizer} from '@angular/platform-browser';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {FilenameUtilsService} from '../../utils/filename-utils.service';
import {BiometricsConfirmationComponent} from '../../profile/student-registation/biometrics-confirmation/biometrics-confirmation.component';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {DisabilityChangeRequestsService} from '../disability-change-requests.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';

declare var bootbox: any;

@Component({
  selector: 'app-disability-change-request-check',
  templateUrl: './disability-change-request-check.component.html',
  styleUrls: ['./disability-change-request-check.component.scss']
})
export class DisabilityChangeRequestCheckComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  active = 1;
  @Input() public application: Application;
  @Input() public disabilityChangeRequest: DisabilityChangeRequest;

  constructor(public configServices: ConfigServices, public sanitizer: DomSanitizer,
              public disabilityChangeRequestsService: DisabilityChangeRequestsService,
              public http: HttpClient, private filenameUtilsService: FilenameUtilsService,
              public modalService: NgbModal, public activeModal: NgbActiveModal,
              protected gaService: GoogleAnalyticsService, public matSnackBar: MatSnackBar, private router: Router) {
  }


  ngOnInit(): void {
    if (this.disabilityChangeRequest?.disabilityAnnexure) {
      this.getURL(this.disabilityChangeRequest.disabilityAnnexure);
    }
  }

  getURL(attachment: Attachment) {
    const url = this.configServices.getApi() + 'file/' + attachment.id + '/' + this.sanitiseFilename(attachment.name);
    this.http.get(url, {observe: 'response', responseType: 'blob'}).subscribe((res) => {
      const objectURL = URL.createObjectURL(res.body);
      const image = this.sanitizer.bypassSecurityTrustResourceUrl(objectURL);
      attachment.url = image;
    });
  }

  sanitiseFilename(filename: string): string {
    return this.filenameUtilsService.sanitiseFilename(filename);
  }


}
