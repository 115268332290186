export enum DocumentTypeEnum  {
    NSFAS_CONSENT_FORM_FOR_PARENT_GUARDIAN = "NSFAS_CONSENT_FORM_FOR_PARENT_GUARDIAN",
    NSFAS_CONSENT_FORM_FOR_APPLICANT = "NSFAS_CONSENT_FORM_FOR_APPLICANT",
    ID_COPY = "ID_COPY",
    NSFAS_DECLARATION_SIGNED_BY_PARENT_GUARDIAN = "NSFAS_DECLARATION_SIGNED_BY_PARENT_GUARDIAN",
    NSFAS_CONSENT_FORM_OF_SPOUSE = "NSFAS_CONSENT_FORM_OF_SPOUSE",
    MARRIAGE_CERTIFICATE = "MARRIAGE_CERTIFICATE",
    DEATH_CERTIFICATE_OF_PARENT_GUARDIAN = "DEATH_CERTIFICATE_OF_PARENT_GUARDIAN",
    NSFAS_DECLARATION_FORM_OR_VULNERABLE_CHILD_FORM = "NSFAS_DECLARATION_FORM_OR_VULNERABLE_CHILD_FORM",
    OTHER = "OTHER"
}