  import {Directive, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
  import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
  import {CoursesService} from "../courses.service";
  import {Course} from "./course";
  import {Results} from "../../core/results";
  import {Application} from '../../applications/application/application';
  declare var $: any;
  @Directive({
    selector: '[appCourse]',
    providers: [{
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CourseDirective),
      multi: true
    }]
  })
  export class CourseDirective  implements ControlValueAccessor, OnInit {


    @Input() private course: Course;
    @Input() private application: Application = new Application();
    @Input() private disabled = false;
    select2: any;

    constructor(private el: ElementRef, private service: CoursesService) {
    }

    ngOnInit(): void {
      const that = this;
      this.select2 = $(this.el.nativeElement).select2({
        width: '100%',
        placeholder: 'Field of study',
        allowClear: true,
        ajax: {
          transport: function(params, success, failure) {
            const page = 1;
            const perPage = 100;
            that.service.search(page, perPage, 'ASC', 'name', params.data.term).subscribe((ret: Results<Course>) => {
              success(ret);
            });

          },
          dataType: 'json',
          type: 'GET',
          processResults: function(data) {
            const arr = [];
            data.content.forEach((obj : Course)=> {
              arr.push({
                id: JSON.stringify(obj),
                text: obj.name,
                disabled: that.application?.courseOne?.id === obj.id || that.application?.courseTwo?.id === obj.id || that.application?.courseThree?.id === obj.id
              });
            });
            return {
              results: arr
            };
          }
        }
      });
      $(this.el.nativeElement).on('select2:select', function(e) {
        that.course = JSON.parse(e.params.data.id);
        that.propagateChange(that.course);
      });

      $(this.el.nativeElement).on('select2:unselect', function(e) {
        that.course = null;
        that.propagateChange(that.course);
      });


    }

    onTouched = () => {
    };

    propagateChange = (_: any) => {
    };

    writeValue(course: Course): void {

      this.course = course;
      this.propagateChange(this.course);
      const that = this;
      if (this.course !== undefined && this.course !== null) {
        const option = new Option(course.name, JSON.stringify(course), true, true);
        that.select2.append(option);
        that.select2.trigger('change');
      }

    }

    registerOnChange(fn: any): void {
      this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
      this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
      this.disabled = isDisabled;
    }

    get value(): any {
      return this.course;
    }

    set value(v: any) {
      if (v !== this.course) {
        this.course = v;
        this.propagateChange(v);
      }
    }

  }
