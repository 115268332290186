import {Attachment} from '../../core/models';

export class  RequestedDocuments {
  caseId: string;
  hashkey: string;
  disabilityAnnexure: Attachment;
  provisionalOfferLetter: Attachment;
  vulnerableChildOrNonSassDeclaration: Attachment;
  father: Attachment[];
  guardian: Attachment[];
  mother: Attachment[];
  spouse: Attachment[];

  student: Attachment[];
  appeals: Attachment[];
  requiredDocuments: Attachment[];
}
