import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfigService} from '../../../../config.service';
import {Application} from '../../../application/application';
import {ApplicationsService} from '../../../applications.service';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {NgForm} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Attachment} from '../../../../core/models';
import {GoogleAnalyticsService} from 'ngx-google-analytics';

declare var $: any;

@Component({
  selector: 'app-withdraw-confirm',
  templateUrl: './withdraw-confirm.component.html',
  styleUrls: ['./withdraw-confirm.component.css']
})
export class WithdrawConfirmComponent implements OnInit {
  @Output() closeWithdraw = new EventEmitter();
  @Input() application: Application;
  @BlockUI() blockUI: NgBlockUI;
  @Input() kinType: string;
  @ViewChild('ngForm', {static: false})
  public ngForm: NgForm;
  public validated: boolean;
  checked = false;

  constructor(public activeModal: NgbActiveModal, public applicationsService: ApplicationsService,protected gaService: GoogleAnalyticsService,
              public configService: ConfigService, private modalService: NgbModal,
              private matSnackBar: MatSnackBar) {
  }

  ngOnInit() {
  }

  dashboard() {
    this.activeModal.close();
  }

  close() {
    this.closeWithdraw.emit();
  }

  save() {
    this.applicationsService.update(this.application).subscribe((application: Application) => {
      this.applicationsService.withdraw(this.application).subscribe((application:Application) => {
        this.blockUI.stop();
        this.application = application;
        this.applicationsService.application = application;
      }, (error: HttpErrorResponse) => {
        this.blockUI.stop();
        this.gaService.exception(error.error?.message, true);
        this.matSnackBar.open(error.error.message, 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      });
    }, (error: HttpErrorResponse) => {
      this.blockUI.stop();
      this.gaService.exception(error.error?.message, true);
      this.matSnackBar.open(error.error.message, 'Okay', {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    });


  }

  onSubmit() {

    if (!this.ngForm.valid) {
      this.validated = true;
      $('html, body').animate({
        scrollTop: 0
      }, 1000, function() {
      });
      return;
    } else {
      this.application.status = 'Withdrawn';
      this.blockUI.start();
      if (this.application.withdrawId && this.application.withdrawId.file && (this.application.withdrawId.file instanceof Blob)) {
        this.applicationsService.upload(this.application.withdrawId.file).subscribe((image: Attachment) => {
          if (image) {
            this.application.withdrawId = image;
            this.save();
          }
        }, (error: HttpErrorResponse) => {
          this.blockUI.stop();
          this.gaService.exception(error.error?.message, true);
          this.matSnackBar.open(error.error.message, 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        });
      } else {
        this.save();
      }
    }

  }

}
