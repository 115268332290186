import {Attachment} from './models/attachment';
import {CookieService} from 'ngx-cookie-service';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigServices {

  private version = 1.0;
  private api = '/api/';
  public existing = 'https://assistive.nsfas.org.za/Application/myAccount.jsp?Token=';
  public singleView= 'http://singleView?Token='

  constructor(public cookieService: CookieService) {

  }


  public getImage(data: string, file: File, existing: Attachment): Attachment {
    let image = new Attachment();
    if (existing) {
      image = existing;
    }
    image.contentType = data.substr(0, data.indexOf(';')).replace('data:', '');
    image.content = data.substr(data.indexOf(';'), data.length).replace(';base64,', '');
    image.name = file.name;
    image.size = file.size;
    image.file = file;
    image.version = this.version;
    return image;

  }

  public getDocument(data: string, file: any, doc: Attachment): Attachment {

    let document = doc;
    if (doc === undefined) {
      document = new Attachment();
    }
    document.contentType = data.substr(0, data.indexOf(';')).replace('data:', '');
    document.content = data.substr(data.indexOf(';'), data.length).replace(';base64,', '');
    document.name = file.name;
    return document;

  }

  public isImage(document: Attachment): boolean {

    if (document === null || document === undefined) {
      return false;
    }
    if (document.contentType === null || document.contentType === undefined) {
      return false;
    }
    return document.contentType.indexOf('image') > -1 || document.contentType.indexOf('img') > -1;
  }

  public isPDF(document: Attachment): boolean {

    if (document === null || document === undefined) {
      return false;
    }
    if (document.contentType === null || document.contentType === undefined) {
      return false;
    }
    return document.contentType.indexOf('pdf') > -1 || document.contentType.indexOf('pdf') > -1;
  }

  public getApi(): string {
    return this.api;
  }

  public getImageUrl(document: Attachment): string {

    if (document === undefined || document === null) {
      return '';
    }
    if (!this.isImage(document)) {
      return 'http://chnm.gmu.edu/staff/jsafley/omeka/application/views/scripts/images/fallback-file.png';
    }
    if (document.id === undefined || document.id === null) {

      if (document.content !== undefined || document.content !== null) {
        return 'data:' + document.contentType + ';base64,' + document.content;
      }
      return '';
    }
    return this.api + '/file/' + document.id;

  }

  public getPDFUrl(document: Attachment): string {

    if (document === undefined || document === null) {
      return '';
    }
    if (!this.isPDF(document)) {
      return 'http://chnm.gmu.edu/staff/jsafley/omeka/application/views/scripts/images/fallback-file.png';
    }
    if (document.id === undefined || document.id === null) {

      if (document.content !== undefined || document.content !== null) {
        return 'data:' + document.contentType + ';base64,' + document.content;
      }
      return '';
    }
    return this.api + 'file/' + document.id;

  }


}
