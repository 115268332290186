import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { CoreComponent } from 'src/app/core/core.component';
import {HigherEducationInstitution} from './higher-education-institution';
import {NgForm} from '@angular/forms';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {HigherEducationInstitutionsService} from '../higher-education-institutions.service';
import {ActivatedRoute} from '@angular/router';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HttpErrorResponse} from '@angular/common/http';
declare var $: any;
@Component({
  selector: 'app-higher-education-institution',
  templateUrl: './higher-education-institution.component.html',
  styleUrls: ['./higher-education-institution.component.scss']
})
export class HigherEducationInstitutionComponent extends CoreComponent<HigherEducationInstitution> {

  @Input() item: HigherEducationInstitution = new HigherEducationInstitution();
  validated= false;
  @ViewChild('ngForm', { static: false }) public ngForm: NgForm;
  @BlockUI() blockUI: NgBlockUI;
  constructor(protected servicesService: HigherEducationInstitutionsService, public route: ActivatedRoute,
              public activeModal: NgbActiveModal, private matSnackBar: MatSnackBar) {
    super(servicesService,route);
  }

  onSubmit() {

    if (!this.ngForm.valid) {
      this.validated = true;
      $('html, body').animate({
        scrollTop: 0
      }, 1000, function() {
      });
      return;
    } else {
      this.blockUI.start();
      this.servicesService.save(this.item).subscribe((item: HigherEducationInstitution) => {
        this.blockUI.stop();
        this.activeModal.close();
        this.item = item;
      }, (error: HttpErrorResponse) => {
        this.blockUI.stop();
        this.matSnackBar.open(error.error.message, 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      });
    }

  }


}
