import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Course} from './course';
import {NgForm} from '@angular/forms';
import {CoursesService} from '../courses.service';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {ActivatedRoute} from '@angular/router';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CoreComponent} from '../../core/core.component';
import {HttpErrorResponse} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Meta, Title} from '@angular/platform-browser';

declare var $: any;
@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent extends CoreComponent<Course> {

  @Input() item: Course = new Course();
  validated= false;
  @ViewChild('ngForm', { static: false }) public ngForm: NgForm;
  @BlockUI() blockUI: NgBlockUI;
  constructor(protected servicesService: CoursesService, public route: ActivatedRoute,
              public activeModal: NgbActiveModal, private matSnackBar: MatSnackBar,public meta: Meta, public title: Title) {
    super(servicesService,route);
    this.title.setTitle('Course');
  }

  onSubmit() {

    if (!this.ngForm.valid) {
      this.validated = true;
      $('html, body').animate({
        scrollTop: 0
      }, 1000, function() {
      });
      return;
    } else {
      this.blockUI.start();
      this.servicesService.save(this.item).subscribe((item: Course) => {
        this.blockUI.stop();
        this.activeModal.close();
        this.item = item;

      }, (error: HttpErrorResponse) => {
          this.blockUI.stop();
          this.matSnackBar.open(error.error.message, 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        });
    }

  }



}
