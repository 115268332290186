import { Address } from 'src/app/core/address';
import {Race} from '../../admin-config/races/race/race';

export class Register {
  cellphone: string;
  email: string;
  firstName: string;
  id: string;
  idNumber: string;
  married:boolean;
  disabled:boolean;
  lastName: string
  password: string;
  address: Address;
  agree: boolean;
  passwordCheck: string;
  race: Race;

  public constructor(init?:Partial<Register>){
    Object.assign(this, init);
  }

}
