import {Component, OnInit} from '@angular/core';
import {TermConditionsService} from '../admin/term-conditions/term-conditions.service';
import {ActivatedRoute} from '@angular/router';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {HttpErrorResponse} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TermCondition} from '../admin/term-conditions/term-condition/term-condition';
import {Results} from '../core/results';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-terms-conditions-view',
  templateUrl: './terms-conditions-view.component.html',
  styleUrls: ['./terms-conditions-view.component.scss']
})
export class  TermsConditionsViewComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  item: TermCondition;

  constructor(protected servicesService: TermConditionsService,
              public route: ActivatedRoute, private matSnackBar: MatSnackBar,
              public meta: Meta,
              public title: Title) {
  }

  public ngOnInit(): void {

    this.title.setTitle('NSFAS Terms and Conditions');
    this.route.params.subscribe(params => {
      if (params.id !== undefined) {
        if (params.id !== '0') {
          this.get(params.id);
        }
      }
    });

  }

  public get(id: string) {

    this.blockUI.start('Loading');
    this.servicesService.searchByType(1, 1, 'DESC', 'createDate', '', id).subscribe((results:Results<TermCondition>) => {
      this.blockUI.stop();
      if(results.numberOfElements> 0) {
        this.item = results.content[0];
      }
    }, (error: HttpErrorResponse) => {
      this.blockUI.stop();
      this.matSnackBar.open(error.error.message, 'Okay', {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    });
  }

}
