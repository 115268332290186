<div class="row justify-content-center">
  <div class="col-md-5">

    <h2 class="mb-3">Confirm application details</h2>
    <form (ngSubmit)="onSubmit()" #kinForm="ngForm" class="px-5 needs-validation" [ngClass]="validated===true?'was-validated':''">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="notif mx-auto w-100 mb-4">
            <div class="d-table-cell align-middle w-15">
              <i class="fa fa-user fa-2x"></i>
            </div>
            <div class="d-table-cell w-75 pt-2 ms-5 text-start" *ngIf="applicationsService.application?.user?.idNumber">
              <p class="notif-body mb-0">{{applicationsService.application?.user?.firstName}} {{applicationsService.application?.user?.lastName}}</p>
              <p class="mb-1 text-muted">
                {{applicationsService.application?.user?.idNumber}}
              </p>
              <p class="mb-1 text-muted">
                {{applicationsService.application?.user?.cellphone}}
              </p>
            </div>


          </div>

          <!-- <div class="row justify-content-center" *ngIf="applicationsService?.application?.selectedHigherEducationInstitutionOne">
            <div class="col-md-12 text-start">
              <div class="mb-2 mt-2">
                <p class="notif-body mb-0"><strong>3 Selected institutions</strong></p>
            <ul>
              <li>
                {{applicationsService?.application?.selectedHigherEducationInstitutionOne?.name}}
              </li>
              <li>
                {{applicationsService?.application?.selectedHigherEducationInstitutionTwo?.name}}
              </li>
              <li>
                {{applicationsService?.application?.selectedHigherEducationInstitutionThree?.name}}
              </li>
            </ul>
              </div>
            </div>

          </div> -->

          <div class="row justify-content-center" *ngIf="applicationsService?.application?.courseOne">
            <div class="col-md-12 text-start">
              <div class="mb-2 mt-2">
                <p class="notif-body mb-0"><strong>3 Selected fields of study</strong></p>
                <ul>
                  <li>
                    {{applicationsService?.application?.courseOne?.name}}
                  </li>
                  <li>
                    {{applicationsService?.application?.courseTwo?.name}}
                  </li>
                  <li>
                    {{applicationsService?.application?.courseThree?.name}}
                  </li>
                </ul>
              </div>
            </div>

          </div>

          <!-- <div class="row justify-content-center" *ngIf="applicationsService?.application?.address">
            <div class="col-md-12 text-start">
              <div class="mb-2 mt-2">
                <p class="notif-body mb-0"><strong>Address Information</strong></p>
                  <span *ngIf="applicationsService?.application?.address?.line1">
                    {{applicationsService?.application?.address?.line1}}<br/>
                  </span>
                  <span *ngIf="applicationsService?.application?.address?.line2">
                    {{applicationsService?.application?.address?.line2}}<br/>
                  </span>
                  <span *ngIf="applicationsService?.application?.address?.town">
                    {{applicationsService?.application?.address?.town}}<br/>
                  </span>

                  <span *ngIf="applicationsService?.application?.address?.provinceName?.name">
                    {{applicationsService?.application?.address?.provinceName?.name}}<br/>
                  </span>

                <span *ngIf="applicationsService?.application?.address?.postalCode">
                    {{applicationsService?.application?.address?.postalCode}}<br/>
                  </span>

              </div>
            </div>

          </div> -->

          <div class="row justify-content-center" *ngIf="applicationsService.application.disabilityAnnexure">
            <div class="col-md-12 text-start">
              <div class="mb-5 mt-2">
                <p class="mb-0">{{applicationsService.application.disabilityAnnexure.name}}</p>
                <small class="ps-3">Disability Annexure</small>
              </div>
            </div>
          </div>

          <div class="row justify-content-center" *ngIf="applicationsService?.application?.vulnerableChildOrNonSassDeclaration?.name">
            <div class="col-md-12 text-start">
              <div class="mb-5 mt-2">
                <p class="mb-0">{{applicationsService.application.vulnerableChildOrNonSassDeclaration.name}}</p>
                <small class="ps-3">{{applicationsService.application.vulnerableChildOrNonSassDeclaration.caption}}</small>
              </div>
            </div>
          </div>

          <div *ngIf="applicationsService.application.spouse && applicationsService.application.spouse.requiredDocuments">

            <div class="row justify-content-center" *ngFor="let requiredDocument of applicationsService.application.spouse.requiredDocuments; let i = index">
              <div class="col-md-12 text-start">
                <div class="mb-5 mt-2">
                  <p class="mb-0">{{requiredDocument.name}}</p>
                  <small class="ps-3">{{requiredDocument.caption}}</small>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="applicationsService.application.father && applicationsService.application.father.requiredDocuments">

            <div class="row justify-content-center" *ngFor="let requiredDocument of applicationsService.application.father.requiredDocuments; let i = index">
              <div class="col-md-12 text-start">
                <div class="mb-5 mt-2">
                  <p class="mb-0">{{requiredDocument.name}}</p>
                  <small class="ps-3">{{requiredDocument.caption}}</small>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="applicationsService.application.mother && applicationsService.application.mother.requiredDocuments">

            <div class="row justify-content-center" *ngFor="let requiredDocument of applicationsService.application.mother.requiredDocuments; let i = index">
              <div class="col-md-12 text-start">
                <div class="mb-5 mt-2">
                  <p class="mb-0">{{requiredDocument.name}}</p>
                  <small class="ps-3">{{requiredDocument.caption}}</small>
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center" *ngIf="applicationsService?.application?.provisionalOffer">
            <div class="col-md-12 text-start">
              <div class="mb-5 mt-2">
                <p class="mb-0">{{applicationsService.application?.provisionalOfferLetter?.name}}</p>
                <small class="ps-3">{{applicationsService.application?.provisionalOfferLetter?.caption}}</small>
              </div>
            </div>
          </div>

          <div class="row justify-content-center" *ngIf="!applicationsService?.application?.sassaFunded && applicationsService?.application?.consentForm">
            <div class="col-md-12 text-start">
              <div class="mb-5 mt-2">
                <p class="mb-0">{{applicationsService.application?.consentForm?.name}}</p>
                <small class="ps-3">{{applicationsService.application?.consentForm?.caption}}</small>
              </div>
            </div>
          </div>

          <div *ngIf="applicationsService.application.guardian && applicationsService.application.guardian.requiredDocuments">

            <div class="row justify-content-center" *ngFor="let requiredDocument of applicationsService.application.guardian.requiredDocuments; let i = index">
              <div class="col-md-12 text-start">
                <div class="mb-5 mt-2">
                  <p class="mb-0">{{requiredDocument.name}}</p>
                  <small class="ps-3">{{requiredDocument.caption}}</small>
                </div>
              </div>
            </div>
          </div>





        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="d-grid gap-2">

            <button class="btn btn-outline-dark btn-lg mb-3" type="button" (click)="previous()">Previous</button>
          </div>

        </div>
        <div class="col-md-6">
          <div class="d-grid gap-2">
            <button class="btn btn-primary btn-lg mb-3" type="submit">Submit</button>
          </div>

        </div>
      </div>
    </form>

  </div>

</div>
