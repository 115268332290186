import { Injectable } from '@angular/core';
import {ServicesService} from "../services.service";
import {HttpClient} from "@angular/common/http";
import {ConfigServices} from "../../core/config";
import {CookieService} from "ngx-cookie-service";
import {Router} from "@angular/router";
import {QuestionAnswers} from "../../authentication/auth-security-questions/question-answers";

@Injectable({
  providedIn: 'root'
})
export class UserQuestionsService extends ServicesService {

  idNumber: string;
  constructor(public http: HttpClient, public configService: ConfigServices,public cookieService: CookieService, private router: Router) {
    super('user-security-questions', http, configService);
  }

  public hasUserSecurityQuestions() {

    const url = this.configService.getApi() + this.type + '/has-security-questions';
    return this.http.get(url,{  withCredentials: true });

  }

  public getSecurityUserQuestions(idNumber: string) {

    const url = this.configService.getApi() + this.type + '/questions/'+idNumber;
    return this.http.get(url);

  }

  public validateQuestions(answers: QuestionAnswers) {
    const url = this.configService.getApi() + this.type+'/validate-questions';
    return this.http.post(url, answers,{  withCredentials: true });
  }

  public save(item: any) {
    const url = this.configService.getApi() + this.type+'/questions';
    return this.http.put(url, item,{  withCredentials: true });
  }
}
