import {Injectable} from '@angular/core';
import {ServicesService} from '../users/services.service';
import {HttpClient} from '@angular/common/http';
import {ConfigServices} from '../core/config';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {UserQuestion} from '../users/user-questions/user-question/user-question';

@Injectable({
  providedIn: 'root'
})
export class QuestionsService extends ServicesService {

  userQuestions: Array<UserQuestion> = new Array<UserQuestion>();

  constructor(public http: HttpClient, public configService: ConfigServices, public cookieService: CookieService, private router: Router) {
    super('security-questions', http, configService);
  }
}
