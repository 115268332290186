import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {CoreComponent} from '../../core/core.component';
import {NgForm} from '@angular/forms';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {ActivatedRoute} from '@angular/router';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { FrequentlyAskedQuestionAnswer } from './frequently-asked-question-answer';
import {FrequentlyAskedQuestionAnswersService} from '../frequently-asked-question-answers.service';
declare var $: any;
@Component({
  selector: 'app-frequently-asked-question-answer',
  templateUrl: './frequently-asked-question-answer.component.html',
  styleUrls: ['./frequently-asked-question-answer.component.scss']
})
export class FrequentlyAskedQuestionAnswerComponent extends CoreComponent<FrequentlyAskedQuestionAnswer> {

  @Input() item: FrequentlyAskedQuestionAnswer = new FrequentlyAskedQuestionAnswer();
  validated= false;
  @ViewChild('ngForm', { static: false }) public ngForm: NgForm;
  @BlockUI() blockUI: NgBlockUI;
  constructor(protected servicesService: FrequentlyAskedQuestionAnswersService, public route: ActivatedRoute,public activeModal: NgbActiveModal) {
    super(servicesService,route);
  }

  onSubmit() {

    if (!this.ngForm.valid) {
      this.validated = true;
      $('html, body').animate({
        scrollTop: 0
      }, 1000, function() {
      });
      return;
    } else {
      this.blockUI.start();
      this.servicesService.save(this.item).subscribe((item: FrequentlyAskedQuestionAnswer) => {
        this.blockUI.stop();
        this.activeModal.close();
        this.item = item;

      });
    }

  }


}
