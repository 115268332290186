<app-card>
<div class="row justify-content-center" style="padding-top: 15%;">
  <div class="col-md-5">

    <h4 class="mb-3 text-center"><b>Dear Applicant</b> </h4>
    <h4 class="mb-3 text-center"><b>Did you apply to a public university or college?</b> </h4>

    <form (ngSubmit)="onSubmit()" #appliedForm="ngForm">
      <div class="row" style="padding-left: 30%;">
        <div class="col-md-6">
          <div class="radio d-block mb-3">
            <input class="form-check-input" type="radio" name="applied" id="appliedNo" [value]="false"
              [(ngModel)]="applicationsService.application.applied" (click)="clear();" required>
            <label class="cr" for="appliedNo">No</label>
          </div>
        </div>
        <div class="col-md-4">
          <div class="radio d-block mb-3">
            <input class="form-check-input" type="radio" name="applied" id="appliedYes" [value]="true"
              [(ngModel)]="applicationsService.application.applied" required>
            <label class="cr" for="appliedYes">Yes</label>
          </div>
        </div>
      </div>
      <div class="row" style="text-align: center;">
        <div class="row">
          <div class="d-grid gap-2">
            <button class="btn btn-primary" type="submit"
              [disabled]="applicationsService.application.applied===undefined">Next</button>
          </div>

        </div>
      </div>
      <div class="row">
        <div class="alert alert-light text-start text-dark text-center" *ngIf="!applicationsService.application.applied">
          <small id="alert">Please note, you will have to apply to study at public University or College for your funding application to be considered! </small>
        </div>
      </div>
    </form>
  </div>
</div>
</app-card>