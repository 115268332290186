import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AlertModule, BreadcrumbModule, CardModule, ModalModule} from './components';
import {DataFilterPipe} from './components/data-table/data-filter.pipe';
import {TodoListRemoveDirective} from './components/todo/todo-list-remove.directive';
import {TodoCardCompleteDirective} from './components/todo/todo-card-complete.directive';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {ClickOutsideModule} from 'ng-click-outside';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import 'hammerjs';
import 'mousetrap';
import {GalleryModule} from '@ks89/angular-modal-gallery';
import {NgSelect2Module} from 'ng-select2';
import {
  HigherEducationInstitutionDirective
} from '../../higher-education-institutions/higher-education-institution/higher-education-institution.directive';
import {CourseDirective} from '../../courses/course/course.directive';
import {AddressDirective} from '../../addresses/address/address.directive';
import {RaceDirective} from '../../admin-config/races/race/race.directive';
import {GradeDirective} from '../../admin-config/grades/grade/grade.directive';
import {StudyTermDirective} from '../../admin-config/study-terms/study-term/study-term.directive';
import {
  HigherEducationInstitutionTypeDirective
} from '../../higher-education-institution-types/higher-education-institution-type/higher-education-institution-type.directive';
import {WithdrawConfirmComponent} from '../../applications/tracker/withdraw/withdraw-confirm/withdraw-confirm.component';
import {FileUploadDirective} from '../../core/file-upload.directive';
import {AppealReasonDirective} from '../../appeal-reasons/appeal-reason/appeal-reason.directive';
import {AppealReasonComponent} from '../../appeal-reasons/appeal-reason/appeal-reason.component';
import {AppealReasonsComponent} from '../../appeal-reasons/appeal-reasons.component';
import {FrequentlyAskedQuestionsComponent} from '../../frequently-asked-questions/frequently-asked-questions.component';
import {
  FrequentlyAskedQuestionComponent
} from '../../frequently-asked-questions/frequently-asked-question/frequently-asked-question.component';
import {HigherEducationInstitutionsComponent} from '../../higher-education-institutions/higher-education-institutions.component';
import {
  HigherEducationInstitutionComponent
} from '../../higher-education-institutions/higher-education-institution/higher-education-institution.component';
import {
  HigherEducationInstitutionTypesComponent
} from '../../higher-education-institution-types/higher-education-institution-types.component';
import {
  HigherEducationInstitutionTypeComponent
} from '../../higher-education-institution-types/higher-education-institution-type/higher-education-institution-type.component';
import {SecurityQuestionsComponent} from '../../questions/security-questions.component';
import {FrequentlyAskedQuestionAnswersComponent} from '../../frequently-asked-question-answers/frequently-asked-question-answers.component';
import {
  FrequentlyAskedQuestionDirective
} from '../../frequently-asked-questions/frequently-asked-question/frequently-asked-question.directive';
import {
  FrequentlyAskedQuestionAnswerDirective
} from '../../frequently-asked-question-answers/frequently-asked-question-answer/frequently-asked-question-answer.directive';
import {
  FrequentlyAskedQuestionAnswerComponent
} from '../../frequently-asked-question-answers/frequently-asked-question-answer/frequently-asked-question-answer.component';
import {UsersManagerComponent} from '../../admin/users-manager/users-manager.component';
import {UsersComponent} from '../../admin/users/users.component';
import {UserComponent} from 'src/app/admin/users/user/user.component';
import {UserRoleDirective} from '../../admin/users/user/user-role.directive';
import {UserDirective} from '../../admin/users/user/user.directive';
import {RejectionReasonsComponent} from '../../admin/rejection-reasons/rejection-reasons.component';
import {RejectionReasonComponent} from '../../admin/rejection-reasons/rejection-reason/rejection-reason.component';
import {ApplicationStatusDirective} from '../../applications/application/application-status.directive';
import {AdminConfigModule} from '../../admin-config/admin-config.module';
import {RacesComponent} from '../../admin-config/races/races.component';
import {RaceComponent} from '../../admin-config/races/race/race.component';
import {SchoolsComponent} from '../../admin-config/schools/schools.component';
import {SchoolComponent} from '../../admin-config/schools/school/school.component';
import {SchoolDirective} from '../../admin-config/schools/school/school.directive';
import {GradesComponent} from '../../admin-config/grades/grades.component';
import {GradeComponent} from '../../admin-config/grades/grade/grade.component';
import {StudyTermsComponent} from '../../admin-config/study-terms/study-terms.component';
import {StudyTermComponent} from '../../admin-config/study-terms/study-term/study-term.component';
import {CoursesComponent} from '../../courses/courses.component';
import {CourseComponent} from '../../courses/course/course.component';
import {WithdrawReasonsComponent} from '../../admin-config/withdraw-reasons/withdraw-reasons.component';
import {WithdrawReasonComponent} from '../../admin-config/withdraw-reasons/withdraw-reason/withdraw-reason.component';
import {WithdrawReasonDirective} from '../../admin-config/withdraw-reasons/withdraw-reason/withdraw-reason.directive';
import {QuestionsComponent} from '../../questions/questions.component';
import {QuestionComponent} from '../../questions/question/question.component';
import {LaptopDirective} from '../../admin-config/laptops/laptop/laptop.directive';
import {LaptopsComponent} from '../../admin-config/laptops/laptops.component';
import {LaptopComponent} from '../../admin-config/laptops/laptop/laptop.component';
import {PlacesOfApplicationComponent} from '../../admin-config/places-of-application/places-of-application.component';
import {PlaceOfApplicationComponent} from '../../admin-config/places-of-application/place-of-application/place-of-application.component';
import {PlaceOfApplicationDirective} from '../../admin-config/places-of-application/place-of-application/place-of-application.directive';
import {ProvincesComponent} from '../../admin-config/provinces/provinces.component';
import {ProvinceComponent} from '../../admin-config/provinces/province/province.component';
import {ProvinceDirective} from '../../admin-config/provinces/province/province.directive';
import {MunicipalitiesComponent} from '../../admin-config/municipalities/municipalities.component';
import {MunicipalityComponent} from '../../admin-config/municipalities/municipality/municipality.component';
import {MunicipalityDirective} from '../../admin-config/municipalities/municipality/municipality.directive';
import {DatepickerDirective} from '../../core/datepicker.directive';
import {RejectReasonDirective} from '../../admin-config/reject-reasons/reject-reason/reject-reason.directive';
import {ApplicationComponent} from '../../applications/application/application.component';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {NgStepperModule} from 'angular-ng-stepper';
import {ApplyInstitutionComponent} from '../../apply/apply-institution/apply-institution.component';
import {ApplyComponent} from '../../apply/apply.component';
import {ApplyDocsComponent} from '../../apply/apply-docs/apply-docs.component';
import {ApplyDetailsComponent} from '../../apply/apply-details/apply-details.component';
import {ApplyLaptopComponent} from '../../apply/apply-laptop/apply-laptop.component';
import {ApplyStudyComponent} from '../../apply/apply-study/apply-study.component';
import {ApplyConfirmComponent} from '../../apply/apply-confirm/apply-confirm.component';
import {RegisterUserComponent} from '../../applications/application/register-user/register-user.component';
import {OrderModule} from 'ngx-order-pipe';
import { ApplyUploadDocumentsComponent } from 'src/app/apply/apply-upload-documents/apply-upload-documents.component';
import {RouterModule} from '@angular/router';
import {ApplyTermsComponent} from '../../apply/apply-terms/apply-terms.component';
import {
  HigherEducationInstitutionTypesDirective
} from '../../higher-education-institution-types/higher-education-institution-types.directive';
import {AppealReopenReasonDirective} from '../../admin-config/appeal-reopen-reasons/appeal-reopen-reason/appeal-reopen-reason.directive';
import {
  RejectAppealReprocessReasonDirective
} from '../../admin-config/reject-appeal-reprocess-reasons/reject-appeal-reprocess-reason/reject-appeal-reprocess-reason.directive';
import {
  AppealReprocessReasonDirective
} from '../../admin-config/appeal-reprocess-reasons/appeal-reprocess-reason/appeal-reprocess-reason.directive';
import {
  DisabilityChangeRequestComponent
} from '../../disability-change-requests/disability-change-request/disability-change-request.component';
import {DisabilityChangeRequestsComponent} from '../../disability-change-requests/disability-change-requests.component';
import {
  DisabilityChangeRequestCheckComponent
} from '../../disability-change-requests/disability-change-request-check/disability-change-request-check.component';
import {SubFunderDirective} from '../../admin-config/sub-funders/sub-funder/sub-funder.directive';
import {FunderDirective} from '../../admin-config/funders/funder/funder.directive';
import {BankAccountTypeDirective} from '../../admin-config/bank-account-types/bank-account-type/bank-account-type.directive';
import {BankDirective} from '../../admin-config/banks/bank/bank.directive';
import { OfferLetterComponent } from 'src/app/apply/offer-letter/offer-letter.component';
import { ApplySubmittedComponent } from 'src/app/apply/apply-submitted/apply-submitted.component';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule,
    CardModule,
    BreadcrumbModule,
    ModalModule,
    NgbModule,
    NgSelect2Module,
    GalleryModule.forRoot(),
    ClickOutsideModule,
    CdkStepperModule,
    NgStepperModule,
    OrderModule,
    RouterModule


  ],
  exports: [
    CommonModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule,
    CardModule,
    BreadcrumbModule,
    ModalModule,
    GalleryModule,
    DataFilterPipe,
    TodoListRemoveDirective,
    TodoCardCompleteDirective,
    HigherEducationInstitutionDirective,
    AddressDirective,
    RaceDirective,
    GradeDirective,
    HigherEducationInstitutionDirective,
    StudyTermDirective,
    CourseDirective,
    LaptopDirective,
    AppealReasonComponent,
    AppealReasonsComponent,
    ClickOutsideModule,
    SpinnerComponent,
    HigherEducationInstitutionDirective,
    WithdrawConfirmComponent,
    FileUploadDirective,
    HigherEducationInstitutionTypeDirective,
    HigherEducationInstitutionTypesDirective,
    AppealReasonDirective,
    FrequentlyAskedQuestionsComponent,
    FrequentlyAskedQuestionAnswerComponent,
    FrequentlyAskedQuestionAnswersComponent,
    FrequentlyAskedQuestionComponent,
    HigherEducationInstitutionsComponent,
    HigherEducationInstitutionComponent,
    HigherEducationInstitutionTypesComponent,
    HigherEducationInstitutionTypeComponent,
    RejectionReasonsComponent,
    RejectionReasonComponent,
    SecurityQuestionsComponent,
    UsersManagerComponent,
    UsersComponent,
    UserComponent,
    FrequentlyAskedQuestionDirective,
    FrequentlyAskedQuestionAnswerDirective,
    UserRoleDirective,
    UserDirective,
    ApplicationStatusDirective,
    RacesComponent,
    RaceComponent,
    SchoolsComponent,
    SchoolComponent,
    SchoolDirective,
    GradesComponent,
    GradeComponent,
    StudyTermsComponent,
    StudyTermComponent,
    CoursesComponent,
    CourseComponent,
    WithdrawReasonsComponent,
    WithdrawReasonComponent,
    AppealReopenReasonDirective,
    WithdrawReasonDirective,
    QuestionsComponent,
    QuestionComponent,
    LaptopsComponent,
    LaptopComponent,
    PlacesOfApplicationComponent,
    PlaceOfApplicationComponent,
    PlaceOfApplicationDirective,
    DatepickerDirective,
    ProvincesComponent,
    ProvinceComponent,
    ProvinceDirective,
    MunicipalitiesComponent,
    MunicipalityComponent,
    MunicipalityDirective,
    RejectReasonDirective,
    ApplicationComponent,
    ApplyInstitutionComponent,
    ApplyDocsComponent,
    ApplyDetailsComponent,
    ApplyLaptopComponent,
    ApplyStudyComponent,
    ApplyConfirmComponent,
    ApplyTermsComponent,
    RegisterUserComponent,
    ApplyUploadDocumentsComponent,
    AppealReprocessReasonDirective,
    RejectAppealReprocessReasonDirective,
    DisabilityChangeRequestComponent,
    DisabilityChangeRequestsComponent,
    SubFunderDirective,
    FunderDirective,
    BankDirective,
    BankAccountTypeDirective,
    OfferLetterComponent
  ],
  declarations: [
    DataFilterPipe,
    TodoListRemoveDirective,
    TodoCardCompleteDirective,
    HigherEducationInstitutionDirective,
    HigherEducationInstitutionTypeDirective,
    HigherEducationInstitutionTypesDirective,
    AddressDirective,
    CourseDirective,
    RaceDirective,
    GradeDirective,
    HigherEducationInstitutionDirective,
    StudyTermDirective,
    SpinnerComponent,
    HigherEducationInstitutionDirective,
    WithdrawConfirmComponent,
    AppealReasonComponent,
    AppealReasonsComponent,
    FileUploadDirective,
    AppealReasonDirective,
    RejectionReasonsComponent,
    RejectionReasonComponent,
    FrequentlyAskedQuestionsComponent,
    FrequentlyAskedQuestionComponent,
    HigherEducationInstitutionsComponent,
    HigherEducationInstitutionComponent,
    HigherEducationInstitutionTypesComponent,
    HigherEducationInstitutionTypeComponent,
    SecurityQuestionsComponent,
    FrequentlyAskedQuestionAnswerComponent,
    FrequentlyAskedQuestionAnswersComponent,
    FrequentlyAskedQuestionDirective,
    FrequentlyAskedQuestionAnswerDirective,
    UsersManagerComponent,
    UsersComponent,
    UserComponent,
    UserRoleDirective,
    UserDirective,
    ApplicationStatusDirective,
    RacesComponent,
    RaceComponent,
    SchoolsComponent,
    SchoolComponent,
    SchoolDirective,
    GradesComponent,
    GradeComponent,
    StudyTermsComponent,
    StudyTermComponent,
    CoursesComponent,
    CourseComponent,
    WithdrawReasonsComponent,
    WithdrawReasonComponent,
    WithdrawReasonDirective,
    DatepickerDirective,
    QuestionsComponent,
    QuestionComponent,
    LaptopDirective, LaptopsComponent, LaptopComponent, PlacesOfApplicationComponent,
    PlaceOfApplicationComponent, PlaceOfApplicationDirective, ProvincesComponent,
    ProvinceComponent, ProvinceDirective, MunicipalitiesComponent, MunicipalityComponent, MunicipalityDirective,
    RejectReasonDirective,
    ApplicationComponent,
    ApplyInstitutionComponent,
    ApplyComponent,
    ApplyDocsComponent,
    ApplyDetailsComponent,
    ApplyLaptopComponent,
    ApplyStudyComponent,
    ApplyConfirmComponent,
    RegisterUserComponent,
    ApplyTermsComponent,
    ApplyUploadDocumentsComponent,
    AppealReopenReasonDirective,
    AppealReprocessReasonDirective,
    RejectAppealReprocessReasonDirective,
    DisabilityChangeRequestComponent,
    DisabilityChangeRequestsComponent,
    SubFunderDirective,
    FunderDirective,
    BankDirective,
    BankAccountTypeDirective,
    OfferLetterComponent,
    ApplySubmittedComponent
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class SharedModule {
}
