import {Application} from '../../applications/application/application';
import {User} from '../../admin/users/user/user';
import {Attachment} from '../../core/models';

export  class DisabilityChangeRequest {
  id: string;
  createDate: Date;
  application: Application;
  from: boolean;
  to: boolean = false;
  status: string;
  approvedBy: User;
  requestedBy: User;
  rejectedBy: User;
  disabilityAnnexure: Attachment;
}
