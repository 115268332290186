import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {Application} from '../../applications/application/application';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Meta, Title} from '@angular/platform-browser';
import {ConfigService} from '../../config.service';
import {AppealEvent} from './appeal.event';
import {AppealsService} from '../../appeals/appeals.service';
import {Appeal} from '../appeal/appeal';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import * as _ from 'lodash';

@Component({
  selector: 'app-track-appeal',
  templateUrl: './track-appeal.component.html',
  styleUrls: ['./track-appeal.component.scss'],
})
export class TrackAppealComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  @Input() appeals: Array<Appeal> = new Array<Appeal>();
  @Input() application: Application = new Application();
  @Output() withdrawAppeal = new EventEmitter<Appeal>();
  @Output() reOpenWithdrawanAppeal = new EventEmitter<Appeal>();
  @Output() reProcessAppealEvent = new EventEmitter<Appeal>();
  public loading: boolean = true;

  constructor(public activeModal: NgbActiveModal, public meta: Meta, public title: Title,
              protected gaService: GoogleAnalyticsService,
              public configService: ConfigService, private appealsService: AppealsService) {
    this.title.setTitle('Track Application');
  }

  ngOnInit() {

    this.loading = true;
    this.blockUI.start();
    this.appeals = new Array<Appeal>();
    this.appealsService.getAppeal(this.application).subscribe((appeals: Array<Appeal>) => {
      this.loading = false;
     if(Array.isArray(appeals))  {
       this.appeals = appeals;
       this.blockUI.stop();

     } else {
       this.appeals.push(appeals);
     }
      this.blockUI.stop();
    }, error => {
      this.gaService.exception(error.error?.message, true);
      this.blockUI.stop();
    });

  }

  close() {
    this.activeModal.close();
  }

  withdraw(appeal: Appeal) {
    this.withdrawAppeal.emit(appeal);
  }
  reOpenWithdrawn(appeal: Appeal) {
    this.reOpenWithdrawanAppeal.emit(appeal);
  }

  reProcessAppeal(appeal: Appeal) {
    this.reProcessAppealEvent.emit(appeal);
  }
  getStatus(application: Application) {
    if(application.status ==='NotStarted') {
      return '';
    } else if(application.status ==='ProvisionallyFunded') {
      return 'Application approved (Funding Eligible)';
    }
    return _.startCase(application.status);
  }

  getAppealStatus(appeal: Appeal) {
    return _.startCase(appeal?.status);
  }

  getIndex(appeal: Appeal) {

    let indx = 0;
    if (appeal.status === 'Submitted') {
      indx = 1;
    } else if (appeal.status === 'AwaitingEvaluation') {
      if (indx < 2) {
        indx = 2;
      }
    } else if (appeal.status === 'AwaitingDocuments') {
      if (indx < 3) {
        indx = 3;
      }
    } else if (appeal.status === 'Rejected') {
      if (indx < 4) {
        indx = 4;
      }
    } else if (appeal.status === 'Approved') {
      if (indx < 5) {
        indx = 5;
      }
    } else if (appeal.status === 'Revoked') {
      if (indx < 6) {
        indx = 6;
      }
    } else if (appeal.status === 'Finalized') {
      if (indx < 5) {
        indx = 5;
      }
    }
    return indx;
  }

}
