import { Injectable } from '@angular/core';
import {ServicesService} from '../users/services.service';
import {HttpClient} from '@angular/common/http';
import {ConfigServices} from '../core/config';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {Register} from '../users/user/register';
import {ApplicationsService} from './applications.service';
import {Attachment} from '../core/models';

@Injectable({
  providedIn: 'root'
})
export class AssistedService extends ServicesService {

  idNumber: string;
  constructor(public http: HttpClient, public configService: ConfigServices, public applicationsService: ApplicationsService, public cookieService: CookieService, private router: Router) {
    super('assisted', http, configService);
  }


  public create(register: Register) {
    const url = this.configService.getApi() + this.type + '/user/create';
    return this.http.post(url,register,{  withCredentials: true });
  }

  public validate() {

    const app = JSON.parse(JSON.stringify(this.applicationsService.application));
    if(app.guardian && !app.guardian.idNumber) {
      app.guardian = null;
    }
    if(app.spouse && !app.spouse.idNumber) {
      app.spouse = null;
    }
    if(app.mother && !app.mother.idNumber) {
      app.mother = null;
    }
    if(app.father && !app.father.idNumber) {
      app.father = null;
    }
    const url = this.configService.getApi() + this.type;
    return this.http.post(url+'/application/validate',app,{  withCredentials: true });
  }

  canBeSubmitted(supportingDocs: Array<Attachment>) {
    if(supportingDocs.length < 1) {
      return false;
    }
    let ret = false;
    supportingDocs.forEach((attachment: Attachment)=>{
      if(attachment.id) {
        ret = true;
        return ret;
      }
    });
    return ret;
  }
  public save() {


    this.applicationsService.application.idNumber = this.idNumber;

    if(this.applicationsService?.application?.father?.supportingDocs && this.canBeSubmitted(this.applicationsService?.application?.father?.supportingDocs)) {
      this.applicationsService.application.father.requiredDocuments = this.applicationsService?.application?.father?.supportingDocs;
    }
    if(this.applicationsService?.application?.mother?.supportingDocs && this.canBeSubmitted(this.applicationsService?.application?.mother?.supportingDocs)) {
      this.applicationsService.application.mother.requiredDocuments = this.applicationsService?.application?.mother?.supportingDocs;
    }
    if(this.applicationsService?.application?.spouse?.supportingDocs && this.canBeSubmitted(this.applicationsService?.application?.spouse?.supportingDocs)) {
      this.applicationsService.application.spouse.requiredDocuments = this.applicationsService?.application?.spouse?.supportingDocs;
    }
    if(this.applicationsService?.application?.guardian?.supportingDocs && this.canBeSubmitted(this.applicationsService?.application?.guardian?.supportingDocs)) {
      this.applicationsService.application.guardian.requiredDocuments = this.applicationsService?.application?.guardian?.supportingDocs;
    }
    this.applicationsService.application.source = 'WEB';
    const app = JSON.parse(JSON.stringify(this.applicationsService.application));
    if(app.guardian && !app.guardian.idNumber) {
      app.guardian = null;
    }
    if(app.spouse && !app.spouse.idNumber) {
      app.spouse = null;
    }
    if(app.mother && !app.mother.idNumber) {
      app.mother = null;
    }
    if(app.father && !app.father.idNumber) {
      app.father = null;
    }
    const url = this.configService.getApi() + this.type + '/application/save';
    return this.http.post(url,app,{  withCredentials: true });
  }
  public process(id: string) {

    this.applicationsService.application.idNumber = this.idNumber;
    const url = this.configService.getApi() + this.type + '/application/process/'+id;
    return this.http.post(url,this.applicationsService.application,{  withCredentials: true });
  }

}
