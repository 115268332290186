import { Component, OnInit } from '@angular/core';
import {CoreComponent} from '../core/core.component';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {ActivatedRoute} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Results} from '../core/results';
import {HigherEducationInstitutionType} from './higher-education-institution-type/higher-education-institution-type';
import {HigherEducationInstitutionTypesService} from './higher-education-institution-types.service';
import {HigherEducationInstitutionTypeComponent} from './higher-education-institution-type/higher-education-institution-type.component';
import {HttpErrorResponse} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
declare var bootbox: any;
@Component({
  selector: 'app-higher-education-institution-types',
  templateUrl: './higher-education-institution-types.component.html',
  styleUrls: ['./higher-education-institution-types.component.scss']
})
export class HigherEducationInstitutionTypesComponent extends CoreComponent<HigherEducationInstitutionType> {
  @BlockUI() blockUI: NgBlockUI;
  constructor(protected servicesService: HigherEducationInstitutionTypesService, private matSnackBar: MatSnackBar,
              public route: ActivatedRoute,private modalService: NgbModal,protected gaService: GoogleAnalyticsService) {
    super(servicesService,route);
  }

  public search() {
    this.blockUI.start();
    this.servicesService.search(this.currentPage, this.perPage, this.order, this.orderBy, this.keyword)
      .subscribe((data: Results<HigherEducationInstitutionType>) => {
        this.results = data;
        this.blockUI.stop();
      }, (error: HttpErrorResponse) => {
        this.blockUI.stop();
        this.gaService.exception(error.error?.message, true);
        this.matSnackBar.open(error.error.message, 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      });
  }

  remove(item: HigherEducationInstitutionType){
    const that = this;
    bootbox.confirm({
      title: 'Are you sure you want to delete?',
      message: '<strong>'+item.name+'</strong>',
      callback: function(result) {
        if (result === null) {
        } else if (result === true) {

          that.servicesService.delete(item.id).subscribe(()=>{
            that.search();
          },error => {
            that.gaService.exception(error.error?.message, true);
          });
        }
      }
    });
  }
  newItem() {
    this.item = new HigherEducationInstitutionType();
    this.open(this.item);
  }
  open(item: HigherEducationInstitutionType) {
    const modalRef = this.modalService.open(HigherEducationInstitutionTypeComponent, {fullscreen:true, modalDialogClass:'animate-bottom'});
    modalRef.componentInstance.item= item;
    modalRef.componentInstance.name = 'World';
    modalRef.closed.subscribe(()=>{
      this.search();
    });
  }


}
