import {HttpErrorResponse} from '@angular/common/http';
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CookieService} from 'ngx-cookie-service';
import {UsersService} from '../../users/users.service';
import {StudyStep} from 'src/app/core/models/study';
import {CdkStepper} from '@angular/cdk/stepper';
import {NgForm} from '@angular/forms';
import {ApplicationsService} from '../../applications/applications.service';
import {Router} from '@angular/router';
import {User} from '../../admin/users/user/user';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {Documets} from '../../applications/application/application';
import {Meta, Title} from '@angular/platform-browser';
import {AssistedService} from '../../applications/assisted.service';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
declare var $: any;

@Component({
  selector: 'app-apply-confirm',
  templateUrl: './apply-confirm.component.html',
  styleUrls: ['./apply-confirm.component.scss']
})
export class ApplyConfirmComponent implements OnInit {
  public study: StudyStep;
  public validated: boolean;
  @ViewChild('kinForm', {static: false})
  public kinForm: NgForm;
  @Input() cdkStepper: CdkStepper;
  @BlockUI() blockUI: NgBlockUI;
  documets: Documets = new Documets();
  constructor(public activeModal: NgbActiveModal, protected assistedService: AssistedService,private router: Router, private matSnackBar: MatSnackBar,
              public meta: Meta, public title: Title,protected gaService: GoogleAnalyticsService,
              public usersService: UsersService, public cookieService: CookieService, public applicationsService: ApplicationsService) {
    this.study = new StudyStep();
    this.title.setTitle('Apply Confirm');
  }

  ngOnInit() {
  }
  normal() {
    this.blockUI.start();
    this.applicationsService.save().subscribe((user: User)=>{
      this.blockUI.stop();
      this.cdkStepper.next();
    }, (error: HttpErrorResponse) => {
      this.blockUI.stop();
      this.gaService.exception(error.error?.message, true);
      if(error.status === 400) {
        this.cdkStepper.next();
      }else if (error.status === 401 ) {
        this.router.navigate(['/']);
        this.matSnackBar.open('Your session has expired', 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }else if (error.status !== 400) {
        this.matSnackBar.open(error.error.message, 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }
    });
  }


  previous() {

    if(this.assistedService.idNumber) {

      this.blockUI.start();
      this.assistedService.save().subscribe((user: User)=>{
        this.blockUI.stop();
      }, (error: HttpErrorResponse) => {
        this.blockUI.stop();
        this.gaService.exception(error.error?.message, true);
        if (error.status === 401 ) {
          this.router.navigate(['/']);
          this.matSnackBar.open('Your session has expired', 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }else {

          this.cdkStepper.previous();
          // this.matSnackBar.open(error.error.message, 'Okay', {
          //   duration: 5000,
          //   horizontalPosition: 'center',
          //   verticalPosition: 'top',
          // });
        }
      });
    }else {

      this.blockUI.start();
      this.applicationsService.save().subscribe((user: User)=>{
        this.blockUI.stop();
        this.applicationsService.validate().subscribe(()=>{
          this.blockUI.stop();
          this.cdkStepper.selectedIndex  =3;
        }, (err: HttpErrorResponse) => {
          this.blockUI.stop();
          this.gaService.exception(err.error.message, true);
          if(err.status === 400) {
            this.cdkStepper.previous();
            this.documets = JSON.parse(JSON.stringify(err.error));
            this.applicationsService.application.mother = this.documets.mother;
            this.applicationsService.application.father = this.documets.father;
            this.applicationsService.application.guardian = this.documets.guardian;
            this.applicationsService.application.spouse = this.documets.spouse;
          }if (err.status === 401 ) {
            this.router.navigate(['/']);
            this.matSnackBar.open('Your session has expired', 'Okay', {
              duration: 5000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          }else {


            this.cdkStepper.previous();
            // this.matSnackBar.open(err.error.message, 'Okay', {
            //   duration: 5000,
            //   horizontalPosition: 'center',
            //   verticalPosition: 'top',
            // });
          }
        });

      }, (error: HttpErrorResponse) => {
        this.blockUI.stop();
        this.gaService.exception(error.error?.message, true);
        if (error.status === 401 ) {
          this.router.navigate(['/']);
          this.matSnackBar.open('Your session has expired', 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }else {

          this.cdkStepper.previous();
          // this.matSnackBar.open(error.error.message, 'Okay', {
          //   duration: 5000,
          //   horizontalPosition: 'center',
          //   verticalPosition: 'top',
          // });
        }
      });
    }


  }

  onSubmit() {
    if (!this.kinForm.valid) {
      this.validated = true;
      $('html, body').animate({
        scrollTop: 0
      }, 1000, function() {
      });
      return;
    } else {
      this.cdkStepper.next();
    }
  }

}
