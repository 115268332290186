import {Directive, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Province} from '../../provinces/province/province';
import {Results} from '../../../core/results';
import {RejectReason} from './reject-reason';
import {RejectReasonsService} from '../reject-reasons.service';
declare var $: any;
@Directive({
  selector: '[appRejectReason]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RejectReasonDirective),
    multi: true
  }]
})
export class RejectReasonDirective implements ControlValueAccessor, OnInit {

  @Input() private item: RejectReason;
  @Input() private disabled = false;
  @Input() private province: Province;
  @Input() private title;
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  select2: any;

  constructor(private el: ElementRef, private service: RejectReasonsService) {
  }

  ngOnInit(): void {

    const that = this;
    this.select2 = $(this.el.nativeElement).select2({
      width: '100%',
      placeholder: that.title?that.title:'Select Reject Reason',
      allowClear: true,
      ajax: {
        transport: function(params, success, failure) {
          const page = 1;
          const perPage = 100;

          that.service.search(page, perPage, 'ASC', 'description', params.data.term).subscribe((ret: Results<RejectReason>) => {
            success(ret);
          });


        },
        dataType: 'json',
        type: 'GET',
        processResults: function(data) {
          const arr = [];
          data.content.forEach(function(obj) {
            arr.push({
              id: JSON.stringify(obj),
              text: obj.description,
            });
          });
          return {
            results: arr
          };
        }
      }
    });
    $(this.el.nativeElement).on('select2:select', function(e) {

      that.item = JSON.parse(e.params.data.id);
      that.propagateChange(that.item);
      if(that.onSelect) {
        that.onSelect.emit();
      }

    });

    $(this.el.nativeElement).on('select2:unselect', function(e) {

      that.item = null;
      that.propagateChange(that.item);
      if(that.onSelect) {
        that.onSelect.emit();
      }
    });


  }

  onTouched = () => {
  };

  propagateChange = (_: any) => {
  };

  writeValue(item: RejectReason): void {

    this.item = item;
    this.propagateChange(this.item);
    const that = this;
    if (this.item !== undefined && this.item !== null) {
      const option = new Option(item.description, JSON.stringify(item), true, true);
      that.select2.append(option);
      that.select2.trigger('change');
    }else {
      const option = new Option('', '', true, true);
      that.select2.append(option);
      that.select2.trigger('change');
    }

  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  get value(): any {
    return this.item;
  }

  set value(v: any) {
    if (v !== this.item) {
      this.item = v;
      this.propagateChange(v);
    }
  }

}
