import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Appeal} from '../../appeal/appeal';
import {Application} from '../../../applications/application/application';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {NgForm} from '@angular/forms';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AppealsService} from '../../../appeals/appeals.service';
import {ConfigService} from '../../../config.service';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HttpErrorResponse} from '@angular/common/http';
import {RequestToReprocess} from '../../../admin/appeal/request-to-reprocess';
import {Attachment} from '../../../core/models';
declare var $: any;
@Component({
  selector: 'app-request-appeal-reprocess',
  templateUrl: './request-appeal-reprocess.component.html',
  styleUrls: ['./request-appeal-reprocess.component.scss']
})
export class RequestAppealReprocessComponent implements OnInit {
  @Output() closeWithdraw = new EventEmitter();
  @Input() appeal: Appeal;
  @BlockUI() blockUI: NgBlockUI;
  @Input() kinType: string;
  @ViewChild('ngForm', {static: false})
  public ngForm: NgForm;
  public validated: boolean;
  public requestToReprocess: RequestToReprocess = new RequestToReprocess();

  constructor(public activeModal: NgbActiveModal, public appealsService: AppealsService,
              public configService: ConfigService, private modalService: NgbModal,
              protected gaService: GoogleAnalyticsService,
              private matSnackBar: MatSnackBar) {
  }

  ngOnInit() {

  }

  dashboard() {
    this.activeModal.close();
  }

  close() {
    this.closeWithdraw.emit();
  }

  save() {
    this.blockUI.start();
    this.appealsService.upload(this.requestToReprocess.attachment.file).subscribe((attachment: Attachment) => {
      this.requestToReprocess.attachment = attachment;
      if(attachment) {
        this.appealsService.requestAppealReprocess(this.appeal,this.requestToReprocess).subscribe((appeal: Appeal) => {
          this.appeal = appeal;
          this.appeal.status ='Revoked';
          this.blockUI.stop();
        }, (error: HttpErrorResponse) => {
          this.gaService.exception(error.error?.message, true);
          this.blockUI.stop();
          this.matSnackBar.open(error.error.message, 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        });
      }
    });
  }

  onSubmit() {

    if (!this.ngForm.valid) {
      this.validated = true;
      $('html, body').animate({
        scrollTop: 0
      }, 1000, function() {
      });
      return;
    } else {
      this.save();
    }

  }


}
