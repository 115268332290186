import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Application} from '../../applications/application/application';
import {HttpErrorResponse} from '@angular/common/http';
import {CoreComponent} from '../../core/core.component';
import {User} from '../../admin/users/user/user';
import {NgForm} from '@angular/forms';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ContactCenterService} from '../../admin/contact-center/contact-center.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {UsersService} from '../../users/users.service';
import {DisabilityChangeRequestsService} from '../disability-change-requests.service';
import {DisabilityChangeRequest} from './disability-change-request';
import {Attachment} from '../../core/models';
declare var $: any;
@Component({
  selector: 'app-disability-change-request',
  templateUrl: './disability-change-request.component.html',
  styleUrls: ['./disability-change-request.component.scss']
})
export class DisabilityChangeRequestComponent  extends CoreComponent<DisabilityChangeRequest> {

  @Input() status: string;
  user: User = new User();
  item: DisabilityChangeRequest = new DisabilityChangeRequest();
  public validated: boolean;
  @Input() public application: Application;
  @ViewChild('ngForm', {static: false}) public ngForm: NgForm;

  constructor(public modalService: NgbModal, public servicesService: DisabilityChangeRequestsService, public contactCenterService: ContactCenterService,private router: Router,
              public route: ActivatedRoute, private matSnackBar: MatSnackBar,
              protected gaService: GoogleAnalyticsService,public activeModal: NgbActiveModal,
              public userServices: UsersService) {
    super(servicesService, route);
  }

  ngOnInit(): void {

    this.blockUI.start();
    this.userServices.getProfile().subscribe((user: User) => {
      this.user = user;
      this.item.application = this.application;
      this.item.from = this.application.disabled;
      this.blockUI.stop();
    }, (error: HttpErrorResponse) => {
      this.gaService.exception(error.error?.message, true);
      this.blockUI.stop();
      if (error.status === 401) {
        this.activeModal.close();
        this.router.navigate(['/']);
        this.matSnackBar.open('Your session has expired', 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }
    });
  }

  updated() {

    this.blockUI.start();
    this.servicesService.save(this.item).subscribe((disabilityChangeRequest:DisabilityChangeRequest) => {
      this.item = disabilityChangeRequest;
      this.blockUI.stop();
      this.activeModal.close();
      this.matSnackBar.open('Disability status change request sent', 'Okay', {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }, (error: HttpErrorResponse) => {
      this.gaService.exception(error.error?.message, true);
      this.activeModal.close();
      this.blockUI.stop();
      if (error.status === 401) {
        this.activeModal.close();
        this.router.navigate(['/']);
        this.matSnackBar.open('Your session has expired', 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }else {
        this.blockUI.stop();
        this.matSnackBar.open(error.error.message, 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }
    });
  }

  onSubmit() {

    if (!this.ngForm.valid) {
      this.validated = true;
      $('html, body').animate({
        scrollTop: 0
      }, 1000, function() {
      });
      return;
    } else {
      if ((this.item?.disabilityAnnexure?.file instanceof Blob)) {
        this.servicesService.upload(this.item.disabilityAnnexure.file).subscribe((attachment: Attachment)=>{
          if(attachment) {
            this.item.disabilityAnnexure = attachment;
            this.updated();
          }
        })
      } else {
        this.updated();
      }
    }
  }

}
