<app-card>
  <div class="row justify-content-center">
    <div class="col-md-5">
      <h3 class="text-center mt-3 mb-0">Laptop Details</h3>
      <form (ngSubmit)="onSubmit()" #ngForm="ngForm" #userForm="ngForm" [ngClass]="validated?'was-validated':''" style="z-index: 1000;">
        <div class="input-group mb-3">
          <input type="text" class="form-control"  name="brand" [(ngModel)]="item.brand" required>
          <div class="invalid-feedback">
            Brand is required
          </div>
        </div>
        <div class="input-group mb-3">
          <input type="text" class="form-control"  name="cost" [(ngModel)]="item.cost" required>
          <div class="invalid-feedback">
            Cost is required
          </div>
        </div>
        <div class="input-group mb-3">
          <input type="text" class="form-control"  name="description" [(ngModel)]="item.description" required>
          <div class="invalid-feedback">
            Description is required
          </div>
        </div>

        <div class="input-group mb-3">
          <label for="formFileSm" class="form-label">Image</label>
          <input required [(ngModel)]="item.image" appFileUpload name="image" accept="image/*" class="form-control form-control-sm" id="formFileSm" type="file">
          <div class="invalid-feedback">
            Image is required
          </div>
        </div>
        <small class="ps-3" *ngIf="!item.image">PDF or Jpeg, max 20mb</small>
        <small class="ps-3" *ngIf="item.image">{{item.image.name}}</small>
        <div class="d-grid gap-2">
          <button class="btn btn-primary btn-block mb-5" type="submit">Save</button>
        </div>
      </form>
    </div>
  </div>
</app-card>
