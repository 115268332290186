<div class="row justify-content-center">
<div class="col-md-8">

  <div class="row">
    <div class="col-md-12">
      <h4  class="text-primary">Application history</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="row">
        <p class="notif-body mb-0">Application Ref#</p>
        <p class="mb-1 text-muted">
          {{application?.reference}}
        </p>
      </div>
      <div class="row">
        <p class="notif-body mb-0">Applicant Name</p>
        <p class="mb-1 text-muted">
          {{application?.user?.firstName}} {{application?.user?.lastName}}
        </p>
      </div>
      <div class="row">
        <p class="notif-body mb-0">Application date</p>
        <p class="mb-1 text-muted">
          {{application?.createDate | date : 'dd MMMM yyyy'}}
        </p>
      </div>
      <div class="row">
        <p class="notif-body mb-0">Funded</p>
        <p class="mb-1 text-muted">
          {{(application?.status==='ProvisionallyFunded' || application?.status==='RegistrationReceived')?'Yes':'No'}}
        </p>
      </div>

      <div class="row" *ngIf="application?.rejectReason || application?.rejectReasons">
        <p class="notif-body mb-0">Application Rejection Reason</p>
<!--        <p class="mb-1 text-muted" *ngIf="application.rejectReason">-->
<!--          {{application.rejectReason.description}}-->
<!--        </p>-->

        <p class="mb-1 text-muted" *ngFor="let rejectReason of application?.rejectReasons">
          {{rejectReason?.description}}
        </p>
      </div>

    </div>


    <div class="col-md-6">

      <div class="row" *ngIf="!userServices.hasRole(user,'SeniorDisabilityManager')">
        <p class="notif-body mb-0">Disabled</p>
        <p class="mb-1 text-muted">
          {{application.disabled===true?'Yes':'No'}}
        </p>
      </div>
      <div class="row" *ngIf="userServices.hasRole(user,'SeniorDisabilityManager')">

        <form (ngSubmit)="onSubmit()" #ngForm="ngForm" class="px-5 needs-validation"
              [ngClass]="validated===true?'was-validated':''">

          <p class="notif-body mb-0">Disabled</p>


          <div class="radio d-block mb-3">
            <input class="form-check-input" type="radio" name="disabled" id="disabilityNo" [value]="false"
                   [(ngModel)]="application.disabled">
            <label class="cr" for="disabilityNo">No</label>
          </div>


          <div class="radio d-block mb-3">
            <input class="form-check-input" type="radio" name="disabled" id="disabilityYes" [value]="true"
                   [(ngModel)]="application.disabled">
            <label class="cr" for="disabilityYes">Yes</label>
          </div>
          <div class="row">
            <button type="submit" class="btn btn-primary">Update</button>
          </div>
        </form>


      </div>
      <div class="row">
        <p class="notif-body mb-0">Year of study</p>
        <p class="mb-1 text-muted">
          {{application?.year}}
        </p>
      </div>
      <div class="row">
        <p class="notif-body mb-0">Process cycle</p>
        <p class="mb-1 text-muted">
          {{application?.year}} {{application?.studyTerm?.name}}
        </p>
      </div>

      <div class="row">
        <p class="notif-body mb-0">Application status</p>
        <p class="mb-1 text-muted">
          {{application?.status}}
        </p>
      </div>



      <div class="row">
        <p class="notif-body mb-0">Status date</p>
        <p class="mb-1 text-muted">
          {{application?.createDate | date : 'dd MMMM yyyy'}}
        </p>
      </div>
    </div>
  </div>
  </div>
</div>
