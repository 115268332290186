import {Directive, ElementRef, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
declare var $: any;
export const CUSTOM_INPUT_DATE_PICKER_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DatepickerDirective),
  multi: true
};

@Directive({
  selector: '[appDatepicker]',
  host: {'(blur)': 'onTouched($event)'},
  providers: [CUSTOM_INPUT_DATE_PICKER_CONTROL_VALUE_ACCESSOR]
})
export class DatepickerDirective implements ControlValueAccessor {

  private innerValue: string;

  @Input('changeMonth') changeMonth = true;
  @Input('changeYear') changeYear= true;

  constructor(private el: ElementRef) {}

  ngOnInit() {

    $(this.el.nativeElement).datepicker({
      dateFormat: 'dd MM yy',
      changeMonth: true,
      changeYear: true,
      minDate: new Date(),
    }).on('change', (e: any) => {
      this.onChange(e.target.value);
    })
  }

  public onChange: any = (_: any) => { /*Empty*/
  };
  public onTouched: any = () => { /*Empty*/
  };

  get value(): any {
    return this.innerValue;
  };

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChange(v);
    }
  }


  writeValue(val: string): void {
    this.innerValue = val;
    $(this.el.nativeElement).datepicker('setDate', this.innerValue);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

}
