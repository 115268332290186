<app-card>
  <div class="row justify-content-center">
    <div class="col-md-12">
      <h3 class="text-center mt-3 mb-0">Registered Users</h3>
      <p class="text-center  mb-5">Manage all registered Users</p>
      <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs justify-content-center">
        <li [ngbNavItem]="i" *ngFor="let role of roles; let i = index">
          <a ngbNavLink>{{role}}</a>
          <ng-template ngbNavContent>
            <app-users [role]="role"></app-users>
          </ng-template>
        </li>

      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>

    </div>
  </div>
</app-card>
