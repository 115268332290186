<div class="row justify-content-center">
  <div class="col-md-5">

    <h2 class="mb-3 text-center">NSFAS funding application</h2>

    <form (ngSubmit)="onSubmit()" #kinForm="ngForm" class="px-5 needs-validation" [ngClass]="validated===true?'was-validated':''">
      <h5 style="text-align: center;">Kindly fill your current physical address</h5>
      <h5 class="mb-4 text-primary"><strong>Address information</strong></h5>

<!--      <div class="input-group mb-3">-->
<!--        <select class="form-select" required [(ngModel)]="applicationsService.application.address" name="location" appAddress></select>-->
<!--        <div class="invalid-feedback">-->
<!--          Address information is required-->
<!--        </div>-->
<!--      </div>-->

      <div class="input-group mb-3">
        <input type="text" autocomplete="off" class="form-control" placeholder="Street number" name="address.line1" [(ngModel)]="applicationsService.application.address.line1">
        <div class="invalid-feedback">
          Street number is required
        </div>
      </div>
      <div class="input-group mb-3">
        <input type="text" autocomplete="off" class="form-control" placeholder="Street name" name="address.line2" [(ngModel)]="applicationsService.application.address.line2">
        <div class="invalid-feedback">
          Street name is required
        </div>
      </div>
      <div class="input-group mb-3">
        <input type="text" autocomplete="off" class="form-control" placeholder="Town" name="address.town" [(ngModel)]="applicationsService.application.address.town" required>
        <div class="invalid-feedback">
          Town is required
        </div>
      </div>
<!--      <div class="input-group mb-3">-->
<!--        <input type="text" autocomplete="off" class="form-control" placeholder="City" name="address.suburb" [(ngModel)]="applicationsService.application.address.suburb" required>-->
<!--        <div class="invalid-feedback">-->
<!--          City is required-->
<!--        </div>-->
<!--      </div>-->
      <div class="input-group mb-3">
        <select type="text" class="form-control" appProvince name="address.province" [(ngModel)]="applicationsService.application.address.provinceName" required></select>
        <div class="invalid-feedback">
          Province is required
        </div>
      </div>
      <div class="input-group mb-3">
        <input type="text" pattern="[0-9]+" autocomplete="off" class="form-control" placeholder="Postal code" name="address.postalCode" [(ngModel)]="applicationsService.application.address.postalCode" required>
        <div class="invalid-feedback">
          Invalid Postal code
        </div>
      </div>


  <div class="row">
    <div class="col-md-6">
      <div class="d-grid gap-2">

        <button class="btn btn-outline-dark btn-lg mb-3" type="button" (click)="previous()">Previous</button>
      </div>

    </div>
    <div class="col-md-6">
      <div class="d-grid gap-2">

        <button class="btn btn-primary btn-lg mb-3" type="submit" >Next</button>
      </div>

    </div>
  </div>
  </form>

</div>

</div>
