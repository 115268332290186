import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Question} from './question/question';
import {QuestionsService} from './questions.service';
import {UserQuestionsComponent} from '../users/user-questions/user-questions.component';
import {UserQuestion} from '../users/user-questions/user-question/user-question';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-security-questions',
  templateUrl: './security-questions.component.html',
  styleUrls: ['./security-questions.component.scss']
})
export class SecurityQuestionsComponent implements OnInit {
  active = 1;
  questions: Array<Question> = new Array<Question>();
  @BlockUI() blockUI: NgBlockUI;

  constructor(private modalService: NgbModal, private questionsService: QuestionsService, private matSnackBar: MatSnackBar,
              public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    this.questionsService.getAll().subscribe((questions: Array<Question>) => {
      this.questions = questions;
    }
      , (error: HttpErrorResponse) => {
        this.blockUI.stop();
        this.matSnackBar.open(error.error.message, 'Okay', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      });
  }

  back() {
//this.appcomp.appeal = false;
  }
  onSubmit() {
    let counter = 0;
    this.questionsService.userQuestions = new Array<UserQuestion>();
    this.questions.forEach((question: Question) => {
      if (question.selected === true) {
        counter++;
        const userQuestion = new UserQuestion();
        userQuestion.securityQuestion = question;
        this.questionsService.userQuestions.push(userQuestion);
      }
    });
    if(counter < 3) {
      this.matSnackBar.open('Please select atleast three security question', 'Okay', {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      return;
    }
    this.activeModal.close();
    const modalRef = this.modalService.open(UserQuestionsComponent, {
      fullscreen: true,
      modalDialogClass: 'animate-bottom'
    });
    modalRef.componentInstance.name = 'World';

  }


}
