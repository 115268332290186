import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {CoreComponent} from '../../../core/core.component';
import {User} from '../../../admin/users/user/user';
import {NgForm} from '@angular/forms';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {UsersService} from '../../../users/users.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HttpErrorResponse} from '@angular/common/http';
import {AssistedService} from '../../assisted.service';
import {Register} from '../../../users/user/register';
import {CdkStepper} from '@angular/cdk/stepper';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
declare var $: any;
@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.scss']
})
export class RegisterUserComponent extends CoreComponent<Register> {

  @Input() cdkStepper: CdkStepper;
  @Input() item: Register = new Register();

  validated= false;
  @ViewChild('ngForm', { static: false }) public ngForm: NgForm;
  @BlockUI() blockUI: NgBlockUI;
  constructor(protected servicesService: AssistedService, public route: ActivatedRoute,private router: Router,
              protected gaService: GoogleAnalyticsService,
              public meta: Meta, public title: Title,
              public activeModal: NgbActiveModal, private matSnackBar: MatSnackBar, private modalService: NgbModal) {
    super(servicesService,route);
    this.title.setTitle('Register');
  }

  public ngOnInit(): void {

  }

  onSubmit() {

    if (!this.ngForm.valid) {
      this.validated = true;
      $('html, body').animate({
        scrollTop: 0
      }, 1000, function() {
      });
      return;
    } else {
      this.blockUI.start();
      this.item.password='';
      this.servicesService.idNumber = this.item.idNumber;
      this.servicesService.create(this.item).subscribe((item: Register) => {
        this.blockUI.stop();

        this.item = item;
        this.cdkStepper.next();
      }, (error: HttpErrorResponse) => {
        this.blockUI.stop();
        this.gaService.exception(error.error?.message, true);
        if (error.status === 401) {
          this.modalService.dismissAll();
          this.router.navigate(['/']);
          this.matSnackBar.open('Your session has expired', 'Okay', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
          });
        }else {
          this.cdkStepper.next();
        }
      });
    }

  }

}
