<div class="row">
  <div class="col-md-12">
    <h4  class="text-primary">Appeal history</h4>
  </div>
</div>
<div class="row">
  <div class="col-md-6">

    <div class="row">
      <p class="notif-body mb-0">Appeal date</p>
      <p class="mb-1 text-muted">
      {{appeal?.created}}
      </p>
    </div>

    <div class="row" >
      <p class="notif-body mb-0">Appeal Reject Reason</p>
      <p class="mb-1 text-muted">
      {{appeal?.appealReason?.rejectReason?.description}}
      </p>
    </div>
    <div class="row" >
      <p class="notif-body mb-0">Process Cycle</p>
      <p class="mb-1 text-muted">
      {{appeal?.application?.studyTerm?.name}}
      </p>
    </div>

  </div>
  <div class="col-md-6">

    <div class="row">
      <p class="notif-body mb-0">Appeal status</p>
      <p class="mb-1 text-muted">
    {{appeal?.status}}
      </p>
    </div>

    <div class="row">
      <p class="notif-body mb-0">Date submitted</p>
      <p class="mb-1 text-muted">
       {{appeal?.created}}
      </p>
    </div>
  </div>
</div>

