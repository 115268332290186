import {Results} from './results';
import {ActivatedRoute} from '@angular/router';
import {Injectable, Input, OnInit} from '@angular/core';
import {ServicesService} from '../users/services.service';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {StudyTerm} from '../admin-config/study-terms/study-term/study-term';

@Injectable({
  providedIn: 'root'
})

export abstract class CoreComponent<T> implements OnInit {

  public keyword: string;
  public year = '';
  public status = '';
  public firstName: string;
  public studyTerm: StudyTerm;
  public date: Date;
  public currentPage = 1;
  public perPage = 10;
  public order: string = 'ASC';
  public orderBy: string = 'id';
  public results: Results<T> = new Results<T>()
  @BlockUI() blockUI: NgBlockUI;
  @Input() public item: T;


  constructor(protected servicesService: ServicesService, public route: ActivatedRoute) {
  }

  public ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params.id !== undefined) {
        if (params.id !== '0') {
          this.get(params.id);
        }
      } else {
        this.search();
      }
    });
  }

  sortSearch() {
    if (this.order === 'ASC') {
      this.order = 'DESC';
    } else {
      this.order = 'ASC';
    }
    this.search();
  }

  public search() {

    this.blockUI.start();
    this.servicesService.search(this.currentPage, this.perPage, this.order, this.orderBy, this.keyword)
      .subscribe((data: Results<T>) => {
        this.results = data;
        this.blockUI.stop();
      },error => {
        this.blockUI.stop();
      });

  }



  public save() {

    return this.servicesService.save(this.item).subscribe((data: T) => {
      this.item = data;
    });
  }

  public delete(id: string) {
    this.servicesService.delete(id).subscribe(data => {

    });
  }

  public get(id: string) {

    this.servicesService.get(id).subscribe((data: T) => {
      this.item = data;
    });
  }

}
